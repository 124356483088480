import ErrorResponseType from '@/constants/error/ErrorResponseType'
import RequestErrorSource from '@/constants/error/RequestErrorSource'

export default class ErrorResponseDTO {
  constructor({
    error,
    data = null,
    source = RequestErrorSource.unknown,
    type = ErrorResponseType.unknown,
    code = '',
    message = '',
    param = null,
  } = {}) {
    /**
     * @property {Error} The original error object returned from request attempt
     */
    this.error = error

    /**
     * @property {Object} Container prop to transmit any relevant data down the pipeline
     */
    this.data = data

    /**
     * @property {RequestErrorSource} Indicates at what stage the error was triggered when attempting the request
     */
    this.source = source

    /**
     * @property {ErrorResponseType} The type of error received from the response (set to unknown if response wasn't received)
     */
    this.type = type

    /**
     * @property {string} Error code that may be received from the response or determined locally
     */
    this.code = code

    /**
     * @property {string} Message to relate error information to the user
     */
    this.message = message

    /**
     * @property {string} Contains the parameter in error (if applicable)
     */
    this.param = param
  }
}
