// eslint-disable-next-line no-unused-vars
import CandidateBookingSummaryViewModel from './candidateBookingSummaryViewModel'

/**
 * Simplified candidate specific booking data for a specified date
 * @class
 * @public
 */
export default class CandidateDateSpecificBookingsSummaryViewModel {
  /**
   * @param {{ bookings: CandidateBookingSummaryViewModel[] }} param0
   */
  constructor({ bookings = [] } = {}) {
    /**
     * @type {CandidateBookingSummaryViewModel[]}
     */
    this.bookings = bookings
  }
}
