export default Object.freeze({
  /**
   * Select multiple days one at a time.
   */
  multiselect: 'multiselect',
  /**
   * Select a start and end range
   */
  range: 'range',
})
