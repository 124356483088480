/**
 * Generic class to hold information about emit values
 */
export default class EmitEvent {
  constructor({ disabled = false, event = '', value = null } = {}) {
    /**
     * Will prevent event from firing
     * @type {Boolean}
     */
    this.disabled = disabled

    /**
     * Name of event to be emitted
     * @type {String}
     */
    this.event = event

    /**
     * Value of event to be emitted
     * @type {*}
     */
    this.value = value
  }
}
