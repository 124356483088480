// eslint-disable-next-line no-unused-vars
import EmitEvent from './emitEvent'
// eslint-disable-next-line no-unused-vars
import InteractionType from '@/constants/core/InteractionType'

/**
 * Describes an interaction when the user clicks a UI element
 */
export default class Interaction {
  /**
   * @param {{type: InteractionType, value: EmitEvent|URL|Object|Function}}
   */
  constructor({ type, value } = {}) {
    /**
     * @type {String}
     * @see {InteractionType} for valid range of values
     */
    this.type = type

    /**
     * Contains data required to action interation
     * @type {EmitEvent|URL|Object|Function}
     */
    this.value = value
  }

  /**
   * @returns {Boolean}
   */
  isEmitEvent() {
    return this.type === InteractionType.emitEvent
  }

  /**
   * @returns {Boolean}
   */
  isRouterLink() {
    return this.type === InteractionType.routerLink
  }

  /**
   * @returns {Boolean}
   */
  isExternalLink() {
    return this.type === InteractionType.externalLink
  }

  /**
   * @returns {Boolean}
   */
  isFunction() {
    return this.type === InteractionType.function
  }

  /**
   * Determines if an interaction is even possible
   * @returns {Boolean}
   */
  isInteractionPossible() {
    return (
      this.isEmitEvent ||
      this.isRouterLink ||
      this.isExternalLink ||
      this.isFunction
    )
  }

  /**
   * @returns {EmitEvent}
   */
  getEmitEvent() {
    if (!this.isEmitEvent())
      throw new TypeError('This interaction is not an emit event')
    return this.value
  }

  /**
   * @returns {RouterLink}
   */
  getRouterLink() {
    if (!this.isRouterLink())
      throw new TypeError('This interaction is not a router link')
    return this.value
  }

  /**
   * @returns {URL}
   */
  getExternalLink() {
    if (!this.isExternalLink())
      throw new TypeError('This interaction is not an external link')
    return this.value
  }

  /**
   * @returns {Function}
   */
  getFunction() {
    if (!this.isFunction())
      throw new TypeError('This interaction is not an action function')
    return this.value
  }
}
