import { ModelApiService } from './BaseApiService'

export default class WorkExperienceApiService extends ModelApiService {
  constructor() {
    super('work-experience')
  }

  /**
   * @param {{workExperienceId: Number, accessGui: String}} payload
   * @returns {Promise<ResultDTO>} Blob of the application general file
   */
  async downloadFile({ workExperienceId, accessGui }) {
    return this.getBlob(`${workExperienceId}/file/${accessGui}`)
  }
}
