/**
 * Describes a grade or subject
 * @class
 * @public
 */
export default class Grade {
  /**
   * @param {{ id: number, title: string }} param0
   */
  constructor({ id = null, title = '' } = {}) {
    /**
     * @type {number}
     */
    this.id = id
    /**
     * @type {string}
     */
    this.title = title
  }
}
