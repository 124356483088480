<script>
import baseMixin from '@/mixins/baseMixin'
import TheDebugBar from '@/components/dev/the-debug-bar.vue'
import TheImpersonateBar from '@/components/impersonate/the-impersonate-bar.vue'

export default {
  name: 'TheSystemBarContainer',
  components: { TheDebugBar, TheImpersonateBar },
  mixins: [baseMixin],
}
</script>

<template>
  <section>
    <TheDebugBar :set-as-app="!isImpersonating" />
    <TheImpersonateBar v-if="isAuthenticated" />
  </section>
</template>
