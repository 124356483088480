// eslint-disable-next-line no-unused-vars
import CandidateBookingOverviewBookingItemViewModel from './candidateBookingOverviewBookingItemViewModel'
import CandidateBookingOverviewBookingItemWithDateViewModel from './candidateBookingOverviewBookingItemWithDateViewModel'
import { isNonEmptyArray } from '@/helpers/array-helpers'

export default class CandidateBookingOverviewViewModel {
  constructor({ dateLocal = null, bookings = [] } = {}) {
    /**
     * @type {Date}
     */
    this.dateLocal = dateLocal
    /**
     * @type {CandidateBookingOverviewBookingItemViewModel[]}
     */
    this.bookings = isNonEmptyArray(bookings)
      ? bookings.map(
          (booking) => new CandidateBookingOverviewBookingItemViewModel(booking)
        )
      : []
  }

  toBookingsListWithDate() {
    if (!isNonEmptyArray(this.bookings)) return []

    return this.bookings.map(
      (booking) =>
        new CandidateBookingOverviewBookingItemWithDateViewModel({
          ...booking,
          ...{ dateLocal: this.dateLocal },
        })
    )
  }
}
