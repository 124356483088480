import { fail, success } from '@helpers/result-helper'
import DurationUnits from '@/constants/date/DurationUnits.js'
import { isCacheFresh } from '@/helpers/cache-helpers'
import toast from '@/services/toasts/index.js'
import dayjs from '@/services/date/index.js'
import { Ready2WorkStatus } from '@/constants/candidate/Ready2WorkStatus'
import { ActiveDay } from '@/constants/date/ActiveDay'
// eslint-disable-next-line no-unused-vars
import R2WAvailabilityStatusViewModel from '@/models/availability/r2WAvailabilityStatusViewModel'
import ScheduledAvailabilityViewModel from '@/models/availability/scheduledAvailabilityViewModel'
import { AvailabilityStatus } from '@/constants/candidate/AvailabilityStatus'

const getDefaultState = () => {
  return {
    // Place any new state properties here
    loadingCount: 0,
    crudLoadingCount: 0,
    r2WStatusOnActiveDay: null,
  }
}

const state = getDefaultState()

export default {
  namespaced: true,

  /**
   * @typedef {{
   * loadingCount: Number
   * crudLoadingCount: Number
   * r2WStatusOnActiveDay: { r2WStatus: R2WAvailabilityStatusViewModel, lastUpdated: Date }
   * }} Ready2WorkState
   * @type {Ready2WorkState}
   */
  state,
  getters: {
    moduleName: () => 'ready2Work',
    isLoading: (state) => state.loadingCount > 0,
    isLoadingCRUD: (state) => state.crudLoadingCount > 0,
    /**
     * @param {Ready2WorkState} state
     * @returns {R2WAvailabilityStatusViewModel}
     */
    r2WStatusOnActiveDay: (state) => state.r2WStatusOnActiveDay?.r2WStatus,
  },
  mutations: {
    START_LOADING(state) {
      state.loadingCount++
    },
    FINISH_LOADING(state) {
      state.loadingCount--
    },
    START_LOADING_CRUD(state) {
      state.crudLoadingCount++
    },
    FINISH_LOADING_CRUD(state) {
      state.crudLoadingCount--
    },
    CLEAR_STORE(state) {
      // Resets store to default state
      Object.assign(state, getDefaultState())
    },
    /**
     * @param {Ready2WorkState} state
     * @param {R2WAvailabilityStatusViewModel} newValue
     */
    SET_READY2WORK(state, newValue) {
      state.r2WStatusOnActiveDay = {
        r2WStatus: newValue,
        lastUpdated: dayjs(),
      }
    },
  },
  actions: {
    /**
     * @param {*} param0
     * @param {*} newStatus
     * @returns {Promise<ResultDTO>}
     */
    async setReady2WorkStatus({ commit, dispatch }, newStatus) {
      commit('START_LOADING')
      try {
        const response = await this.$api.ready2Work.setReady2WorkStatus(
          newStatus
        )

        let successToastMessage = this.$i18n.t(
          'availability.successfulStatusChangeMessage',
          {
            newStatus:
              newStatus === Ready2WorkStatus.READY
                ? this.$i18n.t('availability.readyStatus')
                : this.$i18n.t('availability.notReadyStatus'),
            relevantDate: this.$i18n.d(
              dayjs(response.data.activeDateLocal),
              'dateShort'
            ),
          }
        )
        // append check-in reminder if the user is ready for next active day
        if (
          response.data.activeDay !== ActiveDay.TODAY &&
          response.data.ready2Work === Ready2WorkStatus.READY
        ) {
          successToastMessage += `. ${this.$i18n.t(
            'availability.checkInReminderMessage'
          )}`
        }
        toast.success(successToastMessage)

        commit('SET_READY2WORK', response.data)

        // update the availability in store
        const availabilityStatus =
          response.data.ready2Work === Ready2WorkStatus.READY
            ? AvailabilityStatus.AVAILABLE
            : AvailabilityStatus.UNAVAILABLE
        await dispatch(
          'availability/updateAvailability',
          new ScheduledAvailabilityViewModel({
            dateLocal: response.data.activeDateLocal,
            status: availabilityStatus,
            partialRanges: [],
          }),
          {
            root: true,
          }
        )
        return response
      } catch (ex) {
        toast.error(this.$i18n.t('error.genericFailedRequestMessage'))
        return fail({
          error: await dispatch('logException', ex, { root: true }),
        })
      } finally {
        commit('FINISH_LOADING')
      }
    },
    /**
     * @param {*} param0
     * @param {*} forceRefresh
     * @returns {Promise<ResultDTO>}
     */
    async refreshReady2WorkStatus({ commit, dispatch }, forceRefresh = false) {
      if (
        isCacheFresh({
          cacheDuration:
            state.r2WStatusOnActiveDay?.r2WStatus
              .timeUntilUpdateRequiredInMilliSeconds,
          durationUnits: DurationUnits.MILLISECOND,
          lastUpdated: state.r2WStatusOnActiveDay?.lastUpdated,
          forceRefresh,
        })
      ) {
        return success()
      }

      commit('START_LOADING')
      try {
        const response = await this.$api.ready2Work.getReady2WorkStatus()
        commit('SET_READY2WORK', response.data)
        return response
      } catch (ex) {
        toast.error(this.$i18n.t('error.genericFailedRequestMessage'))
        return fail({
          error: await dispatch('logException', ex, { root: true }),
        })
      } finally {
        commit('FINISH_LOADING')
      }
    },
    /**
     * @param {Object} param0
     * @param {R2WAvailabilityStatusViewModel} newValue
     */
    setReady2Work({ commit }, newValue) {
      commit('SET_READY2WORK', newValue)
    },
    /**
     * Resets store to default state.
     */
    clear({ commit }) {
      commit('CLEAR_STORE')
    },
  },
}
