import { ModelApiService } from './BaseApiService'
// eslint-disable-next-line no-unused-vars
import { CandidateCertificationCheckDTO } from '@/models/certification/requests/candidateCertificationCheckDTO'

export default class CertificationsApiService extends ModelApiService {
  constructor() {
    super('certifications')
  }

  async getCertTypes() {
    return await this.get('types')
  }

  /**
   * @param {Number} certTypeId
   * @returns {Promise<ResultDTO>}
   */
  async getCertTypeById(certTypeId) {
    return await this.get(`types/${certTypeId}`)
  }

  /**
   * @param {{certificationId: Number, accessGui: String}} payload
   * @returns {Promise<ResultDTO>} Blob of the certification file
   */
  async downloadFile({ certificationId, accessGui }) {
    return this.getBlob(`${certificationId}/file/${accessGui}`)
  }

  /**
   * Verifies cert file to ensure it matches expected document format
   * @param { CandidateCertificationCheckDTO } payload
   */
  async verifyCertFile(payload) {
    return await this.post('verify', payload)
  }

  /**
   * Attempts to extract the details from an uploaded cert and returns a
   * confidence score the specified cert type matches expectation
   * @param { CandidateCertificationCheckDTO } payload
   */
  async extractCertDetails(payload) {
    return await this.post('extract-details', payload)
  }
}
