/* eslint-disable no-unused-vars */
import TentativeFillActionDTO from './tentativeFillActionDTO'
import TentativeFillRequestDTO from './tentativeFillRequestDTO'

/**
 * @class
 * @public
 */
export default class TentativeFillRequestAnonymousDTO {
  constructor({ shortTermTentativeId, accessGuid, payload } = {}) {
    /**
     * @type {String} Guid
     */
    this.shortTermTentativeId = shortTermTentativeId

    /**
     * @type {String} Guid
     */
    this.accessGuid = accessGuid

    /**
     * The value to be emitted once selected by the user
     * @type {TentativeFillActionDTO}
     */
    this.payload = payload
  }
}
