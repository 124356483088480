import { ModelApiService } from './BaseApiService'

export default class Ready2WorkApiService extends ModelApiService {
  constructor() {
    super('ready2work')
  }

  setReady2WorkStatus(newStatus) {
    return this.post('', { status: newStatus })
  }

  getReady2WorkStatus() {
    return this.get()
  }
}
