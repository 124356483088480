/* eslint-disable no-unused-vars */
import BaseLocationAddress from '../../location/baseLocationAddress'
import ShiftRecordBlockWithId from './shiftRecordBlockWithId'
import dayjs from '@/services/date/index'
import { isNonEmptyArray } from '@/helpers/array-helpers'
import BookingStatus from '@/constants/booking/BookingStatus'

/**
 * @typedef {Object} ShiftRecordDetailsViewModelType
 * @property {string} bookingDate Date the shift was held on.
 * @property {boolean} isSubmitted Indicates if the candidate has submitted a shift record for this booking.
 * @property {BaseLocationAddress} location The location of the booking.
 * @property {Array<ShiftRecordBlockWithId>} blocks Time blocks to fill out the shiftRecord form.
 * @property {boolean} isRestBreakCompliant Indicates if the booking is rest break compliant.
 * @property {keyof BookingStatus} bookingStatus The booking status.
 * @property {boolean} showRestBreakComplianceQuestion Indicates if the rest break compliance question should be shown.
 */

/**
 * @type {ShiftRecordDetailsViewModelType}
 */
export default class ShiftRecordDetailsViewModel {
  /** @param {ShiftRecordDetailsViewModelType} params */
  constructor(params) {
    this.bookingDate = params.bookingDate ? dayjs(params.bookingDate) : null
    this.isSubmitted = params.isSubmitted
    this.location = new BaseLocationAddress(params.location)
    this.blocks = isNonEmptyArray(params.blocks)
      ? params.blocks.map((block) => new ShiftRecordBlockWithId(block))
      : []
    this.isRestBreakCompliant = params.isRestBreakCompliant
    this.bookingStatus = params.bookingStatus
    this.showRestBreakComplianceQuestion =
      params.showRestBreakComplianceQuestion
  }
}
