/**
 * The error event is fired on a Window object when a resource failed to load or couldn't be used — for example if a script has an execution error.
 * @tutorial https://developer.mozilla.org/en-US/docs/Web/API/Window/error_event
 */
export default class WindowErrorDTO {
  constructor({ message, source, lineno, colno, error } = {}) {
    /**
     * @type {String} message A string containing a human-readable error message describing the problem. Same as `ErrorEvent.event`
     */
    this.message = message

    /**
     * @type {String} source A string containing the URL of the script that generated the error.
     */
    this.source = source

    /**
     * @type {Number} lineno An integer containing the line number of the script file on which the error occurred.
     */
    this.lineno = lineno

    /**
     * @type {Number} colno An integer containing the column number of the script file on which the error occurred.
     */
    this.colno = colno

    /**
     * @type {Error} error The error being thrown. Usually an `Error` object.
     */
    this.error = error
  }
}
