<script>
import baseMixin from '@/mixins/baseMixin'

export default {
  name: 'BasePageTitle',
  mixins: [baseMixin],
  props: {
    title: {
      type: String,
      default: 'Page Title',
    },
    subtitle: {
      type: String,
      default: '',
    },
    subtitleOnTop: {
      type: Boolean,
      default: false,
    },
    subtitleClass: {
      type: String,
      default: 'text-subtitle-2 mb-0',
    },
    backButton: {
      type: Boolean,
    },
  },
}
</script>

<template>
  <div
    :class="[isMobileViewPort ? 'mb-4' : 'mb-8', 'd-flex align-center mt-3']"
  >
    <slot name="leftAction"> </slot>
    <header class="d-flex justify-space-between">
      <div>
        <h4
          v-if="subtitleOnTop"
          id="page-subtitle"
          :class="[!isMobileViewPort ? subtitleClass : 'body-2']"
        >
          {{ subtitle }}
        </h4>
        <slot name="title">
          <h2
            id="page-title"
            :class="[
              !isMobileViewPort
                ? 'text-h5 d-inline-block font-weight-medium'
                : 'text-subtitle-1 font-weight-medium',
              subtitleOnTop ? 'mt-0 mb-8' : 'mb-0',
            ]"
          >
            {{ title }}
          </h2>
        </slot>
        <slot name="bottomSubtitle">
          <h4
            v-if="!subtitleOnTop"
            id="page-subtitle"
            :class="[!isMobileViewPort ? subtitleClass : 'body-2']"
          >
            {{ subtitle }}
          </h4>
        </slot>
      </div>
      <slot name="appendTitle"></slot>
    </header>
  </div>
</template>
