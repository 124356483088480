/**
 * @class
 * @public
 */
export default class BookingOverviewBookingIndexDTO {
  constructor({ topLevelIndex, bookingIndex } = {}) {
    /**
     * @type {Number}
     */
    this.topLevelIndex = topLevelIndex
    /**
     * @type {Number}
     */
    this.bookingIndex = bookingIndex
  }
}
