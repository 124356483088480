import { ModelApiService } from './BaseApiService'

export default class RegistrationsApiService extends ModelApiService {
  constructor() {
    super('registrations')
  }

  /**
   * @param {{registrationId: Number, complianceItemId: Number}} payload
   * @returns {Promise<ResultDTO>}
   */
  async getVideoComplianceItemData({ registrationId, complianceItemId }) {
    return this.get(
      `${registrationId}/compliance-items/${complianceItemId}/video`
    )
  }

  /**
   * @param {{registrationId: Number, complianceItemId: Number}} payload
   * @returns {Promise<ResultDTO>}
   */
  async getComplianceItemFileData({ registrationId, complianceItemId }) {
    return this.getBlob(
      `${registrationId}/compliance-items/${complianceItemId}/file`
    )
  }

  /**
   * @param {{registrationId: Number, complianceItemId: Number}} payload
   * @returns {Promise<ResultDTO>}
   */
  async verifyComplianceItem({ registrationId, complianceItemId }) {
    return this.post(
      `${registrationId}/compliance-items/${complianceItemId}/verify`
    )
  }

  /**
   * @param {{registrationId: Number, complianceItemId: Number}} payload
   * @returns {Promise<ResultDTO>}
   */
  async getComplianceItemFormDetails({ registrationId, complianceItemId }) {
    return this.get(
      `${registrationId}/compliance-items/${complianceItemId}/form`
    )
  }

  /**
   * @param {{registrationId: Number, complianceItemId: Number}} payload
   * @returns {Promise<ResultDTO>}
   */
  async getIdCheckDetails({ registrationId, complianceItemId }) {
    return this.get(
      `${registrationId}/compliance-items/${complianceItemId}/id-check`
    )
  }

  /**
   * @param {{registrationId: Number, complianceItemId: Number}} payload
   * @returns {Promise<ResultDTO>}
   */
  async completeIdCheck({ registrationId, complianceItemId }) {
    return this.post(
      `${registrationId}/compliance-items/${complianceItemId}/submit`
    )
  }
}
