import { getType } from 'mime'
import { fail, success } from '@/helpers/result-helper.js'
import { isCacheFresh } from '@/helpers/cache-helpers'
import DurationUnits from '@/constants/date/DurationUnits.js'
import toast from '@/services/toasts/index.js'
import dayjs from '@/services/date/index.js'
import { fileToBase64String } from '@/services/file/Base64Convert'
import CandidateDocumentViewModel from '@/models/candidate/candidateDocumentViewModel'
import { extractFileNameFromContentDisposition } from '@/helpers/file-helpers'
import HttpHeaders from '@/constants/api/HttpHeaders'
import { isNonEmptyArray } from '@/helpers/array-helpers'
import { DocumentsActionErrorMessageFactory } from '@/services/documents/DocumentsActionErrorMessageFactory'
// eslint-disable-next-line no-unused-vars
import ResultDTO from '@/models/app/resultDTO'

const getDefaultState = () => {
  return {
    documents: null,
    loadingDocumentsCount: 0,
    loadingFileTypesCount: 0,
    loadingCount: 0,
    crudLoadingCount: 0,
    fileTypes: null,
    loadingViewDocumentCount: 0,
    loadingDeleteCount: 0,
  }
}

const state = getDefaultState()

export default {
  namespaced: true,
  state,
  getters: {
    moduleName: () => 'documents',
    documents: (state) => state.documents?.list,
    isLoading: (state) => state.loadingCount > 0,
    isLoadingDocuments: (state) => state.loadingDocumentsCount > 0,
    isLoadingFileTypes: (state) => state.loadingFileTypesCount > 0,
    isLoadingCRUD: (state) => state.crudLoadingCount > 0,
    isLoadingDelete: (state) => state.loadingDeleteCount > 0,
    fileTypes: (state) => state.fileTypes?.list,
    isLoadingViewDocument: (state) => state.loadingViewDocumentCount > 0,
  },
  mutations: {
    START_LOADING(state) {
      state.loadingCount++
    },
    FINISH_LOADING(state) {
      state.loadingCount--
    },
    START_LOADING_DOCUMENTS(state) {
      state.loadingDocumentsCount++
    },
    FINISH_LOADING_DOCUMENTS(state) {
      state.loadingDocumentsCount--
    },
    START_LOADING_FILE_TYPES(state) {
      state.loadingFileTypesCount++
    },
    FINISH_LOADING_FILE_TYPES(state) {
      state.loadingFileTypesCount--
    },
    START_LOADING_CRUD(state) {
      state.crudLoadingCount++
    },
    FINISH_LOADING_CRUD(state) {
      state.crudLoadingCount--
    },
    START_LOADING_VIEW_DOCUMENT(state) {
      state.loadingViewDocumentCount++
    },
    FINISH_LOADING_VIEW_DOCUMENT(state) {
      state.loadingViewDocumentCount--
    },
    START_LOADING_DELETE(state) {
      state.loadingDeleteCount++
    },
    FINISH_LOADING_DELETE(state) {
      state.loadingDeleteCount--
    },
    SET_DOCUMENTS(state, documents) {
      state.documents = {
        list:
          documents && documents.length > 0
            ? documents.map(
                (document) => new CandidateDocumentViewModel(document)
              )
            : [],
        lastUpdated: dayjs(),
        isDirty: false,
      }
    },
    INSERT_DOCUMENT(state, document) {
      state.documents.list.push(new CandidateDocumentViewModel(document))
    },
    SET_DOCUMENTS_DIRTY(state) {
      if (!state.documents) return // Will prevent an exception if documents aren't initialised
      state.documents.isDirty = true
    },
    SET_FILE_TYPES(state, fileTypes) {
      state.fileTypes = {
        list: fileTypes,
        lastUpdated: dayjs(),
      }
    },
    REMOVE_DOCUMENT(state, candidateGeneralFileId) {
      if (!isNonEmptyArray(state.documents?.list)) return

      const foundIndex = state.documents.list.findIndex(
        (document) => document.id === candidateGeneralFileId
      )
      // If not found, reload summary list
      if (foundIndex < 0) {
        if (!state.documents) return
        state.documents.isDirty = true
      }

      state.documents.list.splice(foundIndex, 1)
    },
    CLEAR_STORE(state) {
      // Resets store to default state
      Object.assign(state, getDefaultState())
    },
  },
  actions: {
    /**
     * @param {{commit: Function, getters: Object, dispatch: Function}} vuexContext
     * @param {Boolean} forceRefresh
     * @returns {Promise<ResultDTO>}
     */
    async getDocumentsList({ commit, getters, dispatch }, forceRefresh) {
      // 1. Check if we already have the docs cached
      if (
        isCacheFresh({
          cacheDuration: 1,
          durationUnits: DurationUnits.HOUR,
          lastUpdated: state.documents?.lastUpdated,
          forceRefresh,
          isDirty: state.documents?.isDirty,
        })
      )
        return success({ data: getters.documents })

      // 2. Fetch docs from api
      commit('START_LOADING_DOCUMENTS')
      try {
        const response = await this.$api.documents.get()
        commit('SET_DOCUMENTS', response.data)
        return success({ data: getters.documents })
      } catch (ex) {
        toast.error(this.$i18n.t('documents.errorLoadDocuments'))
        return fail({
          error: await dispatch('logException', ex, { root: true }),
        })
      } finally {
        commit('FINISH_LOADING_DOCUMENTS')
      }
    },
    /**
     * @param {{commit: Function, getters: Object, dispatch: Function}} vuexContext
     * @param {Boolean} forceRefresh
     * @returns {Promise<ResultDTO>}
     */
    async getFileTypes({ commit, getters, dispatch }, forceRefresh) {
      // 1. Check if we already have the file types
      if (
        isCacheFresh({
          cacheDuration: 6,
          durationUnits: DurationUnits.HOUR,
          lastUpdated: state.fileTypes?.lastUpdated,
          forceRefresh,
        })
      )
        return success({ data: getters.fileTypes })

      // 2. Fetch the file types
      commit('START_LOADING_FILE_TYPES')
      try {
        const response = await this.$api.documents.getFileTypes()
        commit('SET_FILE_TYPES', response.data)
        return success({ data: getters.fileTypes })
      } catch (ex) {
        toast.error(this.$i18n.t('documents.errorLoadFileTypes'))
        return fail({
          error: await dispatch('logException', ex, { root: true }),
        })
      } finally {
        commit('FINISH_LOADING_FILE_TYPES')
      }
    },
    /**
     * @param {{commit: Function, dispatch: Function}} vuexContext
     * @param {*} documentToUpload
     * @returns {Promise<ResultDTO>}
     */
    async uploadDocument(
      { commit, dispatch, getters },
      { documentToUpload, isCacheable = true }
    ) {
      const b64File = await fileToBase64String(documentToUpload.fileBlob)

      const payload = {
        file: {
          base64FileData: b64File,
          fileName: documentToUpload.fileName,
        },
        fileType: documentToUpload.fileTypeId,
      }

      commit('START_LOADING_CRUD')
      try {
        const response = await this.$api.documents.post('', payload)

        const fileTypeFound = isNonEmptyArray(getters.fileTypes)
          ? getters.fileTypes.find((type) => type.id === payload.fileType)
          : null

        const fileTypeText = fileTypeFound ? fileTypeFound.title : ''

        if (isCacheable) {
          commit('INSERT_DOCUMENT', {
            id: response.data.id,
            type: fileTypeText,
            name: payload.file.fileName,
            file: {
              accessGui: response.data.accessGui,
              name: payload.file.fileName,
              friendlyName: payload.file.fileName,
              contentType: getType(payload.file.fileName),
            },
            isVerified: false,
            createdDateLocal: dayjs(),
          })
          commit('SET_DOCUMENTS_DIRTY')
        }

        return response
      } catch (ex) {
        toast.error(this.$i18n.t('documents.errorUploadFile'))
        return fail({
          error: await dispatch('logException', ex, { root: true }),
        })
      } finally {
        commit('FINISH_LOADING_CRUD')
      }
    },
    /**
     * @param {{commit: Function, dispatch: Function}} vuexContext
     * @param {Number} documentId
     * @returns {Promise<ResultDTO>}
     */
    async getDocumentForView({ commit, dispatch }, documentId) {
      try {
        commit('START_LOADING_VIEW_DOCUMENT')
        const response = await this.$api.documents.get(documentId)
        response.data = new CandidateDocumentViewModel(response.data)
        return response
      } catch (ex) {
        return fail({
          error: await dispatch('logException', ex, { root: true }),
        })
      } finally {
        commit('FINISH_LOADING_VIEW_DOCUMENT')
      }
    },
    /**
     * @param {{commit: Function, dispatch: Function}} vuexContext
     * @param {Number} documentId
     * @returns {Promise<ResultDTO>}
     */
    async downloadDocument(
      { commit, dispatch },
      { documentId, accessGui, disableToast = false }
    ) {
      commit('START_LOADING')
      try {
        const response = await this.$api.documents.downloadFile(
          documentId,
          accessGui
        )
        const fileName = extractFileNameFromContentDisposition(
          response.headers[HttpHeaders.contentDisposition]
        )
        const blob = new Blob([response.data], {
          type: getType(fileName),
        })
        return success({ data: blob })
      } catch (ex) {
        if (!disableToast) {
          toast.error(this.$i18n.t('error.unableToLoadFile'))
        }
        return fail({
          error: await dispatch('logException', ex, { root: true }),
        })
      } finally {
        commit('FINISH_LOADING')
      }
    },
    /**
     * Deletes a candidate general file
     * @param {{commit: Function, dispatch: Function}} vuexContext
     * @param {Number} candidateGeneralFileId
     * @returns {Promise<ResultDTO>}
     */
    async deleteDocument({ commit, dispatch }, candidateGeneralFileId) {
      commit('START_LOADING_DELETE')
      try {
        const response = await this.$api.documents.delete(
          candidateGeneralFileId
        )

        commit('REMOVE_DOCUMENT', candidateGeneralFileId)

        toast.success(this.$i18n.t('documents.deleteSuccessfulText'))

        return response
      } catch (ex) {
        const error = await dispatch('logException', ex, { root: true })
        toast.error(
          DocumentsActionErrorMessageFactory({
            actionName: 'deleteDocument',
            error,
            i18n: this.$i18n,
          })
        )
        return fail({ error, statusCode: ex.response.status })
      } finally {
        commit('FINISH_LOADING_DELETE')
      }
    },
    /**
     * Resets store to default state.
     */
    clear({ commit }) {
      commit('CLEAR_STORE')
    },
  },
}
