import DOMPurify from 'dompurify'

/**
 * Sanitzies strings containing html to remove potentially harmful code and XSS attacks
 * @param {String} dirtyHtml Potentially unsanitised HTML
 * @returns
 */
export default function sanitizeHtml(dirtyHtml) {
  return DOMPurify.sanitize(dirtyHtml, {
    USE_PROFILES: { html: true },
  })
}
