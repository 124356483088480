export default Object.freeze({
  home: {
    name: 'home',
    path: '/',
  },
  myAvailability: {
    name: 'myAvailability',
    path: '/availability',
  },
  myRegistrations: {
    name: 'myRegistrations',
    path: '/registrations',
  },
  registrationDetails: {
    name: 'registrationDetails',
    path: '/registrations/:registrationId',
  },
  groupBookingDetailsGuestAccess: {
    name: 'groupBookingDetailsGuestAccess',
    path: '/bookings/group/guest/:accessGuid',
    alias: '/booking/group/:accessGuid', // Actual path used as guest access link was not updated and is using the r2w 1.0 style
  },
  bookingDetailsGuestAccess: {
    name: 'bookingDetailsGuestAccess',
    path: '/bookings/:shortTermTentativeId/guest/:accessGuid',
    alias: '/booking/:shortTermTentativeId/:accessGuid', // Actual path used as guest access link was not updated and is using the r2w 1.0 style
  },
  bookingDetails: {
    name: 'bookingDetails',
    path: '/bookings/:bookingId',
  },
  myFiles: {
    name: 'myFiles',
    path: '/my-files',
  },
  profDevelopment: {
    name: 'profDevelopment',
    path: '/professional-development',
  },
  downloads: {
    name: 'downloads',
    path: '/downloads',
  },
  contact: {
    name: 'contact',
    path: '/contact',
  },
  help: {
    name: 'help',
    path: '/help',
  },
  settings: {
    name: 'settings',
    path: '/settings',
  },
  changePassword: {
    name: 'changePassword',
    path: '/settings/change-password',
  },
  login: {
    name: 'login',
    path: '/login',
  },
  accountSetup: {
    name: 'accountSetup',
    path: '/account/setup',
  },
  impersonateLogout: {
    name: 'impersonateLogout',
    path: '/user/impersonate/logout',
  },
  impersonateLogin: {
    name: 'impersonateLogin',
    path: '/user/impersonate/:candidateId',
  },
  logout: {
    name: 'logout',
    path: '/logout',
  },
  underConstruction: {
    name: 'underConstruction',
    path: '/under-construction',
  },
  notFound: {
    name: 'NotFoundPage',
    path: '/404',
  },
  error: {
    name: 'ErrorPage',
    path: '/500',
  },
  unauthorized: {
    name: 'UnauthorizedPage',
    path: '/401',
  },
  accountLoadFailure: {
    name: 'AccountLoadFailedPage',
  },
  accountSuspended: {
    name: 'AccountSuspendedPage',
  },
})
