/**
 * @class
 * @public
 */
export default class MonthYearDifferentDTO {
  /**
   * @param {{ isMonthDifferent: Boolean, isYearDifferent: Boolean }} param0
   */
  constructor({ isMonthDifferent = false, isYearDifferent = false } = {}) {
    /**
     * @type {Boolean}
     */
    this.isMonthDifferent = isMonthDifferent
    /**
     * @type {Boolean}
     */
    this.isYearDifferent = isYearDifferent
  }
}
