import { isEmptyArray } from '../../helpers/array-helpers'
// eslint-disable-next-line no-unused-vars
import CandidateClientAgreementsViewModel from '@/models/client/candidateClientAgreementsViewModel'
// eslint-disable-next-line no-unused-vars
import CandidateDocumentAgreementUpdateViewModel from '@/models/candidate/candidateDocumentAgreementUpdateViewModel'
// eslint-disable-next-line no-unused-vars
import CandidateDocumentAgreementViewModel from '@/models/candidate/candidateDocumentAgreementViewModel'
import UploadedFileType from '@/constants/file/UploadedFileType'

const getDefaultState = () => {
  return {
    // Place any new state properties here
    loadingCount: 0,
    crudLoadingCount: 0,
    /**
     * @type {CandidateClientAgreementsViewModel[]}
     */
    actionableItems: [],
  }
}

const state = getDefaultState()

export default {
  namespaced: true,
  /**
   * @typedef {{
   * loadingCount: number
   * crudLoadingCount: number
   * actionableItems: CandidateClientAgreementsViewModel[]
   * }} ClientState
   * @type {ClientState}
   */
  state,
  getters: {
    moduleName: () => 'clients',
    isLoading: (state) => state.loadingCount > 0,
    isLoadingCRUD: (state) => state.crudLoadingCount > 0,
    /**
     * @returns {CandidateClientAgreementsViewModel[]}
     */
    allActionableItems: (state) => state.actionableItems,
    /**
     * @param {ClientState} state
     * @param {number} clientId
     * @returns {CandidateClientAgreementsViewModel}
     */
    getActionableItemsByClient: (state) => (clientId) => {
      return state.actionableItems.find(
        (clientActionableItems) => clientActionableItems.clientId === clientId
      )
    },
    /**
     * @returns {CandidateDocumentAgreementViewModel[]}
     */
    getAgreementsByClientId: (state, getters) => (clientId) => {
      const actionableItems = getters.getActionableItemsByClient(clientId)
      return actionableItems?.agreements || []
    },
    /**
     * @returns {CandidateDocumentAgreementViewModel}
     */
    getAgreementByType:
      (state, getters) =>
      (clientId, type = UploadedFileType.unset) => {
        /**
         * @type {CandidateDocumentAgreementViewModel[]}
         */
        const agreements = getters.getAgreementsByClientId(clientId)
        return agreements.find((agreement) => agreement.type === type)
      },
    /**
     * @param {ClientState} state
     * @param {*} getters
     * @param {Number} clientId
     * @returns {CandidateDocumentAgreementViewModel[]}
     */
    getPendingAgreementsByClientId: (state, getters) => (clientId) => {
      /**
       * @type {CandidateDocumentAgreementViewModel[]}
       */
      const agreements = getters.getAgreementsByClientId(clientId)
      const pendingAgreements = agreements.filter(
        (agreement) => !agreement.dateAgreedUTC
      )
      return pendingAgreements
    },
    /**
     *
     * @param {ClientState} state
     * @param {*} getters
     * @param {Number} clientId
     * @returns {Boolean}
     */
    doesClientHavePendingAgreements: (state, getters) => (clientId) => {
      /**
       * @type {CandidateDocumentAgreementViewModel[]}
       */
      const agreements = getters.getPendingAgreementsByClientId(clientId)
      return !isEmptyArray(agreements)
    },
    getTotalAgreementsCountByClientId: (state, getters) => (clientId) => {
      const agreements = getters.getAgreementsByClientId(clientId)
      return agreements.length
    },
    getPendingAgreementsCountByClientId: (state, getters) => (clientId) => {
      const pendingAgreements = getters.getPendingAgreementsByClientId(clientId)
      return pendingAgreements.length
    },
  },
  mutations: {
    START_LOADING(state) {
      state.loadingCount++
    },
    FINISH_LOADING(state) {
      state.loadingCount--
    },
    START_LOADING_CRUD(state) {
      state.crudLoadingCount++
    },
    FINISH_LOADING_CRUD(state) {
      state.crudLoadingCount--
    },
    /**
     * @param {ClientState} state
     * @param {CandidateClientAgreementsViewModel[]} actionableItems
     */
    INSERT_ACTIONABLE_ITEMS(state, actionableItems) {
      let found

      for (const actionableItem of actionableItems) {
        found = state.actionableItems.find(
          (x) => x.clientId === actionableItem.clientId
        )

        if (!found) {
          state.actionableItems.push(actionableItem)
        } else {
          Object.assign(found, actionableItem)
        }
      }
    },
    UPDATE_ACTIONABLE_ITEM(state, agreementUpdateData) {
      const clientFound = state.actionableItems.find(
        (x) => x.clientId === agreementUpdateData.clientId
      )
      if (clientFound) {
        const agreementFound = clientFound.agreements.find(
          (x) => x.fileId === agreementUpdateData.fileId
        )
        if (agreementFound) {
          agreementFound.dateAgreedUTC = agreementUpdateData.dateAgreedUTC
        }
      }
    },
    CLEAR_STORE(state) {
      // Resets store to default state
      Object.assign(state, getDefaultState())
    },
  },
  actions: {
    /**
     * @param {object} VuexAction
     * @param {CandidateClientAgreementsViewModel[]} actionableItems
     */
    insertActionableItems({ commit }, actionableItems) {
      commit('INSERT_ACTIONABLE_ITEMS', actionableItems)
    },
    /**
     * Updates agreement
     * @param {object} VuexAction
     * @param {CandidateDocumentAgreementUpdateViewModel} agreementUpdateData
     */
    updateActionableItemForAgreement({ commit }, agreementUpdateData) {
      commit('UPDATE_ACTIONABLE_ITEM', agreementUpdateData)
    },
    /**
     * Resets store to default state.
     */
    clear({ commit }) {
      commit('CLEAR_STORE')
    },
  },
}
