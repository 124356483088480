/**
 * Contains information regarding the payment details for a booking
 * @class
 * @public
 */
export default class CandidateBookingSummaryPaymentViewModel {
  /**
   * @param {{ payOptionType: string }} param0
   */
  constructor({ payOptionType = '' } = {}) {
    /**
     * @type {string}
     */
    this.payOptionType = payOptionType
  }
}
