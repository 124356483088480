<script>
import camelCase from 'lodash/camelCase'
export default {
  inheritAttrs: false,
  props: {
    source: {
      type: String,
      default: 'mdi',
    },
    name: {
      type: String,
      required: true,
    },
  },
  computed: {
    // Gets a CSS module class, e.g. iconCustomLogo
    customIconClass() {
      return this.$style[camelCase('icon-custom-' + this.name)]
    },
  },
}
</script>

<template>
  <v-icon v-if="source === 'mdi'" v-bind="$attrs">{{ name }}</v-icon>
  <span
    v-else-if="source === 'custom'"
    v-bind="$attrs"
    :class="customIconClass"
  />
</template>
