import CandidateBookingSummaryLocationAddressViewModel from './candidateBookingSummaryLocationAddressViewModel'

/**
 * A location's basic details and address information
 * @class
 * @public
 */
export default class CandidateBookingSummaryLocationViewModel {
  /**
   * @param {{ id: number, name: string, address: CandidateBookingSummaryLocationAddressViewModel }} param0
   */
  constructor({
    id = null,
    name = '',
    address = new CandidateBookingSummaryLocationAddressViewModel(),
  } = {}) {
    /**
     * Location Id
     * @type {number}
     */
    this.id = id
    /**
     * Location name
     * @type {string}
     */
    this.name = name
    /**
     * @type {CandidateBookingSummaryLocationAddressViewModel}
     */
    this.address = address
  }
}
