import ApiErrorCodes from '@/constants/api/ApiErrorCodes'
import HttpStatusCodes from '@/constants/api/HttpStatusCodes'
// eslint-disable-next-line no-unused-vars
import ErrorResponseDTO from '@/models/error/errorResponseDTO'

// Factory

/**
 * Factory to handle generating error messages for document actions
 * @param {{actionName: String, error: ErrorResponseDTO, i18n: {t: Function}}} payload
 * @returns
 */
export function DocumentsActionErrorMessageFactory({
  actionName,
  error,
  i18n,
}) {
  switch (actionName) {
    case 'deleteDocument':
      return deleteDocumentActionErrorMessageHandler({ error, i18n })
    default:
      return error?.message
  }
}

// Handlers

/**
 * Generate error message for the deleteDocument store action
 * @param {{error: ErrorResponseDTO, i18n: {t: Function}}} payload
 * @returns
 */
const deleteDocumentActionErrorMessageHandler = ({ error, i18n }) => {
  let errorMessage = error.message

  switch (error?.error?.response?.status) {
    case HttpStatusCodes.NotFound:
      errorMessage = i18n.t('documents.error.notFound')
      break
    case HttpStatusCodes.BadRequest:
      if (error.code === ApiErrorCodes.Documents.CannotDeleteVerified)
        errorMessage = i18n.t('files.unableToRemoveVerifiedGeneralFileToolTip')
      break
  }

  return errorMessage
}
