/**
 * @class
 * @public
 */
export default class AccessGuiFileViewModel {
  constructor({
    accessGui = '',
    name = '',
    friendlyName = '',
    contentType = '',
    fileSizeBytes = 0,
  } = {}) {
    /**
     * A guid referencing an uploaded file
     * @type {String}
     */
    this.accessGui = accessGui

    /**
     * Raw file name. Used as a backup if friendlyName isn't set
     * @type {String}
     */
    this.name = name

    /**
     * Friendly name for the application general file
     * @type {String}
     */
    this.friendlyName = friendlyName

    /**
     * MIME type (e.g. application/pdf)
     * @type {String}
     */
    this.contentType = contentType

    /**
     * @type {Number}
     */
    this.fileSizeBytes = fileSizeBytes
  }
}
