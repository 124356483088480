<script>
import baseMixin from '@mixins/baseMixin'
import publicViewMixin from '@mixins/publicViewMixin'
import TheSystemBarContainer from '@/components/layout/the-system-bar-container.vue'

export default {
  name: 'LoadingLayout',
  components: {
    TheSystemBarContainer,
  },
  mixins: [baseMixin, publicViewMixin],
}
</script>

<template>
  <v-app id="ready-2-work" :dark="setTheme()">
    <TheSystemBarContainer />
    <v-main class="r2w_bg">
      <v-container class="fill-height" fluid>
        <v-layout align-center justify-center>
          <v-flex xs12 sm8 md6>
            <slot />
          </v-flex>
        </v-layout>
      </v-container>
    </v-main>
  </v-app>
</template>
