/**
 * Color object that contains both foreground and background colours
 */
export default class ColorSet {
  constructor({ foreground = '#000', background = '#FFF' } = {}) {
    /**
     * Hex color value. Color for elements in the foreground, typically icons or text
     * @type {String}
     * @example #00ACEF
     */
    this.foreground = foreground

    /**
     * Hex color value. Color for elements in the background
     * @type {String}
     * @example #00ACEF
     */
    this.background = background
  }
}
