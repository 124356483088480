/* eslint-disable no-unused-vars */
import { ModelApiService } from './BaseApiService'
import OngoingAvailabilityViewModel from '@/models/availability/ongoingAvailabilityViewModel'

export default class AvailabilityApiService extends ModelApiService {
  constructor() {
    super('availability')
  }

  /**
   * Calls the API to update a candidate's availability for multiple days.
   * @param {AvailabilityStatusDTO} newAvailabilities
   * @returns
   */
  setAvailabilityStatus(newAvailabilities) {
    return this.put('', newAvailabilities)
  }

  /**
   * Sets a candidate's ongoing/permanent availability
   * @param {OngoingAvailabilityViewModel} payload
   * @returns
   */
  setOngoingAvailability(payload) {
    return this.put('ongoing', payload)
  }
}
