import featureToggleOptions from '@/constants/features/featureToggleOptions'
import routeDefinitions from '@/constants/routes/routeDefinitions'

const isEnabled = (key, featureToggles) => {
  if (
    !featureToggles ||
    !Object.prototype.hasOwnProperty.call(featureToggles, key)
  )
    return false

  const featureToggle = featureToggles[key]
  return featureToggle === featureToggleOptions.enabled
}

/**
 * Generates a series of feature toggle functions that will instruct which features
 * are enabled or not
 * @param {Object} featureToggles Dictionary of feature toggles
 */
export const createFeatureDecisions = (featureToggles) => {
  return {
    // #region Rego Files
    displayWorkExpInMyFiles() {
      return isEnabled(
        'feature_workExperience_displayInMyFiles',
        featureToggles
      )
    },
    canViewWorkExpDetails() {
      return isEnabled('feature_workExperience_view', featureToggles)
    },
    canDeleteWorkExp() {
      return isEnabled('feature_workExperience_delete', featureToggles)
    },
    canDownloadWorkExpFiles() {
      return isEnabled('feature_workExperience_download', featureToggles)
    },
    canCreateWorkExp() {
      return isEnabled('feature_workExperience_create', featureToggles)
    },

    displayQualificationsInMyFiles() {
      return isEnabled(
        'feature_qualifications_displayInMyFiles',
        featureToggles
      )
    },
    canViewQualificationDetails() {
      return isEnabled('feature_qualifications_view', featureToggles)
    },
    canDeleteQualifications() {
      return isEnabled('feature_qualifications_delete', featureToggles)
    },
    canDownloadQualificationFiles() {
      return isEnabled('feature_qualifications_download', featureToggles)
    },
    canCreateQualifications() {
      return isEnabled('feature_qualifications_create', featureToggles)
    },

    displayCanidateGeneralFilesInMyFiles() {
      return isEnabled(
        'feature_candidateGeneralFile_displayInMyFiles',
        featureToggles
      )
    },
    canViewCanidateGeneralFileDetails() {
      return isEnabled('feature_candidateGeneralFile_view', featureToggles)
    },
    canDeleteCanidateGeneralFiles() {
      return isEnabled('feature_candidateGeneralFile_delete', featureToggles)
    },
    canDownloadCanidateGeneralFiles() {
      return isEnabled('feature_candidateGeneralFile_download', featureToggles)
    },
    canCreateCanidateGeneralFiles() {
      return isEnabled('feature_candidateGeneralFile_create', featureToggles)
    },

    displayCertificationsInMyFiles() {
      return isEnabled(
        'feature_certifications_displayInMyFiles',
        featureToggles
      )
    },
    canViewCertificationDetails() {
      return isEnabled('feature_certifications_view', featureToggles)
    },
    canDeleteCertifications() {
      return isEnabled('feature_certifications_delete', featureToggles)
    },
    canDownloadCertificationFiles() {
      return isEnabled('feature_certifications_download', featureToggles)
    },
    canCreateCertifications() {
      return isEnabled('feature_certifications_create', featureToggles)
    },
    // #endregion

    // #region Registrations
    canViewRegistrationDetails() {
      return isEnabled('feature_registrations_viewDetails', featureToggles)
    },
    canViewAgreementsTab() {
      return isEnabled(
        'feature_registrations_viewAgreementsTab',
        featureToggles
      )
    },
    // #endregion

    // #region Bookings
    isRunningLateEnabled() {
      return isEnabled('feature_runningLate', featureToggles)
    },
    // #endregion
  }
}

/**
 * Returns value of route toggle if set or undefined
 * @param {Object} featureToggles Dictionary of feature toggles
 * @param {String} routeName
 * @returns
 */
const getRouteToggle = (featureToggles, routeName) => {
  if (
    !featureToggles ||
    !Object.prototype.hasOwnProperty.call(featureToggles, `route_${routeName}`)
  )
    return featureToggleOptions.enabled
  return featureToggles[`route_${routeName}`]
}

/**
 * Makes routing decisions based on feature toggles and the desired next route
 * @param {Object} featureToggles Dictionary of feature toggles
 * @param {*} routeTo
 * @param {Function} next
 */
export const decideRouteBasedOnFeatureToggles = (
  featureToggles,
  routeTo,
  next
) => {
  // Check if route toggle exists. If yes, decide based on value. If not, assume enabled
  const routeToggle = getRouteToggle(featureToggles, routeTo.name)

  switch (routeToggle) {
    case featureToggleOptions.enabled:
    case undefined:
      return next()
    case featureToggleOptions.underConstruction:
      return next({
        name: routeDefinitions.underConstruction.name,
        query: { redirectFrom: routeTo.fullPath },
      })
    case featureToggleOptions.disabled:
    default:
      return next({
        name: routeDefinitions.notFound.name,
        params: [routeTo.fullPath],
        replace: true,
      })
  }
}

/**
 * Checks route toggles to determine if a nav item should be displayed or not
 * @param {Object} featureToggles Dictionary of feature toggles
 * @param {String} routeName
 * @returns
 */
export const displayNavItem = (featureToggles, routeName) => {
  const routeToggle = getRouteToggle(featureToggles, routeName)
  return routeToggle === featureToggleOptions.enabled
}
