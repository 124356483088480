import httpStatus from 'statuses'

const isHttpStatus = (response, statusCode = 'OK') => {
  return response === httpStatus(statusCode)
}

const isSuccess = (response) => {
  return (
    isHttpStatus(response, 'OK') ||
    isHttpStatus(response, 'No Content') ||
    isHttpStatus(response, 'Created')
  )
}

export { isSuccess, isHttpStatus }
