/* eslint-disable no-unused-vars */
import EventType from '@/constants/event/EventType'
import EventStatus from '@/constants/date/EventStatus'
import EventCostViewModel from '@/models/event/eventCostViewModel'
import EventLocationViewModel from '@/models/event/eventLocationViewModel'
import Distance from '@/models/general/distance'

/**
 * Summary data for an event
 */
export default class EventSummaryViewModel {
  constructor({
    id,
    registrationURL,
    type,
    title,
    startDateTimeUTC,
    endDateTimeUTC,
    timeZone,
    cost,
    status,
    numberOfSpaces,
    enrolledCount,
    remainingSpaces,
    isFull,
    description,
    distance,
    facilitatorName,
    createdDateUTC,
    location,
  } = {}) {
    /**
     * @type {Number}
     */
    this.id = id

    /**
     * @type {String}
     */
    this.registrationURL = registrationURL

    /**
     * @type {EventType}
     */
    this.type = type

    /**
     * @type {String}
     */
    this.title = title

    /**
     * @type {Date}
     */
    this.startDateTimeUTC = startDateTimeUTC

    /**
     * @type {Date}
     */
    this.endDateTimeUTC = endDateTimeUTC

    /**
     * @type {String}
     */
    this.timeZone = timeZone

    /**
     * @type {EventCostViewModel}
     */
    this.cost = cost

    /**
     * @type {EventStatus}
     */
    this.status = status

    /**
     * @type {Number}
     */
    this.numberOfSpaces = numberOfSpaces

    /**
     * @type {Number}
     */
    this.enrolledCount = enrolledCount

    /**
     * @type {Number}
     */
    this.remainingSpaces = remainingSpaces

    /**
     * @type {Boolean}
     */
    this.isFull = isFull

    /**
     * @type {String}
     */
    this.description = description

    /**
     * @type {Distance}
     */
    this.distance = distance

    /**
     * @type {String}
     */
    this.facilitatorName = facilitatorName

    /**
     * @type {Date}
     */
    this.createdDateUTC = createdDateUTC

    /**
     * @type {EventLocationViewModel}
     */
    this.location = location
  }
}
