export default class VueErrorDTO {
  constructor({ error, vm, info } = {}) {
    /**
     * @type {Object} complete error trace, contains the `message` and `error stack`
     */
    this.error = error

    /**
     * @type {Object} Vue component/instance in which error is occurred
     */
    this.vm = vm

    /**
     * @type {Object} info Vue specific error information such as lifecycle hooks, events etc.
     */
    this.info = info
  }
}
