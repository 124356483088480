import MediaQueryBreakPoint from '@/models/general/mediaQueryBreakPoint'

/**
 * Device pixel breakpoints to be used in conjunction with VueMQ & Vuetify components.
 * Pixel breakpoints are based on Vuetify's breakpoint values: https://vuetifyjs.com/en/features/breakpoints/
 */
export default Object.freeze({
  /**
   * Extra small - Small to large phone
   */
  xs: new MediaQueryBreakPoint({
    code: 'xs',
    size: 600,
  }),
  /**
   * Small - Small to medium tablet
   */
  sm: new MediaQueryBreakPoint({
    code: 'sm',
    size: 960,
  }),
  /**
   * Medium - Large tablet to laptop
   */
  md: new MediaQueryBreakPoint({
    code: 'md',
    size: 1264,
  }),
  /**
   * Large - Desktop
   */
  lg: new MediaQueryBreakPoint({
    code: 'lg',
    size: 1904,
  }),
  /**
   * Extra large - 	4k and ultra-wide
   */
  xl: new MediaQueryBreakPoint({
    code: 'xl',
    size: Infinity,
  }),
})
