export default Object.freeze({
  /**
   * Initial state
   */
  unset: 'unset',
  /**
   * Date happened in the past
   */
  past: 'past',
  /**
   * Date is on the current day or currently happening
   */
  present: 'present',
  /**
   * Date will happen in the future
   */
  future: 'future',
})
