import { mapActions, mapGetters } from 'vuex'
import DOMPurify from 'dompurify'
import config from '@/common/config'
import MediaQueryBreakPoints from '@/constants/core/MediaQueryBreakPoints'
import Environment from '@/constants/core/Environment'
import routeDefinitions from '@/constants/routes/routeDefinitions'

export default {
  computed: {
    ...mapGetters({
      isDebug: 'isDebugModeActive',
      isDarkMode: 'isDarkModeActive',
      hasImpersonateCandidateId: 'auth/hasImpersonateCandidateId',
      isAuthenticated: 'auth/isUserLoggedIn',
      startOfWeek: 'startOfWeek',
    }),
    isExtraSmall() {
      return this.$mq === MediaQueryBreakPoints.xs.code
    },
    isSmall() {
      return this.$mq === MediaQueryBreakPoints.sm.code
    },
    isMedium() {
      return this.$mq === MediaQueryBreakPoints.md.code
    },
    isLarge() {
      return this.$mq === MediaQueryBreakPoints.lg.code
    },
    isExtraLarge() {
      return this.$mq === MediaQueryBreakPoints.xl.code
    },
    isSmallOrLower() {
      return this.isSmall || this.isExtraSmall
    },
    isMediumOrLower() {
      return this.isSmall || this.isExtraSmall || this.isMedium
    },
    isLargeOrLower() {
      return this.isSmall || this.isExtraSmall || this.isMedium || this.isLarge
    },
    isSmallOrHigher() {
      return this.isSmall || this.isMedium || this.isLarge || this.isExtraLarge
    },
    isMediumOrHigher() {
      return this.isMedium || this.isLarge || this.isExtraLarge
    },
    isLargeOrHigher() {
      return this.isLarge || this.isExtraLarge
    },
    isMobileViewPort() {
      return this.isExtraSmall || this.isSmall || this.isMedium
    },
    isDev() {
      return config.get('env') === Environment.development
    },
    isImpersonating() {
      return this.hasImpersonateCandidateId
    },
    heightOfDevBar() {
      return this.isDev ? 24 : 0 // in pixels
    },
    locale() {
      return this.$i18n.locale
    },
    getStartOfWeek() {
      return this.startOfWeek(this.locale)
    },
    $_routeDefinitions() {
      return routeDefinitions
    },
  },
  methods: {
    ...mapActions({
      addDebugMessage: 'addDebugMessage',
      logException: 'logException',
    }),
    logger(msg) {
      this.addDebugMessage(`${msg}`)
    },
    setTheme() {
      if (this.isDarkMode) {
        return (this.$vuetify.theme.dark = true)
      } else {
        return (this.$vuetify.theme.dark = false)
      }
    },
    /**
     * Sanitize strings containing html for use with v-html to prevent
     * XSS attacks
     * Learn more: https://v2.vuejs.org/v2/guide/security.html#Potential-Dangers
     * @param {String} stringToBeSanitized String containing html syntax
     * @returns sanitized html string
     */
    sanitize(stringToBeSanitized) {
      return DOMPurify.sanitize(stringToBeSanitized, {
        USE_PROFILES: { html: true },
      })
    },
    async scrollToFirstInputError() {
      await this.$nextTick()
      const element = document.querySelector('.v-input .error--text')
      if (element) {
        element.scrollIntoView({ behavior: 'smooth', block: 'nearest' })
      }
    },
  },
}
