import ColorSet from '@/models/general/colorSet'

// Hash any string into an integer value
export const hashCode = (str, hash) => {
  if (hash === undefined) {
    hash = 0
  }
  for (let i = 0; i < str.length; i++) {
    hash = str.charCodeAt(i) + ((hash << 5) - hash)
  }
  return hash
}

// Convert an int to hexadecimal with a max length
// of six characters.
export const intToARGB = (i) => {
  const h =
    ((i >> 24) & 0xff).toString(16) +
    ((i >> 16) & 0xff).toString(16) +
    ((i >> 8) & 0xff).toString(16) +
    (i & 0xff).toString(16)
  return h.substring(0, 6)
}

export const hexToRgb = (hex) => {
  const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex)
  return result
    ? {
        r: parseInt(result[1], 16),
        g: parseInt(result[2], 16),
        b: parseInt(result[3], 16),
      }
    : null
}

const COLOURS = {
  red: new ColorSet({ background: '#f7b4b4', foreground: '#5e0909' }),
  orange: new ColorSet({ background: '#f5c6b8', foreground: '#5c1f0c' }),
  light_orange: new ColorSet({ background: '#f8deb5', foreground: '#603e09' }),
  yellow: new ColorSet({ background: '#fbefb8', foreground: '#675505' }),
  light_green: new ColorSet({ background: '#d6e7aa', foreground: '#374413' }),
  aqua: new ColorSet({ background: '#aed0c1', foreground: '#1c3127' }),
  blue: new ColorSet({ background: '#a6e5e1', foreground: '#13413f' }),
  light_blue: new ColorSet({ background: '#cef3f1', foreground: '#175e5a' }),
  dark_blue: new ColorSet({ background: '#a2b9e8', foreground: '#102143' }),
  purple: new ColorSet({ background: '#c6c1f3', foreground: '#18115c' }),
  light_purple: new ColorSet({ background: '#d9b5e9', foreground: '#3a164a' }),
  pink: new ColorSet({ background: '#fcd4f7', foreground: '#750868' }),
  dark_pink: new ColorSet({ background: '#f8b7d8', foreground: '#610936' }),
  dark_red: new ColorSet({ background: '#fdcbcc', foreground: '#750307' }),
  ateam_purple: new ColorSet({ background: '#ede7f6', foreground: '#3d3088' }),
  anzuk_blue: new ColorSet({ background: '#ceffff', foreground: '#00acef' }),
}

const COLOURS_LIST = Object.values(COLOURS)

export const avatarColourSets = COLOURS
export const stringToPredefinedColourSet = (string) => {
  if (!string || !string.trim()) return COLOURS.ateam_purple
  return COLOURS_LIST[Math.abs(hashCode(string)) % COLOURS_LIST.length]
}
export const stringToPredefinedForegroundColour = (string) =>
  stringToPredefinedColourSet(string).foreground
export const stringToPredefinedBackgroundColour = (string) =>
  stringToPredefinedColourSet(string).background

export const returnHTMLColor = (str) => intToARGB(hashCode(str))
