import config from '@/common/config'

/**
 * DEPRECIATED: Don't use, okay for a best guess but often users don't know what their browser's language is set to
 * Attempts to detect the first language selected from the browser (since modern browsers come with an array of preferred languages).
 * It is also backwards compatible with older browsers and mobile.
 *
 * Sourced from https://stackoverflow.com/a/46514247 on 06/09/2021 01:46 PM
 * @returns String | null
 */
export const getFirstBrowserLanguage = () => {
  const nav = window.navigator
  const browserLanguagePropertyKeys = [
    'language',
    'browserLanguage',
    'systemLanguage',
    'userLanguage',
  ]
  let i
  let language
  let len
  let shortLanguage = null

  // support for HTML 5.1 "navigator.languages"
  if (Array.isArray(nav.languages)) {
    for (i = 0; i < nav.languages.length; i++) {
      language = nav.languages[i]
      len = language.length
      if (!shortLanguage && len) {
        shortLanguage = language
      }
      if (language && len > 2) {
        return language
      }
    }
  }

  // support for other well known properties in browsers
  for (i = 0; i < browserLanguagePropertyKeys.length; i++) {
    language = nav[browserLanguagePropertyKeys[i]]
    // skip this loop iteration if property is null/undefined. IE11 fix.
    if (language == null) {
      continue
    }
    len = language.length
    if (!shortLanguage && len) {
      shortLanguage = language
    }
    if (language && len > 2) {
      return language
    }
  }

  return shortLanguage
}

/**
 * Returns language key based on URL client used to access CL
 * @returns {String} Language key (e.g. en-US)
 */
export const getLanguageBasedOnBaseURL = () => {
  const baseUrl = window.location.origin

  // key-pair { langKey: url }
  // e.g. { 'en-US': 'https://example.com' }
  const urls = config.get('i18n.urls')

  if (!urls) {
    // Suppress warning while unit testing
    if (process.env.NODE_ENV !== 'test')
      console.warn("Unable to parse url config. Defaulting language to 'en'")
    return config.get('i18n.locale')
  }

  // Iterate over supported urls to find a match
  for (const [key, value] of Object.entries(urls))
    if (value.includes(baseUrl)) return key

  // Defaults to en
  return config.get('i18n.locale')
}
