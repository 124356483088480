/**
 * Tokens used to format dates
 * @tutorial https://day.js.org/docs/en/display/format
 */
export default Object.freeze({
  // --- 01. Date --------------------------------------------------------------

  /**
   * Two-digit year
   * @example 18
   */
  YY: 'YY',
  /**
   * Four-digit year
   * @example 2018
   */
  YYYY: 'YYYY',
  /**
   * The month, beginning at 1
   * @example 1-12
   */
  M: 'M',
  /**
   * The month, 2-digits
   * @example 01-12
   */
  MM: 'MM',
  /**
   * The abbreviated month name
   * @example Jan-Dec
   */
  MMM: 'MMM',
  /**
   * The full month name
   * @example January-December
   */
  MMMM: 'MMMM',
  /**
   * The day of the month
   * @example 1-31
   */
  D: 'D',
  /**
   * The day of the month, 2-digits
   * @example 01-31
   */
  DD: 'DD',
  /**
   * The day of the week, with Sunday as 0
   * @example 0-6
   */
  d: 'd',
  /**
   * The min name of the day of the week
   * @example Su-Sa
   */
  dd: 'dd',
  /**
   * The short name of the day of the week
   * @example Sun-Sat
   */
  ddd: 'ddd',
  /**
   * The name of the day of the week
   * @example Sunday-Saturday
   */
  dddd: 'dddd',
  /**
   * Quarter
   * @example 1-4
   */
  Q: 'Q',
  /**
   * Day of Month with ordinal
   * @example 1st 2nd ... 31st
   */
  Do: 'Do',
  /**
   * 	Unix Timestamp in second
   * @example 1360013296
   */
  X: 'X',
  /**
   * 	Unix Timestamp in millisecond
   * @example 1360013296123
   */
  x: 'x',

  // --- 02. Time --------------------------------------------------------------

  /**
   * The hour, 24-hour clock
   * @example 0-23
   */
  H: 'H',
  /**
   * The hour, 2-digits, 24-hour clock
   * @example 00-23
   */
  HH: 'HH',
  /**
   * The hour, 12-hour clock
   * @example 1-12
   */
  h: 'h',
  /**
   * The hour, 12-hour clock, 2-digits
   * @example 01-12
   */
  hh: 'hh',
  /**
   * The minute
   * @example 0-59
   */
  m: 'm',
  /**
   * The minute, 2-digits
   * @example 00-59
   */
  mm: 'mm',
  /**
   * The second
   * @example 0-59
   */
  s: 's',
  /**
   * The second, 2-digits
   * @example 00-59
   */
  ss: 'ss',
  /**
   * The millisecond, 3-digits
   * @example 000-999
   */
  SSS: 'SSS',
  /**
   * The offset from UTC, ±HH:mm
   * @example +05:00
   */
  Z: 'Z',
  /**
   * The offset from UTC, ±HHmm
   * @example +0500
   */
  ZZ: 'ZZ',
  /**
   * Meridian, capitalised
   * @example AM/PM
   */
  A: 'A',
  /**
   * Meridian, lowercase
   * @example am/pm
   */
  a: 'a',

  // --- 03. Localised ---------------------------------------------------------

  /**
   * h:mm A
   * @example 8:02 PM
   */
  LT: 'LT',
  /**
   * h:mm:ss A
   * @example 8:02:18 PM
   */
  LTS: 'LTS',
  /**
   * MM/DD/YYYY
   * @example 08/16/2018
   */
  L: 'L',
  /**
   * MMMM D, YYYY
   * @example August 16, 2018
   */
  LL: 'LL',
  /**
   * MMMM D, YYYY h:mm A
   * @example August 16, 2018 8:02 PM
   */
  LLL: 'LLL',
  /**
   * dddd, MMMM D, YYYY h:mm A
   * @example Thursday, August 16, 2018 8:02 PM
   */
  LLLL: 'LLLL',
  /**
   * M/D/YYYY
   * @example 8/16/2018
   */
  l: 'l',
  /**
   * MMM D, YYYY
   * @example Aug 16, 2018
   */
  ll: 'll',
  /**
   * MMM D, YYYY h:mm A
   * @example Aug 16, 2018 8:02 PM
   */
  lll: 'lll',
  /**
   * ddd, MMM D, YYYY h:mm A
   * @example Thu, Aug 16, 2018 8:02 PM
   */
  llll: 'llll',

  // --- 04. Custom ------------------------------------------------------------

  /**
   * @example 12th Jan
   */
  DoMMM: 'Do MMM',
  /**
   * @example Jan 12th
   */
  MMMDo: 'MMM Do',
  /**
   * @example 12th Jan 2022
   */
  DoMMMYYYY: 'Do MMM YYYY',
  /**
   * @example Jan 12th 2022
   */
  MMMDoYYYY: 'MMM Do YYYY',
  /**
   * @example Mon 12/01/2022
   */
  dddL: 'ddd L',
  /**
   * @example Monday, January 12 2022
   */
  ddddLL: 'dddd, LL',
  /**
   * @example January 2022
   */
  MMMMYYYY: 'MMMM YYYY',
  /**
   * @example 2022-01-12
   */
  YYYYMMDD: 'YYYY-MM-DD',
  /**
   * @example 2022-01-12T00:00:00
   */
  YYYYMMDDT0: 'YYYY-MM-DDT00:00:00',
  /**
   * @example 2022-01
   */
  YYYYMM: 'YYYY-MM',
  /**
   * @example Mon, 12th Jan
   */
  dddDoMMM: 'ddd, Do MMM',
  /**
   * @example Mon, Jan 12th
   */
  dddMMMDo: 'ddd, MMM Do',
  /**
   * @example Mon 12th
   */
  dddDo: 'ddd Do',
  /**
   * 24hr time
   * @example 13:00
   */
  HHmm: 'HH:mm',
})
