/* eslint-disable no-unused-vars */
import TentativeFillActionDTO from './tentativeFillActionDTO'

/**
 * @class
 * @public
 */
export default class TentativeFillRequestDTO {
  constructor({ bookingId, payload } = {}) {
    /**
     * @type {Number}
     */
    this.bookingId = bookingId

    /**
     * The value to be emitted once selected by the user
     * @type {TentativeFillActionDTO}
     */
    this.payload = payload
  }
}
