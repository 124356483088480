import httpStatus from 'statuses'
import i18n from '@plugins/vue-i18n'

// Abstract Product
const BaseErrorPage = (errorCode) => {
  let errorStatusText = ''
  try {
    errorStatusText = httpStatus(errorCode)
  } catch (error) {
    errorStatusText = 'Unknown'
  }

  return {
    // Properties
    showErrorCode: true,
    errorCode,
    errorText: `${errorCode} ${errorStatusText}`,
    title: i18n.t('error.errorGenericTitle'),
    desc: i18n.t('error.errorGenericDescription'),
    subDesc: i18n.t('error.errorGenericSubDescription'),
    goBackURL: '/',
    goBackText: i18n.t('error.errorGenericReturnButtonText'),
  }
}

// Factory
export function ErrorPageFactory(errorCode) {
  switch (errorCode?.toString()) {
    case '500':
      return InternalServerError(errorCode)
    case '404':
      return PageNotFoundError(errorCode)
    case '401':
      return UnauthorizedError(errorCode)
    case '400':
      return BadRequestError(errorCode)
    case '117':
      return ActionLockedError(errorCode)
    case 'accountLoadFailure':
      return AccountLoadFailedError(403)
    case 'accountSuspended':
      return AccountSuspenedError(403)
    default:
      return BaseErrorPage('500')
  }
}

// Concrete Products
const InternalServerError = (errorCode) => {
  return BaseErrorPage(errorCode)
}

const BadRequestError = () => {
  return BaseErrorPage('500')
}

const PageNotFoundError = (errorCode) => {
  const base = BaseErrorPage(errorCode)
  base.title = i18n.t('error.errorNotFoundTitle')
  base.desc = i18n.t('error.errorNotFoundDescription')
  base.subDesc = i18n.t('error.errorNotFoundSubDescription')
  base.showErrorCode = true
  return base
}

const UnauthorizedError = (errorCode) => {
  const base = BaseErrorPage(errorCode)
  base.desc = i18n.t('error.errorUnauthorizedDescription')
  base.subDesc = i18n.t('error.errorUnauthorizedSubDescription')
  base.showErrorCode = false
  return base
}

const ActionLockedError = (errorCode) => {
  const base = BaseErrorPage(errorCode)

  base.desc = i18n.t('error.errorActionLockedDescription')
  base.subDesc = i18n.t('error.errorActionLockedSubDescription')
  base.showErrorCode = false
  return base
}

const AccountLoadFailedError = (errorCode) => {
  const base = BaseErrorPage(errorCode)
  base.desc = i18n.t('error.accountLoadFailureDesc')
  base.subDesc = i18n.t('error.accountLoadFailureSubDesc', {
    companyName: i18n.t('app.companyName'),
  })
  base.showErrorCode = false
  base.errorText = i18n.t('error.accountLoadFailureDesc')
  return base
}

const AccountSuspenedError = (errorCode) => {
  const base = BaseErrorPage(errorCode)
  base.desc = i18n.t('error.accountSuspendedFailureDesc')
  base.subDesc = i18n.t('error.accountSuspendedFailureSubDesc', {
    companyName: i18n.t('app.companyName'),
  })
  base.showErrorCode = false
  base.errorText = i18n.t('error.accountSuspendedFailureDesc')
  return base
}
