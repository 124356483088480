/**
 * Describes various types of interactions with UI elements
 */
export default Object.freeze({
  /**
   * Initial state
   */
  none: 'none',
  /**
   * Indicates this interaction will emit an event
   */
  emitEvent: 'emitEvent',
  /**
   * Interaction will utilise the VueRouter to redirect within the app
   */
  routerLink: 'routerLink',
  /**
   * Indicates that clicking on this UI element should open up a new tab
   */
  externalLink: 'externalLink',
  /**
   * Function to be fired on click
   */
  function: 'function',
})
