import ResultDTO from '@/models/app/resultDTO'

/**
 * Successful operation. isSuccess is set to true
 * @param {ResultDTO} payload
 * @returns
 */
export const success = ({
  data = null,
  message = '',
  statusCode,
  headers,
} = {}) =>
  new ResultDTO({ isSuccess: true, data, message, statusCode, headers })

/**
 * Failed operation. isSuccess is set to false
 * @param {ResultDTO} payload
 * @returns
 */
export const fail = ({
  data = null,
  message = '',
  statusCode,
  error = null,
  headers,
} = {}) =>
  new ResultDTO({ isSuccess: false, data, message, statusCode, error, headers })
