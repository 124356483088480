import { ModelApiService } from './BaseApiService'

export default class QualificationsApiService extends ModelApiService {
  constructor() {
    super('qualifications')
  }

  async getQualificationDetails(qualificationId) {
    return this.get(qualificationId)
  }

  async downloadQualificationFile(qualificationId, accessGui) {
    return this.getBlob(`${qualificationId}/file/${accessGui}`)
  }
}
