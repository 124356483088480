import i18n from '@plugins/vue-i18n'
import store from '@/store'
import { ErrorPageCodes } from '@/constants/error/ErrorPageCodes'
import routeDefinitions from '@/constants/routes/routeDefinitions'

export default [
  {
    ...routeDefinitions.home,
    component: () => import('@views/home.vue'),
    meta: {
      title: i18n.t('home.homePageTitle'),
    },
  },
  {
    ...routeDefinitions.myAvailability,
    component: () => import('@views/my-availability.vue'),
    meta: {
      title: i18n.t('availability.pageTitle'),
    },
  },
  {
    ...routeDefinitions.myRegistrations,
    component: () => import('@views/my-registrations.vue'),
    meta: {
      title: i18n.t('registrations.pageTitle'),
    },
  },
  {
    ...routeDefinitions.registrationDetails,
    component: () => import('@/router/views/registration-details.vue'),
    meta: {
      title: i18n.t('registrationDetails.pageTitle'),
    },
  },
  {
    ...routeDefinitions.groupBookingDetailsGuestAccess,
    component: () => import('@/router/views/booking-details.vue'),
    meta: {
      title: i18n.t('bookingDetails.pageTitle'),
      public: true,
    },
  },
  {
    ...routeDefinitions.bookingDetailsGuestAccess,
    component: () => import('@/router/views/booking-details.vue'),
    meta: {
      title: i18n.t('bookingDetails.pageTitle'),
      public: true,
    },
  },
  {
    ...routeDefinitions.bookingDetails,
    component: () => import('@/router/views/booking-details.vue'),
    meta: {
      title: i18n.t('bookingDetails.pageTitle'),
    },
  },
  {
    ...routeDefinitions.myFiles,
    component: () => import('@/router/views/my-files.vue'),
    meta: {
      title: i18n.t('myFiles.pageTitle'),
    },
  },
  {
    ...routeDefinitions.profDevelopment,
    component: () => import('@views/professional-development.vue'),
    meta: {
      title: i18n.t('professionalDevelopment.pageTitle'),
    },
  },
  {
    ...routeDefinitions.downloads,
    component: () => import('@views/downloads.vue'),
    meta: {
      title: i18n.t('downloads.pageTitle'),
    },
  },
  {
    ...routeDefinitions.contact,
    component: () => import('@views/contact.vue'),
    meta: {
      title: i18n.t('contact.pageTitle'),
    },
  },
  {
    ...routeDefinitions.help,
    component: () => import('@views/help.vue'),
    meta: {
      title: i18n.t('help.pageTitle'),
    },
  },
  {
    ...routeDefinitions.settings,
    component: () => import('@/router/views/settings/settings.vue'),
  },
  {
    ...routeDefinitions.changePassword,
    component: () =>
      import('@/router/views/settings/settings-change-password.vue'),
  },
  {
    ...routeDefinitions.login,
    component: () => import('@views/login.vue'),
    meta: {
      public: true,
      title: i18n.t('login.loginPageTitle'),
      async beforeResolve(routeTo, routeFrom, next) {
        // Since login is a public path, we won't get a chance to check if the
        // user is already authenticated via main route guard. So doing it here.
        try {
          await store.dispatch('auth/refreshToken')
        } catch {
          // We expect the above dispatch to throw if the user is unauthenticated.
          // Intentionally leaving this catch block blank.
        } finally {
          // If the user is already logged in
          if (store.getters['auth/isUserLoggedIn']) {
            // Redirect to the home page instead
            next({ name: routeDefinitions.home.name })
          } else {
            // Continue to the login page
            next()
          }
        }
      },
    },
  },
  {
    ...routeDefinitions.accountSetup,
    component: () => lazyLoadView(import('@views/account-setup.vue')),
    meta: {
      public: true,
      title: i18n.t('accountSetup.pageTitle'),
    },
  },
  {
    ...routeDefinitions.impersonateLogout,
    component: () => lazyLoadView(import('@views/impersonate-logout.vue')),
    meta: {
      public: true,
      title: 'Impersonate Logout',
    },
  },
  {
    ...routeDefinitions.impersonateLogin,
    component: () => lazyLoadView(import('@views/login.vue')),
    meta: {
      public: true,
      title: 'Impersonate Login',
      beforeResolve(routeTo, routeFrom, next) {
        // If the user is already logged in
        if (store.getters['auth/isUserLoggedIn']) {
          // Redirect to the home page instead
          next({ name: routeDefinitions.home.name })
        } else {
          // Continue to the login page
          next()
        }
      },
    },
  },
  {
    ...routeDefinitions.logout,
    meta: {
      beforeResolve(routeTo, routeFrom, next) {
        store.dispatch('auth/logOut')
        const authRequiredOnPreviousRoute = routeFrom.matched.some(
          (route) => route.meta.authRequired
        )
        // Navigate back to previous page, or home as a fallback
        next(
          authRequiredOnPreviousRoute
            ? { name: routeDefinitions.home.name }
            : { ...routeFrom }
        )
      },
    },
  },
  {
    ...routeDefinitions.underConstruction,
    component: () => lazyLoadView(import('@views/_under-construction.vue')),
    meta: {
      public: true,
      title: i18n.t('underConstruction.pageTitle'),
    },
  },
  {
    path: '/404',
    name: '404',
    redirect: { name: routeDefinitions.notFound.name },
    meta: {
      public: true,
      label: 'Error',
      type: ErrorPageCodes.PAGE_NOT_FOUND.code,
    },
    // Allows props to be passed to the 404 page through route
    // params, such as `resource` to define what wasn't found.
    props: true,
  },
  // Redirect any unmatched routes to the 404 page. This may
  // require some server configuration to work in production:
  // https://router.vuejs.org/en/essentials/history-mode.html#example-server-configurations
  {
    path: '*',
    component: require('@views/_error.vue').default,
    props: true,
    name: routeDefinitions.notFound.name,
    meta: {
      public: true,
      label: 'NotFound',
      type: ErrorPageCodes.PAGE_NOT_FOUND.code,
    },
  },
  {
    path: '/500',
    redirect: { name: routeDefinitions.error.name },
    meta: {
      public: true,
      label: 'Error',
      type: ErrorPageCodes.INTERNAL_SERVER_ERROR.code,
    },
  },
  {
    path: '/401',
    redirect: { name: routeDefinitions.unauthorized.name },
    meta: {
      public: true,
      label: 'Unauthorized',
      type: ErrorPageCodes.UNAUTHORIZED.code,
      icon: 'mdi-account-cancel',
    },
  },
  {
    path: '/error',
    redirect: { name: routeDefinitions.error.name },
    meta: {
      public: true,
      label: 'Error',
      type: ErrorPageCodes.INTERNAL_SERVER_ERROR.code,
    },
  },
  {
    path: '*',
    component: require('@views/_error.vue').default,
    props: true,
    name: routeDefinitions.error.name,
    meta: {
      public: true,
      label: 'Error',
      type: ErrorPageCodes.INTERNAL_SERVER_ERROR.code,
    },
  },
  {
    path: '*',
    component: require('@views/_error.vue').default,
    props: true,
    name: routeDefinitions.unauthorized.name,
    meta: {
      public: true,
      label: 'Unauthorized',
      type: ErrorPageCodes.UNAUTHORIZED.code,
      icon: 'mdi-account-cancel',
    },
  },
  {
    path: '*',
    component: require('@views/_error.vue').default,
    props: true,
    name: routeDefinitions.accountLoadFailure.name,
    meta: {
      public: true,
      label: 'Failed To Load Account',
      type: ErrorPageCodes.ACCOUNT_LOAD_FAILURE.code,
      icon: 'mdi-account-cancel',
    },
  },
  {
    path: '/account-suspended',
    redirect: { name: routeDefinitions.accountSuspended.name },
    meta: {
      public: true,
      label: 'Account Suspended',
      type: ErrorPageCodes.ACCOUNT_SUSPENDED.code,
      icon: 'mdi-account-cancel',
    },
  },
  {
    path: '*',
    component: require('@views/_error.vue').default,
    props: true,
    name: routeDefinitions.accountSuspended.name,
    meta: {
      public: true,
      label: 'Account Suspended',
      type: ErrorPageCodes.ACCOUNT_SUSPENDED.code,
      icon: 'mdi-account-cancel',
    },
  },
]

/** Lazy-loads view components, but with better UX. A loading view
 * will be used if the component takes a while to load, falling
 * back to a timeout view in case the page fails to load. You can
 * use this component to lazy-load a route with:
 *
 * component: () => lazyLoadView(import('@views/my-view'))
 *
 * NOTE: Components loaded with this strategy DO NOT have access
 * to in-component guards, such as beforeRouteEnter,
 * beforeRouteUpdate, and beforeRouteLeave. You must either use
 * route-level guards instead or lazy-load the component directly:
 *
 * component: () => import('@views/my-view')
 */
function lazyLoadView(AsyncView) {
  const AsyncHandler = () => ({
    component: AsyncView,
    // A component to use while the component is loading.
    loading: require('@views/_loading.vue').default,
    // Delay before showing the loading component.
    // Default: 200 (milliseconds).
    delay: 400,
    // A fallback component in case the timeout is exceeded
    // when loading the component.
    error: require('@views/_timeout.vue').default,
    // Time before giving up trying to load the component.
    // Default: Infinity (milliseconds).
    timeout: 10000,
  })

  return Promise.resolve({
    functional: true,
    render(h, { data, children }) {
      // Transparently pass any props or children
      // to the view component.
      return h(AsyncHandler, data, children)
    },
  })
}
