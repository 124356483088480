import $dayjs from '@/services/date'
import DateStripDisplayRange from '@/constants/date/DateStripDisplayRange'
import DurationUnits from '@/constants/date/DurationUnits.js'
import DateRange from '@/models/general/dateRange'
// eslint-disable-next-line no-unused-vars
import Duration from '@/models/general/duration'
import Direction from '@/constants/layout/Direction'
import MonthYearDifferentDTO from '@/models/date/monthYearDifferentDTO'
import DateFormatToken from '@/constants/date/DateFormatToken'
import DateTense from '@/constants/date/DateTense'
import { DayOfWeek } from '@/constants/date/DayOfWeek'

const FIRST_DAY_OF_WEEK_INDEX = 0
const DAYS_IN_A_FORTNIGHT = 14
const DAYS_IN_A_WEEK = 7
const NUMBER_OF_DAYS_UNTIL_END_OF_WEEK = 6
const NUMBER_OF_DAYS_UNTIL_END_OF_FORTNIGHT = 13

/**
 * Calculates the last date to be displayed in the date strip
 * @param {Date} date
 * @param {DateStripDisplayRange} displayRangeType
 * @returns {Date}
 */
const calculateLastDateToDisplay = function (date, displayRangeType) {
  switch (displayRangeType) {
    case DateStripDisplayRange.WEEK:
      return $dayjs(date).add(
        NUMBER_OF_DAYS_UNTIL_END_OF_WEEK,
        DurationUnits.DAY
      )
    case DateStripDisplayRange.FORTNIGHT:
      return $dayjs(date).add(
        NUMBER_OF_DAYS_UNTIL_END_OF_FORTNIGHT,
        DurationUnits.DAY
      )
  }
}

/**
 * Returns the number of days based on the display range value
 * @param {DateStripDisplayRange} displayRange
 * @returns
 */
const calcDaysToDisplayBasedOnDisplayRange = function (displayRange) {
  switch (displayRange) {
    case DateStripDisplayRange.WEEK:
      return DAYS_IN_A_WEEK
    case DateStripDisplayRange.FORTNIGHT:
      return DAYS_IN_A_FORTNIGHT
    default:
      throw Error('Unsupported display range')
  }
}

/**
 * Calculates the shift to start date when clicking the previous or next arrows
 * on the date strip
 * @param {Direction} direction
 * @param {DateStripDisplayRange} displayRange
 * @param {Date} startDate
 * @returns {Date}
 */
const shiftStartDateBasedOnDisplayRangeType = function (
  direction = Direction.next,
  displayRange,
  startDate
) {
  const daysToDisplay = calcDaysToDisplayBasedOnDisplayRange(displayRange)
  switch (direction) {
    case Direction.next:
      return startDate.add(daysToDisplay, DurationUnits.DAY)
    case Direction.previous:
      return startDate.subtract(daysToDisplay, DurationUnits.DAY)
    default:
      throw Error(`Invalid direction. Received: ${direction}`)
  }
}

/**
 * Decides which date will be the basis for calculating the date range to be called from the overview bookings API.
 *
 * If the direction is previous, we want to use the start date as the basis as it is the earliest date. We'll want to grab bookings from that earlier year
 * `E.g. 2021 <- 2022`
 *
 * If next, we want to use the end date as the direction is moving towards the future
 * `E.g. 2022 -> 2023`
 *
 * If direction is null then the user has selected a date directly, and as such that should form the basis.
 *
 * @param {Direction} direction
 * @param {Date} selectedDate Currently selected date
 * @param {DateRange} dateRange Currently viewed range on the date strip
 * @returns {Date} Dayjs date object to be consumed by `calculateLoadRangeBasedWithBuffer`
 */
const determineDateToBeBasisOfRangeGenerationBasedOnDirection = (
  direction,
  selectedDate,
  dateRange
) => {
  switch (direction) {
    case Direction.next:
      return dateRange.end
    case Direction.previous:
      return dateRange.start
    default:
      return selectedDate
  }
}

/**
 * Creates an object that contains the start and end dates used to generate dates on the date strip
 * @param {DateStripDisplayRange} displayRangeType
 * @param {String} locale e.g. en-AU
 * @param {Date} targetDay
 * @returns {DateRange}
 */
const calculateDateStripDateRangeBasedOnDisplayType = function (
  displayRangeType,
  targetDay = $dayjs(),
  startOfWeek
) {
  const startDate = $dayjs(targetDay).weekday(startOfWeek)
  return new DateRange({
    start: startDate,
    end: calculateLastDateToDisplay(startDate, displayRangeType),
  })
}

/**
 * @param {DateStripDisplayRange} displayRangeType
 * @param {Date} targetDay
 * @param {Duration} buffer Will add specified duration buffer to the start and end of the load range
 */
const calculateLoadRangeBasedWithBuffer = function (
  displayRangeType,
  targetDay = $dayjs(),
  buffer = new Duration()
) {
  switch (displayRangeType) {
    case DateStripDisplayRange.YEAR:
      return new DateRange({
        start: $dayjs(targetDay)
          .startOf(DurationUnits.YEAR)
          .subtract(buffer.value, buffer.unit),
        end: $dayjs(targetDay)
          .endOf(DurationUnits.YEAR)
          .add(buffer.value, buffer.unit)
          .set(DurationUnits.HOUR, 0)
          .set(DurationUnits.MINUTE, 0)
          .set(DurationUnits.SECOND, 0)
          .set(DurationUnits.MILLISECOND, 0),
      })
    default:
      throw new Error(`Unsupported display range: ${displayRangeType}`)
  }
}

/**
 * Calculates the start of the week based on locale
 * @param {String} locale e.g. en-AU
 * @returns {Number} Date index (0 = Sunday, 6 = Saturday)
 */
const getStartOfTheWeekBasedOnLocale = function (locale) {
  return $dayjs()
    .locale(locale)
    .weekday(FIRST_DAY_OF_WEEK_INDEX)
    .get(DurationUnits.DAY)
}

/**
 * Determines if Sunday is the first day of the week
 * @param {String} locale e.g. en-AU
 * @returns {Boolean} true if Sunday is first day of week in specified locale
 */
const isSundayFirstDayOfWeek = function (locale) {
  const firstDayOfWeek = getStartOfTheWeekBasedOnLocale(locale)
  return firstDayOfWeek === DayOfWeek.SUNDAY
}

/**
 * Determines if the between two dates if the month and the year is different
 * @param {DateRange} dateRange
 * @returns {MonthYearDifferentDTO}
 */
const determineIfMonthAndYearIsDifferentInRange = (dateRange) => {
  const startMonth = $dayjs(dateRange.start).format('MMMM')
  const startYear = $dayjs(dateRange.start).format('YYYY')
  const endMonth = $dayjs(dateRange.end).format('MMMM')
  const endYear = $dayjs(dateRange.end).format('YYYY')

  const isMonthDifferent = startMonth !== endMonth
  const isYearDifferent = startYear !== endYear

  return new MonthYearDifferentDTO({
    isMonthDifferent,
    isYearDifferent,
  })
}

/**
 * Determines whether the month component of a date to be presented should
 * preceed the day component for a specified locale.
 *
 * @param locale The locale to check
 * @returns {Boolean}
 */
const isMonthBeforeDay = (locale) => {
  return (
    $dayjs().locale(locale.toLowerCase()).localeData().longDateFormat('L') ===
    'MM/DD/YYYY'
  )
}

/**
 * Determines if the between two dates if the month and the year is different
 * @param {DateRange} dateRange
 * @param {String} locale e.g. en-US
 * @param {String} dayFormatString DateFormatToken
 * @param {String} monthFormatString DateFormatToken
 * @param {String} yearFormatString DateFormatToken
 * @returns
 */
const determineFormatStringsForDateRange = (
  dateRange,
  locale = 'en',
  dayFormatString = DateFormatToken.Do,
  monthFormatString = DateFormatToken.MMM,
  yearFormatString = DateFormatToken.YYYY
) => {
  const monthYearDiff = determineIfMonthAndYearIsDifferentInRange(dateRange)
  const isMonthBeforeDayBool = isMonthBeforeDay(locale)

  // Compute the start and end month and year format string pieces based on
  // if the month/year is different
  const startMonthFormatString =
    isMonthBeforeDayBool ||
    monthYearDiff.isYearDifferent ||
    monthYearDiff.isMonthDifferent
      ? monthFormatString
      : ''
  const startYearFormatString = monthYearDiff.isYearDifferent
    ? yearFormatString
    : ''
  const endMonthFormatString =
    !isMonthBeforeDayBool ||
    monthYearDiff.isYearDifferent ||
    monthYearDiff.isMonthDifferent
      ? monthFormatString
      : ''
  const endYearFormatString = monthYearDiff.isYearDifferent
    ? yearFormatString
    : ''

  const startFormatStringList = []
  const endFormatStringList = []

  // Combine the format pieces into a complete format string with spaces
  // Dending on locale it could be: Do MMM? YYYY? or MMM? Do YYYY?
  if (isMonthBeforeDayBool) {
    startFormatStringList.push(startMonthFormatString)
    startFormatStringList.push(dayFormatString)
    endFormatStringList.push(endMonthFormatString)
    endFormatStringList.push(dayFormatString)
  } else {
    startFormatStringList.push(dayFormatString)
    startFormatStringList.push(startMonthFormatString)
    endFormatStringList.push(dayFormatString)
    endFormatStringList.push(endMonthFormatString)
  }

  startFormatStringList.push(startYearFormatString)
  endFormatStringList.push(endYearFormatString)

  const startFormatString = startFormatStringList.join(' ')
  const endFormatString = endFormatStringList.join(' ')

  return {
    startFormatString: startFormatString.trim(),
    endFormatString: endFormatString.trim(),
  }
}

/**
 * Determines the tense of the event but doesn't consider time.
 * Tense is used as an anchor for other computations.
 * @param {Date} targetDate
 * @returns {DateTense}
 */
const determineDateTense = (targetDate) => {
  const isTodayTheSameAsTargetDate = targetDate.isSame(
    $dayjs(),
    DurationUnits.DAY
  )
  const isTargetDateInThePast = targetDate.isBefore($dayjs())

  if (isTargetDateInThePast && !isTodayTheSameAsTargetDate)
    return DateTense.past
  else if (isTodayTheSameAsTargetDate) return DateTense.present
  else return DateTense.future
}

/**
 *
 * @param {Date} targetDate
 * @param {{ locale: String, t: Function }} i18n class containing current locale and translation function
 * @param {Date} currentDate
 * @returns {String} Formatted date string
 */
const formatDateTruncatedLocaleAware = (
  targetDate,
  $i18n,
  currentDate = null,
  ignoreRelative = false
) => {
  const target = $dayjs(targetDate)

  // Handle relative date
  if (!ignoreRelative) {
    if (target.isYesterday()) return $i18n.t('date.yesterday')
    if (target.isToday()) return $i18n.t('date.today')
    if (target.isTomorrow()) return $i18n.t('date.tomorrow')
  }

  // Use date range format function to generate format string for target date
  const dateRangeFormatString = determineFormatStringsForDateRange(
    new DateRange({
      start: currentDate,
      end: target,
    }),
    $i18n.locale
  )

  // We switch which format string to use based on if month is displayed before date.
  // This is because with locales like the US, date ranges are written like Oct 1st - 23rd
  // whereas in locales like AUS they are written as 1st - 23rd Oct
  return target.format(
    isMonthBeforeDay($i18n.locale)
      ? dateRangeFormatString.startFormatString
      : dateRangeFormatString.endFormatString
  )
}

/**
 * Returns the dates of a specified weekday within a month
 * @param {Number} targetWeekday `DayOfWeek` value
 * @param {Date} month
 * @returns {Date[]}
 */
const getAllWeekdayDatesInMonth = (targetWeekday, currentMonth) => {
  const DAYS_IN_WEEK = 7

  const daysArr = []

  let weekday = $dayjs(currentMonth)
    .startOf(DurationUnits.MONTH)
    .day(targetWeekday)

  if (weekday.date() > DAYS_IN_WEEK)
    weekday = weekday.add(DAYS_IN_WEEK, DurationUnits.DAY)

  const month = weekday.month()

  while (month === weekday.month()) {
    daysArr.push($dayjs(weekday))
    weekday = weekday.add(DAYS_IN_WEEK, DurationUnits.DAY)
  }

  return daysArr
}

const getAllWeekdaysAcrossMultipleMonths = (targetWeekday, monthsList) => {
  let weekDayArray = []

  for (const month of monthsList) {
    weekDayArray = [
      ...weekDayArray,
      ...getAllWeekdayDatesInMonth(targetWeekday, month),
    ]
  }

  return weekDayArray
}

/**
 * Generates a list of months based on the date range provided
 * @param {DateRange} dateRange
 * @returns {String[]}
 */
const getListOfMonthsFromDateRange = (dateRange) => {
  if (!dateRange || !dateRange?.start || !dateRange?.end) return []

  const startDate = $dayjs(dateRange.start)
  const endDate = $dayjs(dateRange.end)
  const monthsDifferenceBetweenRange = endDate.diff(
    startDate,
    DurationUnits.MONTH
  )

  if (monthsDifferenceBetweenRange === 0) {
    return [startDate.format(DateFormatToken.YYYYMM)]
  }

  const monthsList = []

  for (let i = 0; i <= monthsDifferenceBetweenRange; ++i) {
    monthsList.push(
      startDate.add(i, DurationUnits.MONTH).format(DateFormatToken.YYYYMM)
    )
  }

  return monthsList
}

/**
 * Generates a list of dates within a date range
 * @param {DateRange} range
 * @param {Date[]} excludedDates
 */
const listDatesWithinRange = (range, excludedDates = []) => {
  const start = $dayjs(range.start)
  const end = $dayjs(range.end)

  if (!start.isValid()) throw Error('Invalid start date')
  if (!end.isValid()) throw Error('Invalid end date')

  if (start.isSame(end, DurationUnits.DAY)) return [range.start]

  const dates = []

  const diff = end.diff(start, 'days')

  if (diff <= 0) return dates

  for (let i = 0; i <= diff; i++) {
    const date = end.subtract(i, DurationUnits.DAY)

    // Make sure to skip excluded dates
    if (
      excludedDates.length > 0 &&
      excludedDates.some((excludedDate) =>
        excludedDate.isSame(date, DurationUnits.DAY)
      )
    )
      continue

    dates.push(date)
  }

  return dates
}

const getStartOfWeekFromLocale = (locale) => {
  const START_OF_WEEK_INDEX = 0

  const startOfWeek = $dayjs().locale(locale).weekday(START_OF_WEEK_INDEX)
  return startOfWeek
}

/**
 * Accepts a list of weekday indexes and will return every date within
 * a specified month for the supplied indexes.
 *
 * e.g. [DayOfWeek.MONDAY] => Returns every monday for that month
 *
 * @param {Number[]} dayNumberList List of `DayOfWeek` values
 * @param {Date} targetMonth
 * @returns {Date[]}
 */
const listAllDatesWithinAMonthByDayNumbers = (dayNumberList, targetMonth) => {
  if (!dayNumberList || dayNumberList.length === 0) return []
  const month = $dayjs(targetMonth)
  if (!month.isValid()) throw Error(`Invalid month supplied: ${targetMonth}`)

  let dates = []

  for (const dayNumber of dayNumberList) {
    dates = [...getAllWeekdayDatesInMonth(dayNumber, targetMonth), ...dates]
  }

  return dates
}

/**
 * Similar to `listAllDatesWithinAMonthByDayNumbers` except it works off of a
 * list of months and will grab dates from all the months
 * @param {Number[]} dayNumberList
 * @param {String[]} monthList
 * @returns
 */
const listAllDatesAcrossMultipleMonthsByDayNumbers = (
  dayNumberList,
  monthList
) => {
  if (!dayNumberList || dayNumberList.length === 0) return []
  if (!monthList || monthList.length === 0) return []

  let dates = []

  for (const dayNumber of dayNumberList) {
    dates = [
      ...dates,
      ...getAllWeekdaysAcrossMultipleMonths(dayNumber, monthList),
    ]
  }

  return dates
}

/**
 * Constructs a date range from a list of dates
 * @param {Date[]} dates
 * @returns {DateRange}
 */
const getRangeFromListOfDates = (dates) => {
  if (!dates || dates.length === 0)
    throw new Error('A list of dates is required')

  const listOfTimestamps = dates
    .map((date) => $dayjs(date).format(DateFormatToken.YYYYMMDD))
    .sort()

  return new DateRange({
    start: listOfTimestamps[0],
    end: listOfTimestamps[listOfTimestamps.length - 1],
  })
}

/**
 * Generates a relative date string with the smallest unit it shows being
 * date
 * @param {Date} date
 * @param {*} $i18n
 * @returns {String}
 */
const generateRelativeTimeWithDayMinimumValue = (date, $i18n) => {
  const targetDate = $dayjs(date)

  if (targetDate.isYesterday()) return $i18n.t('date.yesterday')
  if (targetDate.isToday()) return $i18n.t('date.today')
  if (targetDate.isTomorrow()) return $i18n.t('date.tomorrow')

  return targetDate.locale($i18n.locale).fromNow()
}

export {
  calculateDateStripDateRangeBasedOnDisplayType,
  calculateLastDateToDisplay,
  calcDaysToDisplayBasedOnDisplayRange,
  shiftStartDateBasedOnDisplayRangeType,
  getStartOfTheWeekBasedOnLocale,
  calculateLoadRangeBasedWithBuffer,
  determineDateToBeBasisOfRangeGenerationBasedOnDirection,
  determineIfMonthAndYearIsDifferentInRange,
  determineFormatStringsForDateRange,
  isMonthBeforeDay,
  determineDateTense,
  formatDateTruncatedLocaleAware,
  isSundayFirstDayOfWeek,
  getAllWeekdayDatesInMonth,
  getAllWeekdaysAcrossMultipleMonths,
  listDatesWithinRange,
  getStartOfWeekFromLocale,
  listAllDatesWithinAMonthByDayNumbers,
  listAllDatesAcrossMultipleMonthsByDayNumbers,
  getListOfMonthsFromDateRange,
  getRangeFromListOfDates,
  generateRelativeTimeWithDayMinimumValue,
}
