/**
 * Validates that an object has every key provided in the expected array
 * @param {Array} expected
 * @param {Object} obj
 * @returns {Boolean}
 */
export default function objectHasKeys(expected = [], obj = {}) {
  if (!expected || !Array.isArray(expected) || expected.length === 0)
    throw Error('Expected array needs to be a valid, non-empty array')

  if (!obj) throw Error('Object needs to be non-empty')

  return expected.every((key) => Object.prototype.hasOwnProperty.call(obj, key))
}
