import { getType } from 'mime'
import { extractFileNameFromContentDisposition } from '@helpers/file-helpers'
import { fail, success } from '@/helpers/result-helper.js'
import toast from '@/services/toasts/index.js'
import dayjs from '@/services/date'
import CandidateDocumentAgreementUpdateViewModel from '@/models/candidate/candidateDocumentAgreementUpdateViewModel'

const getDefaultState = () => {
  return {
    loadingCount: 0,
    loadingAgreementsCount: 0,
  }
}

const state = getDefaultState()

export default {
  namespaced: true,
  state,
  getters: {
    isLoading: (state) => state.loadingCount > 0,
    isLoadingAgreement: (state) => state.loadingAgreementsCount > 0,
  },
  mutations: {
    START_LOADING(state) {
      state.loadingCount++
    },
    FINISH_LOADING(state) {
      state.loadingCount--
    },
    START_LOADING_AGREEMENTS(state) {
      state.loadingAgreementsCount++
    },
    FINISH_LOADING_AGREEMENTS(state) {
      state.loadingAgreementsCount--
    },
    CLEAR_STORE(state) {
      // Resets store to default state
      Object.assign(state, getDefaultState())
    },
  },
  actions: {
    async downloadAgreement({ commit, dispatch }, fileId) {
      commit('START_LOADING_AGREEMENTS')
      try {
        const response = await this.$api.agreements.downloadAgreementsFile(
          fileId
        )
        const fileName = extractFileNameFromContentDisposition(
          response.headers['content-disposition']
        )
        const blob = new Blob([response.data], {
          type: getType(fileName),
        })
        return success({ data: blob })
      } catch (ex) {
        toast.error(this.$i18n.t('error.unableToLoadFile'))
        return fail({
          error: await dispatch('logException', ex, { root: true }),
        })
      } finally {
        commit('FINISH_LOADING_AGREEMENTS')
      }
    },
    async signAgreement({ commit, dispatch }, { fileId, clientId }) {
      commit('START_LOADING')
      try {
        const response = await this.$api.agreements.putAgreements(fileId)
        const signedDate = dayjs.utc()
        await dispatch(
          'updateActionableItemAfterPendingAgreementAction',
          new CandidateDocumentAgreementUpdateViewModel({
            fileId,
            clientId,
            dateAgreedUTC: signedDate,
          })
        )

        toast.success(this.$i18n.t('agreements.acceptedAgreementText'))
        return response
      } catch (ex) {
        toast.error(this.$i18n.t('error.errorGenericApiErrorText'))
        return fail({
          error: await dispatch('logException', ex, { root: true }),
        })
      } finally {
        commit('FINISH_LOADING')
      }
    },
    /**
     * Resets store to default state.
     */
    clear({ commit }) {
      commit('CLEAR_STORE')
    },
    async updateActionableItemAfterPendingAgreementAction(
      { dispatch },
      agreementUpdateData
    ) {
      await dispatch(
        'clients/updateActionableItemForAgreement',
        agreementUpdateData,
        {
          root: true,
        }
      )
    },
  },
}
