export default class DownloadedFile {
  constructor({
    id = null,
    accessGuid = null,
    type = null,
    name = null,
    size = null,
    file = null,
  } = {}) {
    /**
     * Unique Identifier used in the array to find this item for viewing/deletion
     * @type {Number|String}
     */
    this.id = id
    /**
     * accessGuid of the downloaded file. Uploaded file will have this as null
     * @type {Guid}
     */
    this.accessGuid = accessGuid
    /**
     * Content Type of the file
     * @type {String}
     */
    this.type = type
    /**
     * Name of the file
     * @type {String}
     */
    this.name = name
    /**
     * Size of the file
     * @type {Number}
     */
    this.size = size
    /**
     * Downloaded file data.
     * @type {Object}
     */
    this.file = file
  }
}
