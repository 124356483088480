/**
 * Truncates any string provided
 * @param {String} text String to be truncated
 * @param {Number} limit String length before truncating. Default: 0
 * @param {String} delimiter Defaults to '...'
 * @returns Truncated string
 */
export default (text, limit = 0, delimiter = '...') => {
  if (typeof text !== 'string')
    throw Error('Invalid data type for text (Expected String)')

  if (typeof limit !== 'number')
    throw Error('Invalid data type for limit (Expected Number)')

  if (typeof delimiter !== 'string')
    throw Error('Invalid data type for delimiter (Expected String)')

  if (limit === 0) return text

  if (text.length > limit) text = text.substring(0, limit) + delimiter

  return text
}
