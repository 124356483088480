/* eslint-disable no-unused-vars */
import AccessGuiFileViewModel from '../file/accessGuiFileViewModel'
import dayjs from '@/services/date/index'

/**
 * @class
 * @public
 */
export default class CandidateDocumentViewModel {
  /**
   * @constructor
   */
  constructor({
    id,
    type = '',
    name = '',
    file = null,
    isVerified = false,
    createdDateLocal = null,
  } = {}) {
    /**
     * @type {Number}
     */
    this.id = id

    /**
     * @type {String}
     */
    this.type = type ?? ''

    /**
     * @type {String}
     */
    this.name = name ?? ''

    /**
     * @type {AccessGuiFileViewModel}
     */
    this.file = file ? new AccessGuiFileViewModel(file) : null

    /**
     * Indicates if this record has been verified by a consultant or by the system.
     * @type {Boolean}
     */
    this.isVerified = isVerified
    /**
     * @type {Date}
     */
    this.createdDateLocal = createdDateLocal ? dayjs(createdDateLocal) : null
  }
}
