/**
 * Zero indexed week day mapping https://day.js.org/docs/en/get-set/day
 */
export const DayOfWeek = Object.freeze({
  SUNDAY: 0,
  MONDAY: 1,
  TUESDAY: 2,
  WEDNESDAY: 3,
  THURSDAY: 4,
  FRIDAY: 5,
  SATURDAY: 6,
})
