<script>
import baseMixin from '@mixins/baseMixin'
import publicViewMixin from '@mixins/publicViewMixin'
import TheDebugBar from '@components/dev/the-debug-bar.vue'

export default {
  name: 'PublicLayout',
  components: {
    TheDebugBar,
  },
  mixins: [baseMixin, publicViewMixin],
}
</script>

<template>
  <v-app id="ready-2-work" :dark="setTheme()">
    <TheDebugBar />
    <v-main class="r2w_bg">
      <v-container class="fill-height" fluid>
        <v-layout align-center justify-center>
          <v-flex xs12 sm8 md6>
            <slot />
          </v-flex>
        </v-layout>
      </v-container>
    </v-main>
  </v-app>
</template>

<style lang="scss" scoped>
.r2w_bg {
  position: relative;
  background-image: url('https://anzukmedia.blob.core.windows.net/assets/r2w/img/bg/bg_min.jpg');
  background-image: linear-gradient(
      172deg,
      rgb(61 48 136 / 83%) 37%,
      rgb(61 48 136 / 83%) 0,
      #3d3088 100%,
      #3d3088
    ),
    url('https://anzukmedia.blob.core.windows.net/assets/r2w/img/bg/bg_min.jpg');
  background-position: center;
  background-size: cover;
  opacity: 1;
}
</style>
