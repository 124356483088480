import { fail, success } from '@helpers/result-helper'
import toast from '@/services/toasts/index.js'
// eslint-disable-next-line no-unused-vars
import EventSummaryViewModel from '@/models/event/eventSummaryViewModel'
import BlobCacheDTO from '@/models/file/blobCacheDTO'
import { checkCacheFreshness } from '@/helpers/cache-helpers'
import Duration from '@/models/general/duration'
import DurationUnits from '@/constants/date/DurationUnits'
import { isHttpStatus } from '@/helpers/http-status-helpers'

const getDefaultState = () => {
  return {
    events: [],
    eventImages: [],
    // Place any new state properties here
    loadingCount: 0,
    loadingEventSummaryCount: 0,
    loadingEventImage: [],
    crudLoadingCount: 0,
  }
}

const state = getDefaultState()

export default {
  namespaced: true,
  state,
  getters: {
    moduleName: () => 'events',
    isLoading: (state) => state.loadingCount > 0,
    isLoadingEventSummary: (state) => state.loadingEventSummaryCount > 0,
    isLoadingCRUD: (state) => state.crudLoadingCount > 0,
    isLoadingEventImage: (state) => (eventId) =>
      state.loadingEventImage.some((id) => id === eventId),
    events: (state) => state.events,
    eventImage: (state) => (eventId) =>
      state.eventImages.find((image) => image.id === eventId.toString()),
  },
  mutations: {
    START_LOADING(state) {
      state.loadingCount++
    },
    FINISH_LOADING(state) {
      state.loadingCount--
    },
    START_LOADING_EVENT_SUMMARY(state) {
      state.loadingEventSummaryCount++
    },
    FINISH_LOADING_EVENT_SUMMARY(state) {
      state.loadingEventSummaryCount--
    },
    START_LOADING_EVENT_IMAGE(state, eventId) {
      state.loadingEventImage.push(eventId)
    },
    FINISH_LOADING_EVENT_IMAGE(state, eventId) {
      const index = state.loadingEventImage.findIndex((id) => id === eventId)
      if (index < 0) return
      state.loadingEventImage.splice(index, 1)
    },
    START_LOADING_CRUD(state) {
      state.crudLoadingCount++
    },
    FINISH_LOADING_CRUD(state) {
      state.crudLoadingCount--
    },
    SET_EVENTS(state, events) {
      if (!events || events.length <= 0) state.events = []
      state.events = events.map((event) => new EventSummaryViewModel(event))
    },
    /**
     *
     * @param {*} state
     * @param {BlobCacheDTO} eventImageBlob
     */
    CACHE_EVENT_IMAGE(state, eventImageBlob) {
      const eventImageFound = state.eventImages.find(
        (cachedImage) => cachedImage.id === eventImageBlob.id
      )

      if (!eventImageFound) {
        state.eventImages.push(eventImageBlob)
      } else {
        Object.assign(eventImageFound, eventImageBlob)
      }
    },
    CLEAR_STORE(state) {
      // Resets store to default state
      Object.assign(state, getDefaultState())
    },
  },
  actions: {
    /**
     * @param {*} param0
     * @param {Number} distanceFromInKM
     * @returns {Promise<ResultDTO>}
     */
    async getEvents({ commit, dispatch }, distanceFromInKM = 0) {
      commit('START_LOADING_EVENT_SUMMARY')
      try {
        /**
         * @type {{ status: Number, data: EventSummaryViewModel[] }}
         */
        const response = await this.$api.professionalDevelopment.getEvents({
          distanceFromKM: distanceFromInKM,
        })
        commit('SET_EVENTS', response.data)
        return response
      } catch (ex) {
        toast.error(this.$i18n.t('error.eventSummaryLoadFailure'))

        return fail({
          error: await dispatch('logException', ex, { root: true }),
        })
      } finally {
        commit('FINISH_LOADING_EVENT_SUMMARY')
      }
    },
    /**
     * @param {*} param0
     * @param {Number} eventId
     * @returns {Promise<ResultDTO>}
     */
    async getEventImage({ getters, commit, dispatch }, eventId) {
      /**
       * @type {BlobCacheDTO}
       */
      const isCached = getters.eventImage(eventId)

      if (
        isCached &&
        checkCacheFreshness(
          new Duration({ value: 1, unit: DurationUnits.HOUR }),
          isCached?.timestamp
        )
      ) {
        return success({ data: isCached.blob })
      }

      commit('START_LOADING_EVENT_IMAGE', eventId)
      try {
        /**
         * @type {{ status: Number, data: File }}
         */
        const response = await this.$api.events.getEventImage(eventId)
        const blob = new Blob([response.data])

        commit(
          'CACHE_EVENT_IMAGE',
          new BlobCacheDTO({
            blob,
            id: eventId.toString(),
          })
        )
        return success({ data: blob })
      } catch (ex) {
        // If the event image is not found, set blob to null and cache.
        // This will prevent repeated calls to API and use default event image.
        if (isHttpStatus(ex.response.status, 'Not Found')) {
          commit(
            'CACHE_EVENT_IMAGE',
            new BlobCacheDTO({
              blob: null,
              id: eventId.toString(),
            })
          )
          return success({ data: null })
        }

        return fail({
          error: await dispatch('logException', ex, { root: true }),
        })
      } finally {
        commit('FINISH_LOADING_EVENT_IMAGE', eventId)
      }
    },
    /**
     * Resets store to default state.
     */
    clear({ commit }) {
      commit('CLEAR_STORE')
    },
  },
}
