/* eslint-disable no-unused-vars */
import GenericFileModel from '../file/genericFileModel'

/**
 * @class
 * @public
 */
export default class QualificationUploadFormDTO {
  /**
   * @constructor
   * @param {Object<institute: String, degree: String, dateComplete: Date, teachingMethods: Array<Number>, files: Array<GenericFileModel>>} param0
   */
  constructor({
    institute = null,
    degree = null,
    dateComplete = null,
    teachingMethods = [],
    files = [],
  } = {}) {
    /**
     * @type {String}
     */
    this.institute = institute
    /**
     * @type {String}
     */
    this.degree = degree
    /**
     * @type {Date}
     */
    this.dateComplete = dateComplete
    /**
     * @type {Array<Number>}
     */
    this.teachingMethods = teachingMethods
    /**
     * @type {Array<GenericFileModel>}
     */
    this.files = files
  }
}
