<script>
import Loading from '@views/_loading.vue'
import { mapGetters } from 'vuex'

export default {
  name: 'App',
  components: {
    Loading,
  },
  computed: {
    ...mapGetters({
      serverError: 'isError',
      isAppLoading: 'isLoadingApp',
    }),
  },
  metaInfo: {
    // All subcomponent titles will be injected into this template.
    titleTemplate(title) {
      title = typeof title === 'function' ? title(this.$store) : title
      return title
        ? `${title} | ${this.$t('app.appName')}`
        : this.$t('app.appName')
    },
  },
}
</script>

<template>
  <div id="app">
    <Loading v-if="isAppLoading" />
    <!--
    Even when routes use the same component, treat them
    as distinct and create the component again.

    Using $route.path instead of $route.fullPath so that query changes
    don't trigger page reloads.
    -->
    <RouterView v-else :key="$route.path" />
  </div>
</template>

<!-- This should generally be the only global CSS in the app. -->
<style lang="scss">
// Allow element/type selectors, because this is global CSS.
// stylelint-disable selector-max-type, selector-class-pattern

// Normalize default styles across browsers,
// https://necolas.github.io/normalize.css/
@import '~normalize.css/normalize.css';

// Style loading bar between pages.
// https://github.com/rstacruz/nprogress
@import '~nprogress/nprogress.css';

// Design variables and utilities from src/styles.
@import '@styles';

// Global styles
@import '@/styles/global';

// ===
// Vendor
// ===
.toasted {
  size: 0.9rem;
  font-family: $content-font-family;
}

#nprogress .bar {
  background: $color-link-text;
}
</style>
