<script>
import TheDebugBarActions from './the-debug-bar-actions.vue'
import baseMixin from '@/mixins/baseMixin'
import config from '@/common/config'

export default {
  name: 'TheDebugBar',
  components: { TheDebugBarActions },
  mixins: [baseMixin],
  props: {
    setAsApp: {
      type: Boolean,
    },
  },
  data() {
    return {
      appVersion: config.get('appVersion'),
    }
  },
}
</script>

<template>
  <section>
    <v-system-bar
      v-if="isDev"
      :app="setAsApp"
      dark
      color="purple"
      :class="[
        'debug-bar__mono',
        isMobileViewPort ? 'debug-bar__mono--mobile' : '',
      ]"
    >
      <v-icon x-small left>mdi-wrench</v-icon>
      <span> Dev Mode </span>
      <v-spacer></v-spacer>
      <TheDebugBarActions />

      <section v-if="!isMobileViewPort" class="ml-4">
        <v-icon x-small left>mdi-code-tags</v-icon>
        <span>{{ `v${appVersion}` }}</span>
      </section>
    </v-system-bar>
  </section>
</template>
