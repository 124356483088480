import { isEmpty } from 'lodash'
import { AxiosError } from '@root/node_modules/axios/index'
import objectHasKeys from '@/utils/object-has-keys'
import ErrorResponseType from '@/constants/error/ErrorResponseType'
import RequestErrorSource from '@/constants/error/RequestErrorSource'
import ErrorResponseDTO from '@/models/error/errorResponseDTO'

// Utils

const determineRequestErrorType = (error) => {
  const COULD_NOT_CONTACT_SERVER_STATUSCODE = 0 // Axios sets the error code to 0 if it can't contact the server

  if (!(error instanceof AxiosError)) return RequestErrorSource.clientSide

  if (error?.response?.status !== COULD_NOT_CONTACT_SERVER_STATUSCODE)
    return RequestErrorSource.server

  return RequestErrorSource.request
}

/**
 * Default API request validation response
 *
 * Object Structure:
 * ```json
 * {
    errors: Object,
    status: Number,
    title: String?,
    traceId: String?,
    type: String?,
  }
 * ```
 *
 * @param {Object} responseData
 * @returns
 */
const mapApiValidationErrorResponseToError = (error, errorSource, $i18n) => {
  const base = baseErrorResponse(error, errorSource, $i18n)
  base.data = error.response?.data?.errors
  base.type = error.response?.data?.type || ErrorResponseType.api
  base.message = error.response?.data?.title || base.message

  return base
}

/**
 * Ready2WorkAPI.DTOs.V1.Core.ErrorResponse
 *
 * Object Structure:
 * ```json
 * {
    type: String?,
    code: String?,
    message: String?,
    param: String?,
  }
 * ```
 *
 * @returns
 */
const mapR2WErrorResponseToError = (error, errorSource, $i18n) => {
  const base = baseErrorResponse(error, errorSource, $i18n)
  base.type = error.response?.data?.type || ErrorResponseType.api
  base.code = error.response?.data?.code || base.code
  base.message = error.response?.data?.message || error?.message || base.message
  base.param = error.response?.data?.param
  return base
}

// Constants
const BASE_ERROR_RESPONSE_OBJ_KEYS = ['type', 'code', 'param']
const API_VALIDATION_ERROR_OBJ_KEYS = [
  'errors',
  'status',
  'title',
  'traceId',
  'type',
]

// Abstract Product
const baseErrorResponse = (error, errorSource, $i18n) => {
  const responseData = error?.response?.data

  return new ErrorResponseDTO({
    error,
    source: errorSource,
    code: responseData?.code || error?.code || 'error.genericApiError',
    type: responseData?.type || ErrorResponseType.API,
    message: responseData?.code
      ? $i18n.t(`error.${responseData.code}`)
      : error?.message || $i18n.t('error.genericApiError'),
    param: responseData?.param || null,
  })
}

// Factory
export default function (error, $i18n) {
  const errorSource = determineRequestErrorType(error)

  switch (errorSource) {
    case RequestErrorSource.server:
      return serverErrorResponse(error, errorSource, $i18n)
    case RequestErrorSource.request:
      return requestErrorResponse(error, errorSource, $i18n)
    case RequestErrorSource.clientSide:
      return clientSideErrorResponse(error, errorSource, $i18n)
    default:
      return baseErrorResponse(error, errorSource, $i18n)
  }
}

// Concrete Products

const serverErrorResponse = (error, errorSource, $i18n) => {
  if (!error?.response || isEmpty(error?.response) || !error?.response?.data)
    return baseErrorResponse(error, errorSource, $i18n)

  // Case: Default API request validation response
  if (objectHasKeys(API_VALIDATION_ERROR_OBJ_KEYS, error.response?.data)) {
    return mapApiValidationErrorResponseToError(error, errorSource, $i18n)
  }

  // Case: Ready2WorkAPI.DTOs.V1.Core.ErrorResponse
  if (objectHasKeys(BASE_ERROR_RESPONSE_OBJ_KEYS, error.response?.data))
    return mapR2WErrorResponseToError(error, errorSource, $i18n)

  // Default Case
  return baseErrorResponse(error, errorSource, $i18n)
}

const requestErrorResponse = (error, errorSource, $i18n) => {
  return baseErrorResponse(error, errorSource, $i18n)
}

const clientSideErrorResponse = (error, errorSource, $i18n) => {
  return baseErrorResponse(error, errorSource, $i18n)
}
