<script>
import { ValidationProvider } from 'vee-validate'

export default {
  components: {
    ValidationProvider,
  },
  inheritAttrs: false,
  props: {
    rules: {
      type: [Object, String],
      default: '',
    },
    value: {
      type: null,
      default: '',
    },
  },
  data: () => ({
    innerValue: '',
  }),
  computed: {
    hasSelectedAllItems() {
      return this.innerValue.length === this.$attrs.items.length
    },
    hasSelectedSomeItems() {
      return this.innerValue.length > 0
    },
    compSelectAllItemsIcon() {
      if (this.hasSelectedAllItems) return 'mdi-checkbox-marked'
      if (this.hasSelectedSomeItems) return 'mdi-minus-box'
      return 'mdi-checkbox-blank-outline'
    },
  },
  watch: {
    // Handles internal model changes.
    innerValue(newVal) {
      this.$emit('input', newVal)
    },
    // Handles external model changes.
    value(newVal) {
      this.innerValue = newVal
    },
  },
  created() {
    if (this.value) {
      this.innerValue = this.value
    }
  },
  methods: {
    toggleSelectAll() {
      this.$nextTick(() => {
        if (this.hasSelectedAllItems) {
          this.innerValue = []
        } else {
          this.innerValue = this.$attrs['return-object']
            ? this.$attrs.items.slice()
            : this.$attrs.items
                .slice()
                ?.map((x) => x[this.$attrs['item-value']])
        }
      })
    },
  },
}
</script>

<template>
  <ValidationProvider v-slot="{ errors }" :name="$attrs.label" :rules="rules">
    <v-select
      v-model="innerValue"
      :error-messages="errors"
      v-bind="$attrs"
      v-on="$listeners"
    >
      <template v-if="$attrs.multiple" v-slot:prepend-item>
        <v-list-item ripple @click="toggleSelectAll">
          <v-list-item-action>
            <v-icon :color="innerValue.length > 0 ? 'blue' : ''">
              {{ compSelectAllItemsIcon }}
            </v-icon>
          </v-list-item-action>
          <v-list-item-content>
            <v-list-item-title>
              Select All
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-divider class="mt-2"></v-divider>
      </template>
    </v-select>
  </ValidationProvider>
</template>
