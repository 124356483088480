<script>
import { debounce } from 'lodash'
import fuzzysort from 'fuzzysort'
import SkeletonBaseListSelect from './skeleton/skeleton-base-list-select.vue'
import baseMixin from '@/mixins/baseMixin'

export default {
  name: 'BaseListSelect',
  components: { SkeletonBaseListSelect },
  mixins: [baseMixin],
  props: {
    items: {
      type: Array,
      default: () => [],
    },
    searchable: {
      type: Boolean,
    },
    loading: {
      type: Boolean,
    },
  },
  data: () => {
    return {
      benched: 0,
      searchTerm: '',
      pendingSearch: '',
    }
  },
  computed: {
    hasListItems() {
      return this.list && this.list?.length > 0
    },
    list() {
      return this.filteredItems()
    },
  },
  watch: {
    pendingSearch(val) {
      this.debounceSearch()
    },
  },
  created() {
    this.debounceSearch = debounce(this.setSearchTerm, 200)
  },
  methods: {
    setSearchTerm() {
      this.searchTerm = this.pendingSearch
    },
    renderResult(result) {
      if (!Object.hasOwn(result, '_resultObj')) return result.title
      return fuzzysort.highlight(
        result._resultObj,
        '<mark class="search-results__mark font-weight-bold">',
        '</mark>'
      )
    },
    filteredItems() {
      if (!this.searchTerm) return this.items

      const results = fuzzysort.go(this.searchTerm, this.items, {
        threshold: -10000, // Don't return matches worse than this (higher is faster)
        limit: 100, // Don't return more results than this (lower is faster)
        all: false, // If true, returns all results for an empty search

        key: 'title', // For when targets are objects (see its example usage)
      })

      if (results.length === 0) return []

      return results
        .sort((a, b) => {
          if (a.obj.score > b.obj.score) return 1
          if (a.obj.score < b.obj.score) return -1
          return 0
        })
        .map((searchResult) => {
          return { ...searchResult.obj, ...{ _resultObj: searchResult } }
        })
    },
  },
}
</script>

<template>
  <v-card class="transparent" elevation="0">
    <div v-if="searchable">
      <v-text-field
        id="base-list-select__search-input"
        ref="baseListSelectSearchInput"
        v-model="pendingSearch"
        :label="$t('input.searchLabel')"
        prepend-icon="mdi-magnify"
        class="mb-4"
        clearable
        autofocus
      />
    </div>

    <SkeletonBaseListSelect v-if="loading" />
    <v-virtual-scroll
      v-else-if="hasListItems"
      :bench="benched"
      :items="list"
      :item-height="50"
    >
      <template v-slot="{ item }">
        <v-list-item
          :key="`base-list-select__list-item-${item.id}-${item.title}`"
          @click="$emit('selected', item.id)"
        >
          <v-list-item-content>
            <v-list-item-title
              :class="isMobileViewPort ? 'caption ws-normal' : ''"
            >
              <!-- eslint-disable-next-line vue/no-v-html -->
              <span v-html="sanitize(renderResult(item))"></span>
            </v-list-item-title>
          </v-list-item-content>

          <v-list-item-action>
            <v-icon>mdi-chevron-right</v-icon>
          </v-list-item-action>
        </v-list-item>

        <v-divider></v-divider>
      </template>
    </v-virtual-scroll>
  </v-card>
</template>
