export default Object.freeze({
  /**
   * Client Concern – Agency Took too long
   */
  AGENCY_TOOK_TOO_LONG: -11,
  /**
   * Unable to Fill
   */
  UNABLE_TO_FILL: -12,
  /**
   * anzuk Error
   */
  ANZUK_ERROR: -13,
  /**
   * Candidate – Illness
   */
  CANDIDATE_ILLNESS: -14,
  /**
   * Lapsed
   */
  LAPSED: -100,
  /**
   * Deleted from Group Booking
   */
  DELETED_FROM_GROUP_BOOKING: -50,
  /**
   * Cancelled
   */
  CANCELLED: -1,
  /**
   * Finance Cancelled
   */
  FINANCE_CANCELLED: -101,
  /**
   * Refunded
   */
  REFUNDED: -1000,
  /**
   * Friday Invoice (?)
   */
  FRIDAY_INVOICE: 99,
  /**
   * ECH Invoicing Ready
   */
  ECH_INVOICING_READY: 95,
  /**
   * ECH Booking – Not Approved
   */
  ECH_NOT_APPROVED: 80,
  /**
   * School Booking – Not Approved
   */
  SCHOOL_NOT_APPROVED: 70,
  /**
   * The client has submitted their timesheet and made no changes to the times
   */
  CLIENT_SUBMITTED_TIMESHEET_NO_CHANGES: 111,
  /**
   * The client has submitted their timesheet and made changes
   */
  CLIENT_SUBMITTED_TIMESHEET_MODIFIED: 112,
  /**
   * The client has submitted their timesheet and stated the booking didn't happen
   */
  CLIENT_SUBMITTED_TIMESHEET_DID_NOT_HAPPEN: 113,
  /**
   * The candidate has submitted their timesheet and made no changes to the times
   */
  CANDIDATE_SUBMITTED_TIMESHEET_NO_CHANGES: 114,
  /**
   * The candidate has submitted their timesheet and made changes
   */
  CANDIDATE_SUBMITTED_TIMESHEET_MODIFIED: 115,
  /**
   * The candidate has submitted their timesheet and stated the booking didn't happen
   */
  CANDIDATE_SUBMITTED_TIMESHEET_DID_NOT_HAPPEN: 116,
  /**
   * Both the candidate and client have stated conflicting times for their timesheets.
   * This raises a dispute that is to be resolved by the consultant.
   */
  TIMESHEET_DISPUTE_OR_MISMATCH: 117,
  /**
   * Active, Withhold Pay
   */
  ACTIVE_WITHOLD_PAY: 14,
  /**
   * Active, Lapsed
   */
  ACTIVE_LAPSED: 13,
  /**
   * Tentative Filled
   */
  TENTATIVE_FILLED: 105,
  /**
   * Agency - Moved Client
   */
  AGENCY_MOVED_CLIENT: -15,
  /**
   * Exported to BullHorn NZ
   */
  EXPORTED_TO_BULLHORN_NZ: 175,
  /**
   * Partial Export - Review in BullHorn
   */
  PARTIAL_BULLHORN_EXPORT: -41,
  /**
   * Training
   */
  TRAINING: -16,
  /**
   * Other
   */
  OTHER: -17,
  /**
   * Cancelled by Client
   */
  CANCELLED_BY_CLIENT: -18,
})
