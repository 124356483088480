export const TimesheetOperationType = Object.freeze({
  /**
   * User is confirming that the booking happened
   */
  ACCEPT: 'ACCEPT',
  /**
   * The booking times have been modified and the timesheet will be sent for review
   */
  MODIFY: 'MODIFY',
  /**
   * User indicated that the booking didn't happen and the timesheet will be sent for review
   */
  CANCEL: 'CANCEL',
})
