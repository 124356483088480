/* eslint-disable no-unused-vars */
import dayjs from '@/services/date/index'

/**
 * @class
 * @public
 */
export default class CertificationListItemVM {
  /**
   * @constructor
   */
  constructor({
    id,
    expireStatus = '',
    ignoreExpiry = false,
    dateExpiry = null,
    dateObtained = null,
    isVerified = false,
    certRefNo = '',
    candidateTimeZone = '',
    firstParentId = 0,
    reminderDays = 0,
    title = '',
    typeId = 0,
  } = {}) {
    /**
     * @type {Number}
     */
    this.id = id

    /**
     * @type {String}
     * @see {ExpireStatus} for valid value range
     */
    this.expireStatus = expireStatus

    /**
     * @type {Boolean}
     */
    this.ignoreExpiry = ignoreExpiry

    /**
     * @type {Date}
     */
    this.dateExpiry = dateExpiry ? dayjs(dateExpiry) : null

    /**
     * @type {Date}
     */
    this.dateObtained = dateObtained ? dayjs(dateObtained) : null

    /**
     * @type {Boolean}
     */
    this.isVerified = isVerified

    /**
     * @type {String}
     */
    this.certRefNo = certRefNo

    /**
     * @type {String}
     */
    this.title = title

    /**
     * @type {String}
     */
    this.candidateTimeZone = candidateTimeZone

    /**
     * @type {Number}
     */
    this.firstParentId = firstParentId

    /**
     * @type {Number}
     */
    this.reminderDays = reminderDays

    /**
     * @type {Number}
     */
    this.typeId = typeId
  }
}
