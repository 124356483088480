import { ModelApiService } from './BaseApiService'

export default class EventsApiService extends ModelApiService {
  constructor() {
    super('events')
  }

  async getEventImage(eventId) {
    return await this.getBlob(`${eventId}/image`)
  }
}
