var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('ValidationProvider',{attrs:{"name":_vm.$attrs.label,"rules":_vm.rules},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',_vm._g(_vm._b({attrs:{"type":_vm.type,"error-messages":errors},model:{value:(_vm.innerValue),callback:function ($$v) {_vm.innerValue=$$v},expression:"innerValue"}},'v-text-field',
      _vm.$attrs
      // https://vuejs.org/v2/guide/components-props.html#Disabling-Attribute-Inheritance
    ,false),
      _vm.$listeners
      // https://vuejs.org/v2/guide/components-custom-events.html#Binding-Native-Events-to-Components
    ))]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }