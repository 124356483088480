/* eslint-disable no-unused-vars */
import { nanoid } from '@root/node_modules/nanoid/index'
import Interaction from './interaction'

/**
 * @class
 * @public
 */
export default class ActionButton {
  /**
   * @constructor
   */
  constructor({ text = '', icon, attributes = {}, interaction = {} } = {}) {
    /**
     * Unique key to facilitate the v-for loop. Generated by nanoid
     * @type {String}
     */
    this.key = nanoid()

    /**
     * Text displayed on the action button
     * @type {String}
     */
    this.text = text

    /**
     * Object that configure the action buttons on the dialog
     * @type {Array<Object>}
     */
    this.icon = icon

    /**
     * Object containing attributes to style the action button
     * @type {Object}
     * @see https://v2.vuetifyjs.com/en/api/v-btn/ for full list of attributes
     * @example
     *
     * ```
     * {
     *    id: 'confirm-btn',
     *    color: VuetifyColourClass.primary,
     *    text: true,
     *    title: 'Click here to accept the terms',
     *    'data-testid': 'promise-dialog--confirm-btn'
     * }
     * ```
     */
    this.attributes = attributes

    /**
     * Interaction triggered when action button is clicked
     * @type {Interaction}
     */
    this.interaction = interaction
  }
}
