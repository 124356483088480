import { ModelApiService } from './BaseApiService'

export default class DocumentsApiService extends ModelApiService {
  constructor() {
    super('documents')
  }

  async getFileTypes() {
    return await this.get('file-types')
  }

  /**
   * Downloads a candidate general file
   * @param {Number} documentId
   * @param {String} accessGui Guid
   * @returns
   */
  async downloadFile(documentId, accessGui) {
    return await this.getBlob(`${documentId}/file/${accessGui}`)
  }
}
