/**
 * Contains a list of potential error codes that the API can response with
 */
export default Object.freeze({
  /**
   * Errors relating to managing client agreements
   */
  Agreements: {
    /**
     * The requested agreement file Id was missing or invalid
     */
    InvalidFileId: 'invalid_file_id',
    /**
     * The requested agreement was not found
     */
    NotFound: 'agreement_not_found',
    /**
     * The candidate is not authorised to access the requested agreement
     */
    Unauthorised: 'not_authorised_to_access_agreement',
  },

  /**
   * Errors relating to authorisation and authentication functionality
   */
  Auth: {
    /**
     * Errors relating to the multi-factor auth functionality
     */
    MFA: {
      /**
       * The type of verification channel attempting to be registered already
       * exists for the candidate.
       */
      ChannelAlreadyExists: 'mfa_registration_channel_already_exists',
      /**
       * The supplied email address was invalid
       */
      EmailInvalid: 'mfa_email_invalid',
      /**
       * The supplied candidate id was missing or invalid
       */
      MissingCandidateId: 'mfa_registration_missing_candidate_id',
      /**
       * The supplied To property was missing
       */
      MissingTo: 'mfa_registration_missing_to',
      /**
       * The supplied phone number was invalid
       */
      PhoneNumberInvalid: 'mfa_phone_number_invalid',
      /**
       * The supplied phone number was invalid for the region determined
       * by the country code within the number itself
       */
      PhoneNumberInvalidForRegion: 'mfa_phone_number_invalid_for_region',
      /**
       * The supplied phone number was not a mobile number
       */
      PhoneNumberNotMobile: 'mfa_phone_number_not_mobile',
      /**
       * The verification channel provided was unsupported by the API
       */
      UnsupportedChannel: 'mfa_registration_unsupported_channel',
    },
  },

  /**
   * Errors relating to the Availability controller's endpoints
   */
  Availability: {
    /**
     * Missing or invalid status
     */
    UnsetStatus: 'availability_unset_status',
    /**
     * Request didn't contain at least one day in payload
     */
    NoDaysRequested: 'availability_no_days_requested',
    /**
     * Missing partial ranges in request payload
     */
    NoPartialRangesRequested: 'availability_no_days_requested',
    /**
     * The startTimeLocal property was invalid
     */
    InvalidStartTimeLocal: 'availability_invalid_time_start_local',
    /**
     * The endTimeLocal property was invalid
     */
    InvalidEndTimeLocal: 'availability_invalid_time_end_local',
  },

  /**
   * Errors relating to the Bookings controller endpoints
   */
  Bookings: {
    /**
     * The requested booking does not exist
     */
    DoesNotExist: 'booking_does_not_exist',
    /**
     * The booking Id sent in the request payload was invalid
     */
    InvalidId: 'invalid_booking_id',
    /**
     * The candidate is not authorised to access the requested booking
     */
    Unauthorised: 'user_not_authorized_to_view_booking',
    /**
     * Invalid value was sent for the MinutesLate property
     */
    MinutesLateOutOfRange: 'minutesLate_out_of_range',
    /**
     * Candidate attempted to fill themselves into a booking that wasn't tentative
     */
    NotTentative: 'booking_is_not_tentative',

    /**
     * Errors relating to managing guest access to a booking
     */
    GuestAccess: {
      /**
       * Guest access Id was missing or invalid
       */
      AccessIdMissing: 'booking_guest_access_id_missing',
      /**
       * Guest access key was missing or invalid
       */
      AccessKeyMissing: 'booking_guest_access_key_missing',
      /**
       * The requested booking was not found
       */
      NotFound: 'booking_guest_access_booking_not_found',
      /**
       * The requested booking has been cancelled
       */
      Cancelled: 'booking_guest_access_booking_cancelled',
      /**
       * The requested booking's fill status has been set to rejected
       */
      Rejected: 'booking_guest_access_tentative_rejected',
      /**
       * The requested booking is not tentative
       */
      NotTentative: 'booking_guest_access_not_tentative',
      /**
       * The requested booking's tentative status was unexpected
       */
      UnhandledTentativeStatus:
        'booking_guest_access_unhandled_tentative_status',
    },
  },

  /**
   * Errors relating to the management of candidate certifications
   */
  Certifications: {
    /**
     * Date Expiry was earlier than Date Obtained
     */
    BadInputDates: 'bad_input_dates',
    /**
     * The certification has been verified and cannot be deleted
     */
    CannotDeleteVerified: 'certification_verified',
    /**
     * The custom field's value exceeds the maximum number of characters.
     * Since this is a custom field, only a partial error code is provided
     * @example myCustomField_exceeds_max_length
     */
    CustomFieldExceedsMaxLength: '_exceeds_max_length',
    /**
     * The custom field's value is not within the possible range of valid
     * values.
     * Since this is a custom field, only a partial error code is provided
     * @example invalid_select_value_myCustomField
     */
    CustomFieldInvalidSelectValue: 'invalid_select_value_',
    /**
     * The custom field contains an unexpected value.
     * Since this is a custom field, only a partial error code is provided
     * @example unexpected_data_myCustomField
     */
    CustomFieldUnexpectedData: 'unexpected_data_',
    /**
     * The Date Expiry property was missing from the request payload
     */
    DateExpiryMissing: 'date_expiry_missing',
    /**
     * The Date Obtained property was missing from the request payload
     */
    DateObtainedMissing: 'date_obtained_missing',
    /**
     * File(s) weren't sent in the request payload
     */
    FileMissing: 'file_missing',
    /**
     * Requested certification file was not found
     */
    FileNotFound: 'certification_file_not_found',
    /**
     * The certification Id provided was invalid
     */
    IdInvalid: 'certification_id_invalid',
    /**
     * The access GUI was missing or invalid
     */
    MissingAccessGui: 'certification_file_missing_access_gui',
    /**
     * Invalid or missing certification type sent in request
     */
    NonExistingCertType: 'non_existing_cert_type',
    /**
     * The Note property's value exceeded the maximum number of characters
     */
    NoteExceedsMaxLength: 'note_exceeds_max_length',
    /**
     * The requested cerification was not found
     */
    NotFound: 'certification_not_found',
    /**
     * The RefNo property's value exceeded the maximum number of characters
     */
    RefNumberExceedsMaxLength: 'certrefno_exceeds_max_length',
    /**
     * The candidate does not have access to the requested certification
     */
    Unauthorised: 'certification_unauthorised',
  },

  /**
   * Errors relating to registering devices for push notification functionality
   */
  DeviceRegistrations: {
    /**
     * The requested device could not be found
     */
    NotFound: 'device_not_found',
  },

  /**
   * Errors related to Candidate General Files (aka Other Files) found within the
   * My Files view and grouped within the Documents controller
   */
  Documents: {
    /**
     * Candidate attempted to delete a candidate general file that was verified.
     * This is unlikely to occur without JS manipulation.
     */
    CannotDeleteVerified: 'candidate_general_file_cannot_delete_verified',
    /**
     * The supplied candidate Id was invalid or missing
     */
    InvalidCandidateId: 'employment_history_candidate_id_invalid',
    /**
     * The candidate general file Id provided in the request was missing or invalid
     */
    InvalidId: 'candidate_general_file_id_invalid',
    /**
     * Request payload was missing access gui
     */
    MissingAccessGui: 'candidate_general_file_missing_access_gui',
    /**
     * Requested candidate general file was not found
     */
    CandidateGeneralFileNotFound: 'candidate_general_file_not_found',
    /**
     * The chosen fileType is not a general file type
     */
    FileTypeNotGeneral: 'file_type_not_general',
    /**
     * There was nissing data sent to the api
     */
    IncompleteRequestData: 'incomplete_request_data',
  },

  /**
   * Errors related to professional development events
   */
  Events: {
    /**
     * The requested event was not found
     */
    NotFound: 'event_not_found',
    /**
     * Unable to retrieve the event's banner image
     */
    ImageNotFound: 'event_image_not_found',
  },

  /**
   * Shared error codes relating to accessing files
   */
  Files: {
    /**
     * The Base64 encoded file data was invalid
     */
    Base64StringInvalid: 'file_base64_string_invalid',
    /**
     * File doesn't exist for the requesting candidate
     */
    FileIdAndCandidateIdMismatch: 'candidate_file_id_and_candidate_id_mismatch',
    /**
     * The number of files being upload exceeds the limit
     */
    ExceedsFileUploadLimit: 'exceeds_file_upload_limit',
    /**
     * Requested file was not found
     */
    NotFound: 'file_not_found',
    /**
     * File exceeds maximum uploadable file size
     */
    TooLarge: 'file_too_large',
    /**
     * File being upload is in a file format that is unsupported by the API
     */
    UnsupportedFileExtension: 'unsupported_file_extension',
    /**
     * The file data was missing or invalid in the request
     */
    MissingData: 'missing_file_data',
    /**
     * The file's name was not provided with the request
     */
    MissingFileName: 'missing_file_name',
  },

  /**
   * Errors relating to the Qualifications series of endpoints
   */
  Qualifications: {
    /**
     * Institute property was missing in request payload
     */
    InstituteMissing: 'upload_qualification_institute_missing',
    /**
     * Degree property was missing in request payload
     */
    DegreeMissing: 'upload_qualification_degree_missing',
    /**
     * DateComplete property was missing in request payload
     */
    DateCompleteMissing: 'upload_qualification_date_complete_missing',
    /**
     * DateComplete property was invalid
     */
    DateCompleteInvalid: 'upload_qualification_date_complete_invalid',
    /**
     * Requested qualification was not found
     */
    NotFound: 'qualification_not_found',
    /**
     * Candidate attempted to delete a qualification record that was verified.
     * This is unlikely to occur without JS manipulation.
     */
    CannotDeleteVerified: 'cannot_delete_verified',
  },

  /**
   * Errors related to Ready2Work endpoints that handle signifying if the
   * candidate is up and ready to work
   */
  Ready2Work: {
    /**
     * Missing or invalid status
     */
    UnsetStatus: 'unset_status',
  },

  /**
   * Errors related to candidate registrations
   */
  Registrations: {
    /**
     * The requested registration was not found
     */
    NotFound: 'registration_not_found',
    /**
     * The candidate is not authorised to access the requested registration
     */
    Unauthorised: 'registration_candidate_unauthorized',
  },

  /**
   * Errors related to the work experience (aka employment history)
   * collection of endpoints
   */
  WorkExperience: {
    /**
     * Candidate attempted to delete a work experience record that was verified.
     * This is unlikely to occur without JS manipulation.
     */
    CannotDeleteVerified: 'employment_history_cannot_delete_verified',
    /**
     * DateFrom property was missing from request payload
     */
    DateFromMissing: 'upload_emp_history_date_from_missing',
    /**
     * The DateUntil property was missing and the candidate hasn't indicated that they
     * are currently in the role
     */
    DateUntilMissingAndNotCurrent:
      'upload_emp_history_date_until_missing_and_not_is_current',
    /**
     * The Details property was missing from the request payload
     */
    DetailsMissing: 'upload_emp_history_details_missing',
    /**
     * The value of the Details property exceeds the maximum number of characters
     * limit
     */
    DetailsExceedsMaxLength:
      'upload_emp_history_details_exceeds_max_char_count',
    /**
     * One of the dot points was missing its value from the request payload.
     * Check the param property in the error response for the index of the dot point
     */
    DotPointMissing: 'upload_emp_history_dot_point_missing_value',
    /**
     * One of the dot points' value exceeds the maximum number of characters
     * limit. Check the param property in the error response for the index of the dot point
     */
    DotPointExceedsMaxLength:
      'upload_emp_history_position_held_exceeds_max_char_count',
    /**
     * The requested work experience file was not found
     */
    FileNotFound: 'employment_history_file_not_found',
    /**
     * The supplied candidate Id was invalid or missing
     */
    InvalidCandidateId: 'employment_history_candidate_id_invalid',
    /**
     * The work experience Id provided in the request was missing or invalid
     */
    InvalidId: 'employment_history_id_invalid',
    /**
     * The requested work experience record was not found
     */
    NotFound: 'employment_history_not_found',
    /**
     * The PlaceOfWork property was missing from the request payload
     */
    PlaceOfWorkMissing: 'upload_emp_history_place_of_work_missing',
    /**
     * The value of the PlaceOfWork property exceeds the maximum number of characters
     * limit
     */
    PlaceOfWorkExceedsMaxLength:
      'upload_emp_history_place_of_work_exceeds_max_char_count',
    /**
     * The PositionHeld property was missing from the request payload
     */
    PositionHeldMissing: 'upload_emp_history_position_held_missing',
    /**
     * The value of the PositionHeld property exceeds the maximum number of characters
     * limit
     */
    PositionHeldExceedsMaxLength:
      'upload_emp_history_position_held_exceeds_max_char_count',
  },
})
