import Vue from 'vue'
import { logger } from '@/services/logging/AppLogger'
import VueErrorDTO from '@/models/error/vueErrorDTO'
import WindowErrorDTO from '@/models/error/windowErrorDTO'

/**
 * Captures the errors that are specific to Vue instances. It would not be able
 * to capture the errors which are outside of Vue instances such as utils files,
 * services etc.
 * @param {*} error complete error trace, contains the `message` and `error stack`
 * @param {*} vm Vue component/instance in which error is occurred
 * @param {*} info Vue specific error information such as lifecycle hooks, events etc.
 */
Vue.config.errorHandler = (error, vm, info) => {
  logger.logError(new VueErrorDTO({ error, vm, info }))
}

/**
 * Captures unhandled expections outside of the Vue instance
 * @param {String} message A string containing a human-readable error message describing the problem. Same as `ErrorEvent.event`
 * @param {String} source A string containing the URL of the script that generated the error.
 * @param {Number} lineno An integer containing the line number of the script file on which the error occurred.
 * @param {Number} colno An integer containing the column number of the script file on which the error occurred.
 * @param {Error} error The error being thrown. Usually an `Error` object.
 */
window.onerror = function (message, source, lineno, colno, error) {
  logger.logError(new WindowErrorDTO({ message, source, lineno, colno, error }))
}

/**
 * Captures promise rejections that are not handled by window.onerror
 * @tutorial https://developer.mozilla.org/en-US/docs/Web/API/Window/unhandledrejection_event
 *
 * @param {PromiseRejectionEvent} event
 */
const handlePromiseRejection = function (event) {
  logger.logError(event)
}

window.addEventListener('unhandledrejection', handlePromiseRejection)
