/* eslint-disable no-unused-vars */
import ComplianceItemStatus from '@/constants/registrations/ComplianceItemStatus'

/**
 * @class
 * @public
 */
export default class ComplianceItemStatusUpdateDto {
  constructor({ complianceItemId, status } = {}) {
    /**
     * @type {Number}
     */
    this.complianceItemId = complianceItemId

    /**
     * @type {String}
     * @see {ComplianceItemStatus} see for valid values
     */
    this.status = status
  }
}
