import { ModelApiService } from './BaseApiService'

export default class FileApiService extends ModelApiService {
  constructor() {
    super('file')
  }

  async createFileAccessToken(fileId) {
    return this.get(`generateAccessToken/${fileId}`)
  }

  async getGeneralFile(fileName) {
    return this.getBlob('general', { filePath: fileName })
  }
}
