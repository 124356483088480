import { extractFileNameFromContentDisposition } from '@helpers/file-helpers'
import { getType } from 'mime'
import { fail, success } from '@/helpers/result-helper.js'
import { isCacheFresh } from '@/helpers/cache-helpers'
import DurationUnits from '@/constants/date/DurationUnits.js'
import toast from '@/services/toasts/index.js'
import dayjs from '@/services/date/index.js'
import { processFilesForUpload } from '@/helpers/file-helpers'
// eslint-disable-next-line no-unused-vars
import WorkExperienceUploadFormDTO from '@/models/workExperience/workExperienceUploadFormDTO'
import WorkExperienceUploadDTO from '@/models/workExperience/workExperienceUploadDTO'
import WorkExperienceDotPointDTO from '@/models/workExperience/workExperienceDotPointDTO'
import WorkExperienceSummaryModel from '@/models/workExperience/workExperienceSummaryModel'
// eslint-disable-next-line no-unused-vars
import ResultDTO from '@/models/app/resultDTO'
import WorkExperienceDetailsVM from '@/models/workExperience/workExperienceDetailsVM'
import HttpHeaders from '@/constants/api/HttpHeaders'
import { isNonEmptyArray } from '@/helpers/array-helpers'
import { WorkExperienceActionErrorMessageFactory } from '@/services/workExperience/WorkExperienceActionErrorMessageFactory'
import DateFormatToken from '@/constants/date/DateFormatToken'

const getDefaultState = () => {
  return {
    workExperience: null,
    loadingWorkExperienceCount: 0,
    loadingCount: 0,
    crudLoadingCount: 0,
    loadingDetailsCount: 0,
    loadingDeleteCount: 0,
    loadingFile: [],
  }
}

const state = getDefaultState()

export default {
  namespaced: true,
  /**
   * @typedef {{
   * workExperience: WorkExperienceDetailsVM
   * loadingWorkExperienceCount: Number
   * loadingCount: Number
   * crudLoadingCount: Number
   * loadingDetailsCount: Number
   * loadingDeleteCount: Number
   * loadingFile: Array<String>
   * }} WorkExpState
   * @type {WorkExpState}
   */
  state,
  getters: {
    moduleName: () => 'work-experience',
    workExperience: (state) => state.workExperience?.list,
    isLoading: (state) => state.loadingCount > 0,
    isLoadingWorkExp: (state) => state.loadingWorkExperienceCount > 0,
    isLoadingCRUD: (state) => state.crudLoadingCount > 0,
    isLoadingDetails: (state) => state.loadingDetailsCount > 0,
    isLoadingDelete: (state) => state.loadingDeleteCount > 0,
    getDownloadFileLoadingState: (state) => (accessGui) =>
      state.loadingFile.includes(accessGui),
    isDownloadingFile: (state, getters) => (accessGui) =>
      !!getters.getDownloadFileLoadingState(accessGui),
  },
  mutations: {
    START_LOADING(state) {
      state.loadingCount++
    },
    FINISH_LOADING(state) {
      state.loadingCount--
    },
    START_LOADING_DETAILS(state) {
      state.loadingDetailsCount++
    },
    FINISH_LOADING_DETAILS(state) {
      state.loadingDetailsCount--
    },
    START_LOADING_WORK_EXP(state) {
      state.loadingWorkExperienceCount++
    },
    FINISH_LOADING_WORK_EXP(state) {
      state.loadingWorkExperienceCount--
    },
    START_LOADING_CRUD(state) {
      state.crudLoadingCount++
    },
    FINISH_LOADING_CRUD(state) {
      state.crudLoadingCount--
    },
    START_LOADING_DELETE(state) {
      state.loadingDeleteCount++
    },
    FINISH_LOADING_DELETE(state) {
      state.loadingDeleteCount--
    },
    START_DOWNLOADING_FILE(state, accessGui) {
      const alreadyExists = state.loadingFile.includes(accessGui)
      if (alreadyExists) return
      state.loadingFile.push(accessGui)
    },
    FINISH_DOWNLOADING_FILE(state, accessGui) {
      const foundIndex = state.loadingFile.findIndex(
        (cachedAccessGuid) => cachedAccessGuid === accessGui
      )
      if (foundIndex < 0) return
      state.loadingFile.splice(foundIndex, 1)
    },
    SET_WORK_EXP(state, workExperience) {
      const mappedWorkExp =
        !workExperience || workExperience.length === 0
          ? []
          : workExperience.map(
              (workExp) => new WorkExperienceSummaryModel(workExp)
            )

      state.workExperience = {
        list: mappedWorkExp,
        lastUpdated: dayjs(),
        isDirty: false,
      }
    },
    INSERT_WORK_EXPERIENCE(state, workExperience) {
      state.workExperience.list.push(
        new WorkExperienceSummaryModel(workExperience)
      )
    },
    SET_WORK_EXP_DIRTY(state) {
      if (!state.workExperience) return // Will prevent an exception if work exp aren't initialised
      state.workExperience.isDirty = true
    },
    REMOVE_WORK_EXPERIENCE(state, workExperienceId) {
      if (!isNonEmptyArray(state.workExperience?.list)) return

      const foundIndex = state.workExperience.list.findIndex(
        (workExperience) => workExperience.id === workExperienceId
      )
      // If not found, reload work exp summary list
      if (foundIndex < 0) {
        if (!state.workExperience) return
        state.workExperience.isDirty = true
      }

      state.workExperience.list.splice(foundIndex, 1)
    },
    CLEAR_STORE(state) {
      // Resets store to default state
      Object.assign(state, getDefaultState())
    },
  },
  actions: {
    /**
     * @param {{commit: Function, getters: Object, dispatch: Function}} vuexContext
     * @param {Boolean} forceRefresh
     * @returns {Promise<ResultDTO>}
     */
    async getWorkExperienceList({ commit, getters, dispatch }, forceRefresh) {
      // 1. Check if we already have the work exp cached
      if (
        isCacheFresh({
          cacheDuration: 1,
          durationUnits: DurationUnits.HOUR,
          lastUpdated: state.workExperience?.lastUpdated,
          forceRefresh,
          isDirty: state.workExperience?.isDirty,
        })
      )
        return success({ data: getters.workExperience })

      // 2. Fetch work exp from api
      commit('START_LOADING_WORK_EXP')

      try {
        const response = await this.$api.workExperience.get()
        commit('SET_WORK_EXP', response.data)
        return success({ data: getters.workExperience })
      } catch (ex) {
        toast.error(this.$i18n.t('workExperience.errorLoadWorkExperience'))
        return fail({
          error: await dispatch('logException', ex, { root: true }),
        })
      } finally {
        commit('FINISH_LOADING_WORK_EXP')
      }
    },
    /**
     * @param {{commit: Function, dispatch: Function}} vuexContext
     * @param {WorkExperienceUploadFormDTO} workExperienceToUpload
     * @returns {Promise<ResultDTO>}
     */
    async uploadWorkExperience({ commit, dispatch }, workExperienceToUpload) {
      const payload = new WorkExperienceUploadDTO({
        placeOfWork: workExperienceToUpload.placeOfWork?.trim(),
        positionHeld: workExperienceToUpload.positionHeld?.trim(),
        dateFrom: dayjs(workExperienceToUpload.dateFrom?.trim())
          .local()
          .format(DateFormatToken.YYYYMMDDT0),
        dateUntil: !workExperienceToUpload.isCurrent
          ? dayjs(workExperienceToUpload.dateUntil?.trim())
              .local()
              .format(DateFormatToken.YYYYMMDDT0)
          : null,
        details: workExperienceToUpload.details?.trim(),
        dotPoints: workExperienceToUpload.dotPoints.map(
          (dotPoint) =>
            new WorkExperienceDotPointDTO({
              type: dotPoint.type,
              value: dotPoint.value?.trim(),
            })
        ),
        isCurrent: workExperienceToUpload.isCurrent,
        files: await processFilesForUpload(workExperienceToUpload.files),
      })

      commit('START_LOADING_CRUD')
      try {
        const response = await this.$api.workExperience.post('', payload)
        commit('INSERT_WORK_EXPERIENCE', {
          id: response.data.id,
          placeOfWork: payload.placeOfWork,
          positionHeld: payload.positionHeld,
          dateFrom: payload.dateFrom,
          dateUntil: payload.dateUntil,
          employmentDays: 0,
          isCurrent: payload.isCurrent,
          isVerified: false,
        })
        return response
      } catch (ex) {
        const error = await dispatch('logException', ex, { root: true })
        toast.error(error.message)
        return fail({ error })
      } finally {
        commit('FINISH_LOADING_CRUD')
      }
    },
    /**
     * Retrieves work experience details by id
     * @param {{commit: Function, dispatch: Function}} vuexContext
     * @param {Number} workExpId
     * @returns {Promise<ResultDTO>}
     */
    async getWorkExperienceDetails({ commit, dispatch }, workExpId) {
      commit('START_LOADING_DETAILS')
      try {
        const result = await this.$api.workExperience.get(workExpId)
        result.data = new WorkExperienceDetailsVM(result.data)
        return result
      } catch (ex) {
        const error = await dispatch('logException', ex, { root: true })

        if (ex.response.status === 404) {
          error.message = this.$i18n.t(
            'workExperience.view.recordNotFoundError'
          )
        }

        toast.error(error.message)
        return fail({ error, statusCode: ex.response.status })
      } finally {
        commit('FINISH_LOADING_DETAILS')
      }
    },
    /**
     * Downloads blob of the work experience file
     * @param {{commit: Function, dispatch: Function}} vuexContext
     * @param {{workExperienceId: Number, accessGui: String}} payload
     * @returns {Promise<ResultDTO>} Blob of the work experience file
     */
    async downloadWorkExperienceFile(
      { commit, dispatch },
      { workExperienceId, accessGui, disableToast = false }
    ) {
      commit('START_DOWNLOADING_FILE', accessGui)
      try {
        const response = await this.$api.workExperience.downloadFile({
          workExperienceId,
          accessGui,
        })
        const fileName = extractFileNameFromContentDisposition(
          response.headers[HttpHeaders.contentDisposition]
        )
        const blob = new Blob([response.data], {
          type: getType(fileName),
        })
        return success({ data: blob })
      } catch (ex) {
        if (!disableToast) {
          toast.error(this.$i18n.t('error.unableToLoadFile'))
        }
        return fail({
          error: await dispatch('logException', ex, { root: true }),
        })
      } finally {
        commit('FINISH_DOWNLOADING_FILE', accessGui)
      }
    },
    /**
     * Deletes a candidate's work experience record
     * @param {{commit: Function, dispatch: Function}} vuexContext
     * @param {Number} workExperienceId
     * @returns {Promise<ResultDTO>}
     */
    async deleteWorkExperience({ commit, dispatch }, workExperienceId) {
      commit('START_LOADING_DELETE')
      try {
        const response = await this.$api.workExperience.delete(workExperienceId)

        commit('REMOVE_WORK_EXPERIENCE', workExperienceId)

        toast.success(this.$i18n.t('workExperience.delete.successToastMessage'))

        return response
      } catch (ex) {
        const error = await dispatch('logException', ex, { root: true })
        toast.error(
          WorkExperienceActionErrorMessageFactory({
            actionName: 'deleteWorkExperience',
            error,
            i18n: this.$i18n,
          })
        )
        return fail({ error, statusCode: ex.response.status })
      } finally {
        commit('FINISH_LOADING_DELETE')
      }
    },
    /**
     * Resets store to default state.
     * @param {{commit: Function}} vuexContext
     */
    clear({ commit }) {
      commit('CLEAR_STORE')
    },
  },
}
