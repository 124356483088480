import CandidateBookingDetailsLocationAddressDTO from './candidateBookingDetailsLocationAddressDTO'
import CandidateBookingSummaryLocationViewModel from './candidateBookingSummaryLocationViewModel'

/**
 * A location's basic details and address information
 * @class
 * @public
 */
export default class CandidateBookingDetailsLocationDTO extends CandidateBookingSummaryLocationViewModel {
  /**
   * @param {{ id: number, name: string, address: CandidateBookingDetailsLocationAddressDTO }} param0
   */
  constructor({
    id = null,
    name = '',
    address = new CandidateBookingDetailsLocationAddressDTO(),
  } = {}) {
    super(id, name)

    /**
     * @type {CandidateBookingDetailsLocationAddressDTO}
     */
    this.address = address
  }
}
