export default Object.freeze({
  authorization: 'Authorization',
  /**
   * In a regular HTTP response, the Content-Disposition response header is a header indicating if the content is expected to be displayed inline in the browser, that is, as a Web page or as part of a Web page, or as an attachment, that is downloaded and saved locally.
   *
   * In a multipart/form-data body, the HTTP Content-Disposition general header is a header that must be used on each subpart of a multipart body to give information about the field it applies to. The subpart is delimited by the boundary defined in the Content-Type header. Used on the body itself, Content-Disposition has no effect.
   *
   * The Content-Disposition header is defined in the larger context of MIME messages for email, but only a subset of the possible parameters apply to HTTP forms and POST requests. Only the value form-data, as well as the optional directive name and filename, can be used in the HTTP context.
   * @tutorial https://developer.mozilla.org/en-US/docs/Web/HTTP/Headers/Content-Disposition
   */
  contentDisposition: 'content-disposition',

  // Custom headers
  /**
   * Instructs the server which version of the API to use
   */
  acceptVersion: 'Accept-Version',
  /**
   * Contains the ID of the candidate being impersonated
   */
  impersonatedCandidate: 'Impersonated-Candidate',
})
