import HttpStatusCodes from '@/constants/api/HttpStatusCodes'
import ShiftRecordErrorAlertType from '@/constants/booking/ShiftRecordErrorAlertType'
import InteractionType from '@/constants/core/InteractionType'
import VuetifyColourClass from '@/constants/core/VuetifyColourClass'
import ShiftRecordErrorDTO from '@/models/booking/shiftRecord/shiftRecordErrorDTO'
import ActionButton from '@/models/general/actionButton'
import EmitEvent from '@/models/general/emitEvent'
import Interaction from '@/models/general/interaction'

/**
 * Generates a shift record error DTO for error codes sent with the response code 422
 * @param { { data: Object, status: Number } } response
 * @param {{ t: Function }} i18n
 * @returns
 */
const unprocessableEntityHandler = (response, i18n) => {
  const errorDto = new ShiftRecordErrorDTO({
    testId: response.data.code,
    showContactCard: true,
    actions: [
      new ActionButton({
        text: i18n.t('general.closeBtn'),
        icon: 'mdi-close',
        attributes: {
          outlined: true,
          large: true,
          class: 'mt-6',
        },
        interaction: new Interaction({
          type: InteractionType.emitEvent,
          value: new EmitEvent({ event: 'closeDialog' }),
        }),
      }),
    ],
    onLoadInteraction: new Interaction({
      type: InteractionType.emitEvent,
      value: null,
    }),
  })

  switch (response.data.code) {
    // Shift record was not compliant for specific region.
    // Error dto will contain markdown instructions to be
    // displayed in a dialog to the user.
    case 'shift_record_non_compliance':
      errorDto.alertType = ShiftRecordErrorAlertType.nonCompliant
      errorDto.actions = []
      errorDto.onLoadInteraction = null
      errorDto.message = response?.data?.message || errorDto.message
      break
    case 'shift_record_booking_already_cancelled':
      errorDto.alertType = ShiftRecordErrorAlertType.statusBlock
      errorDto.alertIcon = 'mdi-alert-circle-outline'
      errorDto.alertStyle = VuetifyColourClass.error
      errorDto.title = 'Unable to submit your shift details'
      errorDto.onLoadInteraction.value = new EmitEvent({
        event: 'modifyShiftRecordCache',
        value: 'removeBookingThatDidNotHappen',
      })
      errorDto.message = i18n.t(
        'bookings.shiftRecord.submit.errors.bookingAlreadyCancelled'
      )
      break
    case 'shift_record_booking_already_invoiced':
      errorDto.alertType = ShiftRecordErrorAlertType.statusBlock
      errorDto.alertIcon = 'mdi-alert-circle-outline'
      errorDto.alertStyle = VuetifyColourClass.error
      errorDto.title = 'Unable to submit your shift details'
      errorDto.onLoadInteraction.value = new EmitEvent({
        event: 'modifyShiftRecordCache',
        value: 'updateBookingShiftRecordActionableItemToComplete',
      })
      errorDto.message = i18n.t(
        'bookings.shiftRecord.submit.errors.bookingAlreadyFinalised'
      )
      break
  }

  return errorDto
}

/**
 * Default shift record error DTO generator
 * @param { { data: Object, status: Number } } response
 * @param {{ t: Function }} i18n
 * @returns
 */
const defaultStatusCodeHandler = (response, i18n) => {
  const errorDto = new ShiftRecordErrorDTO({
    testId: response.data.code,
    showContactCard: true,
  })

  switch (response.data.code) {
    case 'shift_record_break_duration_invalid_range':
      errorDto.message = i18n.t(
        'bookings.shiftRecord.submit.errors.breakDurationInvalid'
      )
      break
    case 'shift_record_break_duration_out_of_bounds':
      errorDto.message = i18n.t(
        'bookings.shiftRecord.submit.errors.breakDurationOutOfBounds'
      )
      break
    case 'shift_record_no_blocks':
      errorDto.message = i18n.t('bookings.shiftRecord.submit.errors.noBlocks')
      break
    case 'shift_record_booking_duration_not_found':
      errorDto.message = i18n.t(
        'bookings.shiftRecord.submit.errors.bookingDurationMissing'
      )
      break
    case 'multiple_shift_record_booking_durations':
      errorDto.message = i18n.t(
        'bookings.shiftRecord.submit.errors.multipleBookingDurations'
      )
      break
    case 'shift_record_booking_duration_start_time_invalid_format':
      errorDto.message = i18n.t(
        'bookings.shiftRecord.submit.errors.bookingDurationStartTimeInvalid'
      )
      break
    case 'shift_record_booking_duration_end_time_invalid_format':
      errorDto.message = i18n.t(
        'bookings.shiftRecord.submit.errors.bookingDurationEndTimeInvalid'
      )
      break
    case 'shift_record_booking_duration_invalid_range':
      errorDto.message = i18n.t(
        'bookings.shiftRecord.submit.errors.bookingDurationInvalid'
      )
      break
    case 'shift_record_booking_not_found':
      errorDto.message = i18n.t(
        'bookings.shiftRecord.submit.errors.bookingNotFound'
      )
      break
    case 'shift_record_booking_not_authorised':
      errorDto.message = i18n.t(
        'bookings.shiftRecord.submit.errors.bookingNotAuthorised'
      )
      break
    case 'shift_record_already_exists':
      errorDto.alertType = ShiftRecordErrorAlertType.statusBlock
      errorDto.alertIcon = 'mdi-check-circle'
      errorDto.alertStyle = VuetifyColourClass.success
      errorDto.message = i18n.t(
        'bookings.shiftRecord.alreadySubmitted.conflictDescription'
      )
      break
    case 'shift_record_break_start_time_invalid_format':
      errorDto.message = i18n.t(
        'bookings.shiftRecord.submit.errors.breakStartTimeInvalid'
      )
      break
    case 'shift_record_break_end_time_invalid_format':
      errorDto.message = i18n.t(
        'bookings.shiftRecord.submit.errors.breakEndTimeInvalid'
      )
      break
    case 'shift_record_break_duration_overlapping':
      errorDto.message = i18n.t(
        'bookings.shiftRecord.submit.errors.breaksOverlapping'
      )
      break
    default:
      errorDto.message = i18n.t(
        'bookings.shiftRecord.submit.errors.genericError'
      )
      break
  }

  return errorDto
}

/**
 * Determines the error message and action type based on the failure response from the server
 * @param { { data: Object, status: Number } } response
 * @param {{ t: Function }} i18n
 * @returns
 */
const shiftRecordPostErrorHandler = (response, i18n) => {
  switch (response.status) {
    case HttpStatusCodes.UnprocessableEntity:
      return unprocessableEntityHandler(response, i18n)
    default:
      return defaultStatusCodeHandler(response, i18n)
  }
}

export default shiftRecordPostErrorHandler
