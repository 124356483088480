import ApiErrorCodes from '@/constants/api/ApiErrorCodes'
import HttpStatusCodes from '@/constants/api/HttpStatusCodes'
// eslint-disable-next-line no-unused-vars
import ErrorResponseDTO from '@/models/error/errorResponseDTO'

// Factory

/**
 * Factory to handle generating error messages for the work experience actions
 * @param {{actionName: String, error: ErrorResponseDTO, i18n: {t: Function}}} payload
 * @returns
 */
export function WorkExperienceActionErrorMessageFactory({
  actionName,
  error,
  i18n,
}) {
  switch (actionName) {
    case 'deleteWorkExperience':
      return deleteWorkExperienceActionErrorMessageHandler({ error, i18n })
    default:
      return error?.message
  }
}

// Handlers

/**
 * Generate error message for the deleteWorkExperience store action
 * @param {{error: ErrorResponseDTO, i18n: {t: Function}}} payload
 * @returns
 */
const deleteWorkExperienceActionErrorMessageHandler = ({ error, i18n }) => {
  let errorMessage = error.message

  switch (error?.error?.response?.status) {
    case HttpStatusCodes.NotFound:
      errorMessage = i18n.t('workExperience.view.recordNotFoundError')
      break
    case HttpStatusCodes.BadRequest:
      if (error.code === ApiErrorCodes.WorkExperience.CannotDeleteVerified)
        errorMessage = i18n.t('workExperience.view.deleteDisabledTooltip')
      break
  }

  return errorMessage
}
