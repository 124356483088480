/**
 * @class
 * @public
 */
export default class GenericFileModel {
  constructor({ base64FileData, fileName = '' } = {}) {
    /**
     * File data encoded into base64 string
     * @type {String}
     */
    this.base64FileData = base64FileData
    /**
     * Name of file with file extension (e.g. "myFile.pdf")
     * @type {String}
     */
    this.fileName = fileName
  }
}
