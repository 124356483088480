/**
 * Payload to be sent to the server to signal that the candidate is running late to a specified booking
 */
export default class RunningLatePayload {
  constructor({ bookingId = null, minutesLate = 0 } = {}) {
    /**
     * @type {Number}
     */
    this.bookingId = bookingId

    /**
     * The value to be emitted once selected by the user
     * @type {Number}
     */
    this.minutesLate = minutesLate
  }
}
