// eslint-disable-next-line no-unused-vars
import BookingActionableItemType from '@/constants/booking/BookingActionableItemType'

export default class CandidateBookingOverviewBookingItemViewModel {
  constructor({
    id = null,
    locationId = null,
    clientId = null,
    groupId = null,
    actionableItems = [],
  } = {}) {
    /**
     * Booking Id
     * @type {Number}
     */
    this.id = id
    /**
     * @type {Number}
     */
    this.locationId = locationId
    /**
     * @type {Number}
     */
    this.clientId = clientId
    /**
     * Id of the block booking this booking is a part of.
     * @type {Number}
     */
    this.groupId = groupId

    /**
     * List of actionable items for the booking
     * @type {String[]}
     * @see {BookingActionableItemType} for valid range of values
     */
    this.actionableItems = actionableItems
  }
}
