<script>
import baseMixin from '@/mixins/baseMixin'
import { getStatusLabelHashMap } from '@/helpers/status-helper'

export default {
  name: 'BaseStatusLabel',
  mixins: [baseMixin],
  props: {
    text: {
      type: String,
      required: true,
    },
    color: {
      type: String,
      default: 'grey',
    },
    icon: {
      type: Boolean,
      default: false,
    },
    label: {
      type: Boolean,
      default: false,
    },
    outlined: {
      type: Boolean,
      default: false,
    },
    mobile: {
      type: Boolean,
      default: false,
    },
    capitalize: {
      type: Boolean,
      default: false,
    },
    iconType: {
      type: String,
      default: '',
    },
    tooltip: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      map: new Map(),
    }
  },
  computed: {
    content() {
      return this.getBadgeFromStr(this.text)
    },
  },
  created: function () {
    this.map = getStatusLabelHashMap()
  },
  methods: {
    getBadgeFromStr(str) {
      const badge = this.map.get(str)
      if (badge) return badge

      return { title: str, color: this.color, icon: this.iconType }
    },
    getBadgeFromArr(arr) {
      const badges = []

      for (let i = 0; i < arr.length; i++) {
        badges.push(this.getBadgeFromStr(arr[i]))
      }
      return badges
    },
  },
}
</script>

<template>
  <span>
    <span
      v-if="content && icon && !Array.isArray(content)"
      :class="['status-badge body-2', capitalize ? 'text-capitalize' : '']"
    >
      <v-tooltip :disabled="!tooltip" bottom>
        <template v-slot:activator="{ on, attrs }">
          <span v-bind="attrs" v-on="on">
            <v-icon left :class="content.color + '--text'" small>
              {{ content.icon }}
            </v-icon>
            {{ mobile ? '' : content.title }}
          </span>
        </template>
        <span>{{ tooltip }}</span>
      </v-tooltip>
    </span>

    <v-chip
      v-else-if="content && !Array.isArray(content)"
      x-small
      :outlined="outlined"
      :label="label"
      class="status-badge"
      :color="content.color"
      dark
    >
      {{ content.title }}
    </v-chip>
    <div v-else-if="content && Array.isArray(content)">
      <template v-for="(badge, index) in content">
        <v-chip :key="index" x-small light :outlined="outlined">
          {{ badge.title }}
        </v-chip>
        &nbsp;
      </template>
    </div>
  </span>
</template>
