import { mapActions } from 'vuex'

export default {
  async mounted() {
    await this.setFavicon()
  },
  methods: {
    ...mapActions({
      setFavicon: 'setFavicon',
    }),
  },
}
