import { Ready2WorkStatus } from '@/constants/candidate/Ready2WorkStatus'
import { ActiveDay } from '@/constants/date/ActiveDay'

/**
 * @class
 * @public
 */
export default class R2WAvailabilityStatusViewModel {
  constructor({
    activeDateLocal = null,
    activeDay = ActiveDay.TODAY,
    ready2Work = Ready2WorkStatus.UNSET,
    timeUntilUpdateRequiredInMilliSeconds = 0,
  } = {}) {
    /**
     * The date that determines the target for the ready2work button. Typically will be today's date but will switch in the afternoon to target the next day
     * @type {String}
     * @example 2022-09-30T00:00:00
     */
    this.activeDateLocal = activeDateLocal

    /**
     * @type {ActiveDay}
     */
    this.activeDay = activeDay

    /**
     * Determines if the candidate has set their ready2work for the active day or not.
     * @type {Ready2WorkStatus}
     */
    this.ready2Work = ready2Work

    /**
     * The time, in milliseconds, until the ready2work button switches over to the next active day
     * @type {Number}
     */
    this.timeUntilUpdateRequiredInMilliSeconds =
      timeUntilUpdateRequiredInMilliSeconds
  }
}
