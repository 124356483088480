// eslint-disable-next-line no-unused-vars
import HttpRequestUrl from './httpRequestUrl'
import HttpResponseType from '@/constants/api/HttpResponseType'

/**
 * @class
 * @public
 */
export default class HttpRequestConfig {
  constructor({
    method,
    url,
    data = null,
    headers = null,
    responseType = HttpResponseType.json,
    isAuthenticated = true,
  } = {}) {
    /**
     * HTTP Method
     * @type {String}
     */
    this.method = method

    /**
     * Endpoint url
     * @type {HttpRequestUrl}
     */
    this.url = url

    /**
     * Payload to send to server
     * @type {*}
     */
    this.data = data

    /**
     * Dictionary of key pair values representing request headers
     * @type {Object}
     */
    this.headers = headers

    /**
     * Denotes if the request requires an auth token header to be generated
     * @type {Boolean}
     */
    this.isAuthenticated = isAuthenticated

    /**
     * A http response type. Please see `HttpResponseType`
     * @type {String}
     */
    this.responseType = responseType
  }
}
