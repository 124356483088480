import { isEmpty } from 'lodash'
import dayjs from '@/services/date'
// eslint-disable-next-line no-unused-vars
import Duration from '@/models/general/duration'

/**
 * @obselete Use checkCacheFreshness instead
 */
const isCacheFresh = ({
  cacheDuration,
  durationUnits,
  lastUpdated,
  forceRefresh = false,
  isDirty = false,
}) => {
  // If not being forced to refresh and it hasn't been longer than staleness threshold
  // return resource without API call
  return (
    !isEmpty(lastUpdated) &&
    dayjs(lastUpdated).isValid() &&
    !forceRefresh &&
    !isDirty &&
    dayjs().diff(lastUpdated, durationUnits) < cacheDuration
  )
}

const isCacheNonexpired = ({ expirationDateTimeUtc, forceRefresh = false }) => {
  return (
    !isEmpty(expirationDateTimeUtc) &&
    dayjs(expirationDateTimeUtc).isValid() &&
    !forceRefresh &&
    dayjs.utc().isBefore(expirationDateTimeUtc)
  )
}

/**
 * Updated isCacheFresh function that will determine cache freshness in relation
 * to the lastUpdated date
 * @param {Duration} duration
 * @param {Date} lastUpdated
 * @param {Boolean} forceRefresh
 * @returns {Boolean} true if cache is fresh
 */
const checkCacheFreshness = (duration, lastUpdated, forceRefresh = false) => {
  if (forceRefresh) return false

  return (
    !isEmpty(lastUpdated) &&
    dayjs(lastUpdated).isValid() &&
    dayjs().diff(lastUpdated, duration.unit) < duration.value
  )
}

const getSavedState = (key) => {
  const item = window.localStorage.getItem(key)
  return item && item !== 'undefined' ? JSON.parse(item) : null
}

const saveState = (key, state) => {
  window.localStorage.setItem(key, JSON.stringify(state))
}

const deleteState = (key) => {
  saveState(key, null) // extra precaution
  window.localStorage.removeItem(key)
}

export {
  isCacheFresh,
  isCacheNonexpired,
  checkCacheFreshness,
  saveState,
  deleteState,
  getSavedState,
}
