/**
 * Used to identify errors thrown when required config is missing or empty
 */
export default class MissingConfigurationError extends Error {
  /**
   * @param {String} resourceName name of config resource that wasn't found
   */
  constructor({ message = null, resourceName = null }) {
    super(message || `Required config was not found or null: '${resourceName}'`)
    this.name = 'MissingConfigurationError'
  }
}
