import WorkExperienceDotPointType from '@/constants/workExperience/WorkExperienceDotPointType'

/**
 * @class
 * @public
 */
export default class WorkExperienceDotPointDTO {
  /**
   * @constructor
   * @param {Object<type: String, value: String>} param0
   */
  constructor({
    type = WorkExperienceDotPointType.general,
    value = null,
  } = {}) {
    /**
     * See WorkExperienceDotPointType for accepted values
     * @type {String}
     */
    this.type = type

    /**
     * @type {String}
     */
    this.value = value
  }
}
