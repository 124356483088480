import Vue from 'vue'
import Vuetify, {
  VApp,
  VIcon,
  VCard,
  VSnackbar,
  VBtn,
  VChip,
  VSelect,
  VTextField,
  VTooltip,
} from 'vuetify/lib'

Vue.use(Vuetify, {
  components: {
    VApp,
    VIcon,
    VCard,
    VSnackbar,
    VBtn,
    VChip,
    VSelect,
    VTextField,
    VTooltip,
  },
})

export default new Vuetify({
  theme: {
    options: {
      customProperties: true,
    },
    themes: {
      light: {
        primary: '#3d3088',
        blue: '#00acef',
        info: '#00acef',
        secondary: '#1867c0',
        indigo: '#3d3088',
        warning: '#F5B51A',
      },
      dark: {
        primary: '#ab92f7',
        blue: '#00acef',
        info: '#00acef',
        indigo: '#ab92f7',
        warning: '#F5B51A',
      },
    },
  },
})
