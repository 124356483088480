import Vue from 'vue'
import VueI18n from 'vue-i18n'
import { getLanguageBasedOnBaseURL } from '@/helpers/language-helpers'
import vueI18nSettings from '@/locales/setup/index'

Vue.use(VueI18n)

// https://kazupon.github.io/vue-i18n/api/#constructor-options
export default new VueI18n({
  // locale: The locale of localization. If the locale contains a territory and a dialect, this locale contains an implicit fallback.
  locale: getLanguageBasedOnBaseURL(),
  ...vueI18nSettings,
})
