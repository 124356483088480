/* eslint-disable no-unused-vars */
import CandidateBookingDetailsViewModel from './candidateBookingDetailsViewModel'
import ShortTermTentativeStatus from '@/constants/booking/ShortTermTentativeStatus'

export default class CandidateBookingDetailsGuestAccessViewModel extends CandidateBookingDetailsViewModel {
  constructor({
    id,
    startTimeLocal,
    endTimeLocal,
    clientId,
    clientName,
    location,
    grades = [],
    primaryContact,
    payment,
    description = '',
    actionableItems = [],
    blockDetails = null,
    isRunningLate = false,
    tentativeGroupDetails = null,
    tentativeFillStatus,
  } = {}) {
    super({
      id,
      startTimeLocal,
      endTimeLocal,
      clientId,
      clientName,
      location,
      grades,
      primaryContact,
      payment,
      description,
      actionableItems,
      blockDetails,
      isRunningLate,
      tentativeGroupDetails,
    })

    /**
     * @type {ShortTermTentativeStatus} Status of the tentative fill
     */
    this.tentativeFillStatus = tentativeFillStatus
  }
}
