import Vue from 'vue'
import VueRouter from 'vue-router'
// https://github.com/declandewet/vue-meta
import VueMeta from 'vue-meta'
// Adds a loading bar at the top during page loads.
import NProgress from 'nprogress/nprogress'
import routes from './routes'
import store from '@/store'
import dayjs from '@/services/date'
import { decideRouteBasedOnFeatureToggles } from '@/services/features/featureDecisions.js'
import routeDefinitions from '@/constants/routes/routeDefinitions'

Vue.use(VueRouter)
Vue.use(VueMeta, {
  // The component option name that vue-meta looks for meta info on.
  keyName: 'metaInfo',
})

const router = new VueRouter({
  routes,
  // Use the HTML5 history API (i.e. normal-looking routes)
  // instead of routes with hashes (e.g. example.com/#/about).
  // This may require some server configuration in production:
  // https://router.vuejs.org/en/essentials/history-mode.html#example-server-configurations
  mode: 'history',
  // Simulate native-like scroll behavior when navigating to a new
  // route and using back/forward buttons.
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition
    } else {
      return { x: 0, y: 0 }
    }
  },
})

const startRouteLoading = () => {
  // Begin loading animation. Only really required for initial page loads/refreshes.
  if (!store.getters.hasLoadedAppOnce) {
    store.dispatch('startLoadingApp')
  }

  // Only display the top loading bar after initial load
  if (store.getters.hasLoadedAppOnce) NProgress.start()
}
const stopRouteLoading = () => {
  // Prevents full page loader showing up on every route change
  if (store.getters.isLoadingApp) {
    store.dispatch('setAppAsLoaded')
  }

  // Complete the full page loading animation
  store.dispatch('finishLoadingApp')
  NProgress.done()
}

const shouldBypassLoadProfileErrorFlow = (errorCode) => {
  const codesToAbortRouting = ['account_suspended']
  return codesToAbortRouting.includes(errorCode)
}

// Before each route evaluates...
router.beforeEach(async (routeTo, routeFrom, next) => {
  // Check if auth is required on this route
  // (including nested routes).
  const isPublic = routeTo.matched.some((route) => route.meta.public)

  // If auth isn't required for the route, just continue.
  if (isPublic) return next()

  startRouteLoading()

  // If auth is required and the user isn't logged in...
  if (!store.getters['auth/isUserLoggedIn']) {
    // Retrieve another access token...
    try {
      await store.dispatch('auth/refreshToken')
    } catch (ex) {
      return redirectToLogin()
    }
  }

  // Get user's profile if not already set or isn't fresh
  if (!store.getters['auth/currentUser']) {
    try {
      const loadUserProfileResult = await store.dispatch(
        'auth/getCurrentUserProfile'
      )

      if (!loadUserProfileResult.isSuccess) {
        if (
          shouldBypassLoadProfileErrorFlow(loadUserProfileResult?.error?.code)
        )
          return

        throw new Error("Could not load user's profile")
      }

      // Set locale loaded in with profile
      await store.dispatch('setLocale', loadUserProfileResult.data.language)
    } catch (ex) {
      await store.dispatch('logException', ex)
      return redirectToErrorPage(routeDefinitions.accountLoadFailure.name)
    }
  }

  // If auth is required and the user is NOT currently logged in,
  // redirect to login.
  if (
    !store.getters['auth/isUserLoggedIn'] ||
    !store.getters['auth/currentUser']
  )
    redirectToLogin()

  // Due to notifications being available in every authenticated page
  // the overview bookings will be loaded here to ensure notifications will
  // appear on all pages.
  // TODO: Remove this once notification endpoint has been created
  store.dispatch(
    'bookings/loadOverviewBookingsByTargetDateAsync',
    { root: true },
    dayjs()
  )

  return decideRouteBasedOnFeatureToggles(
    store.getters['features/featureToggles'],
    routeTo,
    next
  )

  function redirectToLogin() {
    stopRouteLoading()
    // Pass the original route to the login component
    next({
      name: routeDefinitions.login.name,
      query: { redirectFrom: routeTo.fullPath },
    })
  }

  function redirectToErrorPage(errorPageName = 'ErrorPage') {
    stopRouteLoading()
    next({ name: errorPageName, params: [routeTo.path], replace: true })
  }
})

router.beforeResolve(async (routeTo, routeFrom, next) => {
  // Create a `beforeResolve` hook, which fires whenever
  // `beforeRouteEnter` and `beforeRouteUpdate` would. This
  // allows us to ensure data is fetched even when params change,
  // but the resolved route does not. We put it in `meta` to
  // indicate that it's a hook we created, rather than part of
  // Vue Router (yet?).
  try {
    // For each matched route...
    for (const route of routeTo.matched) {
      await new Promise((resolve, reject) => {
        // If a `beforeResolve` hook is defined, call it with
        // the same arguments as the `beforeEnter` hook.
        if (route.meta && route.meta.beforeResolve) {
          route.meta.beforeResolve(routeTo, routeFrom, (...args) => {
            // If the user chose to redirect...
            if (args.length) {
              // If redirecting to the same route we're coming from...
              if (routeFrom.name === args[0].name) {
                // Complete the animation of the route progress bar.
                NProgress.done()
              }
              // Complete the redirect.
              next(...args)
              reject(new Error('Redirected'))
            } else {
              resolve()
            }
          })
        } else {
          // Otherwise, continue resolving the route.
          resolve()
        }
      })
    }
    // If a `beforeResolve` hook chose to redirect, just return.
  } catch (error) {
    return
  }

  // If we reach this point, continue resolving the route.
  next()
})

// When each route is finished evaluating...
router.afterEach((routeTo, routeFrom) => {
  // Complete the animation of the route progress bar.
  stopRouteLoading()
})

export default router
