import WorkExperienceDotPointDTO from './workExperienceDotPointDTO'

/**
 * @class
 * @public
 */
export default class WorkExperienceDotPointWithIdDTO extends WorkExperienceDotPointDTO {
  /**
   * @constructor
   */
  constructor({ id, type, value = null } = {}) {
    super({ type, value })

    /**
     * @type {Number}
     */
    this.id = id
  }
}
