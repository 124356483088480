import { fail, success } from '@/helpers/result-helper.js'
import { isCacheFresh } from '@/helpers/cache-helpers'
import DurationUnits from '@/constants/date/DurationUnits.js'
import toast from '@/services/toasts/index.js'
import dayjs from '@/services/date/index.js'

const getDefaultState = () => {
  return {
    // Place any new state properties here
    subjects: [],
    loadingCount: 0,
    crudLoadingCount: 0,
  }
}

const state = getDefaultState()

export default {
  namespaced: true,
  state,
  getters: {
    moduleName: () => 'subjects',
    subjects: (state) => state.subjects?.list,
    isLoading: (state) => state.loadingCount > 0,
    isLoadingCRUD: (state) => state.crudLoadingCount > 0,
  },
  mutations: {
    START_LOADING(state) {
      state.loadingCount++
    },
    FINISH_LOADING(state) {
      state.loadingCount--
    },
    START_LOADING_CRUD(state) {
      state.crudLoadingCount++
    },
    FINISH_LOADING_CRUD(state) {
      state.crudLoadingCount--
    },
    SET_SUBJECTS(state, subjects) {
      state.subjects = {
        list: subjects,
        lastUpdated: dayjs(),
      }
    },
    CLEAR_STORE(state) {
      // Resets store to default state
      Object.assign(state, getDefaultState())
    },
  },
  actions: {
    /**
     * @param {*} param0
     * @param {Boolean} forceRefresh
     * @returns {Promise<ResultDTO>}
     */
    async getSubjectsList({ commit, getters, dispatch }, forceRefresh) {
      // 1. Check if we already have the list cached
      if (
        isCacheFresh({
          cacheDuration: 1,
          durationUnits: DurationUnits.HOUR,
          lastUpdated: state.subjects?.lastUpdated,
          forceRefresh,
        })
      )
        return success({ data: getters.subjects })

      // 2. Fetch list from api
      commit('START_LOADING')

      try {
        const response = await this.$api.subjects.get()
        commit('SET_SUBJECTS', response.data)
        return success({ data: getters.subjects })
      } catch (ex) {
        toast.error(this.$i18n.t('subjects.errorLoadSubjects'))
        return fail({
          error: await dispatch('logException', ex, { root: true }),
        })
      } finally {
        commit('FINISH_LOADING')
      }
    },
    /**
     * Resets store to default state.
     */
    clear({ commit }) {
      commit('CLEAR_STORE')
    },
  },
}
