import { getType } from 'mime'
import config from '@/common/config'
import { fail, success } from '@/helpers/result-helper'
import toasts from '@/services/toasts/index'

const getDefaultState = () => {
  return {
    // Place any new state properties here
    loadingCount: 0,
    crudLoadingCount: 0,
  }
}

const state = getDefaultState()

export default {
  namespaced: true,
  state,
  getters: {
    moduleName: () => 'file',
    isLoading: (state) => state.loadingCount > 0,
    isLoadingCRUD: (state) => state.crudLoadingCount > 0,
  },
  mutations: {
    START_LOADING(state) {
      state.loadingCount++
    },
    FINISH_LOADING(state) {
      state.loadingCount--
    },
    START_LOADING_CRUD(state) {
      state.crudLoadingCount++
    },
    FINISH_LOADING_CRUD(state) {
      state.crudLoadingCount--
    },
    CLEAR_STORE(state) {
      // Resets store to default state
      Object.assign(state, getDefaultState())
    },
  },
  actions: {
    /**
     * @param {*} param0
     * @param {Number} fileId
     * @returns {Promise<ResultDTO>}
     */
    async download({ commit, dispatch }, fileId) {
      commit('START_LOADING')
      try {
        const response = await this.$api.file.createFileAccessToken(fileId)

        const token = response.data.accessKey
        const baseURL = `${config.get('apiUrl')}v${config.get('apiVersion')}`
        const fileURL = `${baseURL}/file/(${token})`

        await this.$api.file.get(`(${token})`)
        window.location = fileURL
        return success()
      } catch (ex) {
        toasts.error('Download Failed')
        return fail({
          error: await dispatch('logException', ex, { root: true }),
        })
      } finally {
        commit('FINISH_LOADING')
      }
    },
    /**
     * @param {*} param0
     * @param {String} filePath
     * @returns {Promise<ResultDTO>}
     */
    async downloadGeneralFile({ commit, dispatch }, filePath) {
      commit('START_LOADING')
      try {
        const response = await this.$api.file.getGeneralFile(filePath)

        const url = window.URL.createObjectURL(
          new Blob([response.data], { type: getType(filePath) })
        )
        return success({ data: url })
      } catch (ex) {
        let toastErrorMessage = this.$i18n.t('error.errorGenericApiErrorText')
        // Try to resolve error response. Response type is Blob, need to convert
        // from blob to json
        try {
          const responseObject = JSON.parse(await ex.response.data.text())
          if (responseObject) toastErrorMessage = responseObject.message
        } catch {}

        toasts.error(toastErrorMessage)

        return fail({
          error: await dispatch('logException', ex, { root: true }),
        })
      } finally {
        commit('FINISH_LOADING')
      }
    },
  },
}
