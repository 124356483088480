/**
 * A simplified view model to describe address information
 * @class
 * @public
 */
export default class CandidateBookingSummaryLocationAddressViewModel {
  /**
   * @param {{ fullAddress: string, latitude: number, longitude: number, suburb: string }} param0
   */
  constructor({
    fullAddress = '',
    latitude = 0.0,
    longitude = 0.0,
    suburb = '',
  } = {}) {
    /**
     * The location's full address string. A combination of all address segements into a single string.
     * @type {string}
     */
    this.fullAddress = fullAddress
    /**
     * @type {number}
     */
    this.latitude = latitude
    /**
     * @type {number}
     */
    this.longitude = longitude
    /**
     * @type {string}
     */
    this.suburb = suburb
  }
}
