/* eslint-disable no-unused-vars */
import ConsultantViewModel from '../contact/consultantViewModel'
import R2WAvailabilityStatusViewModel from '../availability/r2WAvailabilityStatusViewModel'
import RegistrationSummaryViewModel from '../registration/registrationSummaryViewModel'

/**
 * @class
 * @public
 */
export default class CandidateUserViewModel {
  /**
   * @constructor
   */
  constructor({
    id,
    email,
    firstName,
    preferredName,
    lastName,
    fullName,
    associatedConsultant = null,
    language = 'en',
    ready2WorkStatus = null,
    registrations = null,
    timezone = '',
  } = {}) {
    /**
     * @type {Number}
     * @example 8675309
     */
    this.id = id

    /**
     * @type {String}
     * @example mike@nolan.com
     */
    this.email = email ?? ''

    /**
     * @type {String}
     * @example Mike
     */
    this.firstName = firstName ?? ''

    /**
     * @type {String}
     * @example Nolezy
     */
    this.preferredName = preferredName ?? ''

    /**
     * @type {String}
     * @example Nolan
     */
    this.lastName = lastName ?? ''

    /**
     * A pre-concatinated string of the first and last name. If preferred name
     * is set, it will be displayed instead of the first name.
     * @type {String}
     * @example Nolezy Nolan
     */
    this.fullName = fullName ?? ''

    /**
     * The candidate's associated consultant's contact details
     * @type {ConsultantViewModel}
     */
    this.associatedConsultant = associatedConsultant
      ? new ConsultantViewModel(associatedConsultant)
      : null

    /**
     * ISO 639-1 standard language code
     * @type {String}
     * @example en-AU
     */
    this.language = language

    /**
     * @type {R2WAvailabilityStatusViewModel}
     */
    this.ready2WorkStatus = ready2WorkStatus
      ? new R2WAvailabilityStatusViewModel(ready2WorkStatus)
      : null

    /**
     * @type {Array<RegistrationSummaryViewModel>}
     */
    this.registrations =
      registrations && registrations.length > 0
        ? registrations?.map(
            (registration) => new RegistrationSummaryViewModel(registration)
          )
        : []

    /**
     * IANA timezone string
     * @type {String}
     * @example Australia/Melbourne
     */
    this.timezone = timezone
  }
}
