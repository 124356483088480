import Vue from 'vue'
import VueMq from 'vue-mq'
import MediaQueryBreakPoints from '@/constants/core/MediaQueryBreakPoints'

Vue.use(VueMq, {
  breakpoints: {
    xs: MediaQueryBreakPoints.xs.size,
    sm: MediaQueryBreakPoints.sm.size,
    md: MediaQueryBreakPoints.md.size,
    lg: MediaQueryBreakPoints.lg.size,
    xl: MediaQueryBreakPoints.xl.size,
  },
})
