export default Object.freeze({
  /**
   * closed due to it being actioned after the fact.
   */
  CLOSED_TENTATIVE_OPEN_BUT_BOOKING_NOT_105:
    'CLOSED_TENTATIVE_OPEN_BUT_BOOKING_NOT_105',
  CLOSED_STATUS_WRONG_CANDIDATE: 'CLOSED_STATUS_WRONG_CANDIDATE',
  CLOSED_STATUS_UNABLED_TO_LOAD: 'CLOSED_STATUS_UNABLED_TO_LOAD',
  CLOSED_STATUS_NOT_105: 'CLOSED_STATUS_NOT_105',
  REJECTED: 'REJECTED',
  OPEN: 'OPEN',
  ACCEPTED: 'ACCEPTED',
})
