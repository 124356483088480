import SignalRApiService from './SignalRApiService'
import UserApiService from './UserApiService'
import FileApiService from './FileApiService'
import RegistrationApiService from './RegistrationsApiService'
import AvailabilityApiService from './AvailabilityApiService'
import Ready2WorkApiService from './Ready2WorkApiService'
import DocumentsApiService from './DocumentsApiService'
import QualificationsApiService from './QualificationsApiService'
import WorkExperienceApiService from './WorkExperienceApiService'
import CertificationsApiService from './CertificationsApiService'
import SubjectsApiService from './SubjectsApiService'
import BookingsApiService from './BookingsApiService'
import AgreementsApiService from './AgreementsApiService'
import DownloadsApiService from './DownloadsApiService'

import EventsApiService from './EventsApiService'
import ProfessionalDevelopmentApiService from './ProfessionalDevelopmentApiService'
export default {
  professionalDevelopment: new ProfessionalDevelopmentApiService(),
  events: new EventsApiService(),
  bookings: new BookingsApiService(),
  subjects: new SubjectsApiService(),
  workExperience: new WorkExperienceApiService(),
  qualifications: new QualificationsApiService(),
  registrations: new RegistrationApiService(),
  documents: new DocumentsApiService(),
  file: new FileApiService(),
  user: new UserApiService(),
  signalR: new SignalRApiService(),
  availability: new AvailabilityApiService(),
  ready2Work: new Ready2WorkApiService(),
  certifications: new CertificationsApiService(),
  agreements: new AgreementsApiService(),
  downloads: new DownloadsApiService(),
}
