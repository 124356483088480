import Vue from 'vue'
import { POSITION } from 'vue-toastification'

// https://github.com/Maronato/vue-toastification/tree/main#toast-options-object
const toastOptions = {
  duration: 5000,
  position: POSITION.BOTTOM_CENTER,
}

export default {
  success(message, options = toastOptions) {
    Vue.prototype.$toast.success(message, options)
  },
  error(message, options = toastOptions) {
    Vue.prototype.$toast.error(message, options)
  },
  warning(message, options = toastOptions) {
    Vue.prototype.$toast.warning(message, options)
  },
  info(message, options = toastOptions) {
    Vue.prototype.$toast.info(message, options)
  },
  show(message, options = toastOptions) {
    Vue.prototype.$toast(message, options)
  },
  debug(message, options = toastOptions) {
    Vue.prototype.$toast(message, options)
  },
  clear() {
    Vue.prototype.$toast.clear()
  },
}
