import { AvailabilityStatus } from '@/constants/candidate/AvailabilityStatus'

export default class ScheduledAvailabilityViewModel {
  constructor({
    dateLocal = null,
    status = AvailabilityStatus.UNSET,
    partialRanges = [],
  } = {}) {
    /**
     * @type {Date}
     */
    this.dateLocal = dateLocal
    /**
     * @type {AvailabilityStatus}
     */
    this.status = status
    /**
     * @type {ScheduledAvailabilityPartialRangeViewModel[]}
     */
    this.partialRanges = partialRanges
  }
}
