/* eslint-disable no-unused-vars */
import CandidateBookingNavigationViewModel from './candidateBookingNavigationViewModel'
import $dayjs from '@/services/date/index.js'

export default class CandidateBookingNavigationDTO {
  constructor({
    bookingId,
    navigationItems = new CandidateBookingNavigationViewModel(),
    lastUpdated = $dayjs(),
  } = {}) {
    /**
     * @type {Number}
     */
    this.bookingId = bookingId

    /**
     * @type {CandidateBookingNavigationViewModel}
     */
    this.navigationItems = navigationItems

    /**
     * @type {Date}
     */
    this.lastUpdated = lastUpdated
  }
}
