/**
 * List of units available from https://day.js.org/docs/en/display/difference
 */
export default Object.freeze({
  DAY: 'd',
  WEEK: 'w',
  QUARTER: 'Q',
  MONTH: 'M',
  YEAR: 'y',
  HOUR: 'h',
  MINUTE: 'm',
  SECOND: 's',
  MILLISECOND: 'ms',
})
