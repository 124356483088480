export default Object.freeze({
  /**
   * Initial state
   */
  unset: 'unset',
  /**
   * Candidate accepts tentative placements
   */
  accept: 'accept',
  /**
   * Candidate declines invite to tentative placements
   */
  decline: 'decline',
})
