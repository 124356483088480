export default Object.freeze({
  /**
   * Candidate is tentatively placed in the booking
   */
  tentative: 'tentative',
  /**
   * Candidate is required to sign off on 1 or more agreements before taking the booking
   */
  agreementsPending: 'agreementsPending',
  /**
   * This booking is in the past and the candidate is required to fill in timesheeting information
   */
  confirmDetailsRequired: 'confirmDetailsRequired',
  /**
   * This booking is in the past, timesheeting is either disabled or they have confirmed the booking details, and the user can now provide feedback on the booking
   */
  feedbackRequired: 'feedbackRequired',
  /**
   * Will display a UI element that allows the candidate to notify client they will be running late
   */
  runningLate: 'runningLate',
  /**
   * Button to get directions to the location
   */
  getDirections: 'getDirections',
  /**
   * No bookings were scheduled, this will allow the user to set their availability for a future or present date
   */
  availability: 'availability',

  // CUSTOM - Will not be sent back by API

  /**
   * Custom actionable item. Used to tell UI that user has accepted tentative booking
   */
  tentativeAccepted: 'tentativeAccepted',
  /**
   * Custom actionable item. Used to tell UI that user has declined tentative booking
   */
  tentativeDeclined: 'tentativeDeclined',
})
