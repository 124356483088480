import { getType } from 'mime'
import { extractFileNameFromContentDisposition } from '@helpers/file-helpers'
import { fail, success } from '@/helpers/result-helper.js'
import toast from '@/services/toasts/index.js'

const getDefaultState = () => {
  return {
    // Place any new state properties here
    loadingListCount: 0,
    loadingCount: 0,
    crudLoadingCount: 0,
  }
}

const state = getDefaultState()

export default {
  namespaced: true,
  state,
  getters: {
    moduleName: () => 'downloads',
    isLoadingList: (state) => state.loadingListCount > 0,
    isLoading: (state) => state.loadingCount > 0,
    isLoadingCRUD: (state) => state.crudLoadingCount > 0,
  },
  mutations: {
    START_LOADING_LIST(state) {
      state.loadingListCount++
    },
    FINISH_LOADING_LIST(state) {
      state.loadingListCount--
    },
    START_LOADING(state) {
      state.loadingCount++
    },
    FINISH_LOADING(state) {
      state.loadingCount--
    },
    START_LOADING_CRUD(state) {
      state.crudLoadingCount++
    },
    FINISH_LOADING_CRUD(state) {
      state.crudLoadingCount--
    },
    CLEAR_STORE(state) {
      // Resets store to default state
      Object.assign(state, getDefaultState())
    },
  },
  actions: {
    /**
     * Retrieves a list of application general files that are relevant to the user
     * @param {Object} VuexAction
     * @returns {Promise<ResultDTO>} List of objects containing file id and friendly name
     */
    async getAppGeneralFileList({ commit, dispatch }) {
      commit('START_LOADING_LIST')
      try {
        return await this.$api.downloads.getAppGeneralFileList()
      } catch (ex) {
        toast.error(this.$i18n.t('error.errorGeneralFileListLoadFailureText'))
        return fail({
          error: await dispatch('logException', ex, { root: true }),
        })
      } finally {
        commit('FINISH_LOADING_LIST')
      }
    },
    /**
     * Downloads blob of the application general file
     * @param {Object} VuexAction
     * @param {Number} fileId Id of the application general file
     * @returns {Promise<ResultDTO>} Blob of the application general file
     */
    async downloadAppGeneralFile({ commit, dispatch }, fileId) {
      commit('START_LOADING')
      try {
        const response = await this.$api.downloads.downloadAppGeneralFile(
          fileId
        )
        const fileName = extractFileNameFromContentDisposition(
          response.headers['content-disposition']
        )
        const blob = new Blob([response.data], {
          type: getType(fileName),
        })
        return success({ data: blob })
      } catch (ex) {
        toast.error(this.$i18n.t('error.unableToLoadFile'))
        return fail({
          error: await dispatch('logException', ex, { root: true }),
        })
      } finally {
        commit('FINISH_LOADING')
      }
    },
    /**
     * Resets store to default state.
     */
    clear({ commit }) {
      commit('CLEAR_STORE')
    },
  },
}
