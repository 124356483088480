/**
 * Describes environment types related to process.env.NODE_ENV and some custom types
 */
export default Object.freeze({
  production: 'production',
  development: 'development',
  local: 'local',
  unit: 'unit',
  e2e: 'e2e',
})
