<script>
import PageLoaderQuotes from '@/components/layout/page-loader-quotes.vue'
import baseMixin from '@/mixins/baseMixin'
import * as animationData from '@/../public/assets/animations/pencil_loader_light.json'
import * as animationDataDark from '@/../public/assets/animations/pencil_loader_dark.json'
import Lottie from '@/components/general/lottie.vue'

export default {
  name: 'PageLoader',
  components: {
    Lottie,
    PageLoaderQuotes,
  },
  mixins: [baseMixin],
  props: {},
  data() {
    return {
      animationSpeed: 1,
    }
  },
  computed: {
    defaultOptions() {
      return {
        loop: true,
        autoplay: true,
        animationData: this.isDarkMode
          ? animationDataDark.default
          : animationData.default,
      }
    },
  },
  methods: {
    handleAnimation: function (anim) {
      this.anim = anim
    },
  },
}
</script>

<template>
  <v-flex class="text-center">
    <Lottie
      :options="defaultOptions"
      :height="250"
      :width="250"
      @animCreated="handleAnimation"
    />

    <h2 :class="[isSmallOrLower ? '' : 'mt-4']">
      <PageLoaderQuotes />
    </h2>
  </v-flex>
</template>
