import Vue from 'vue'
import Toast, { POSITION } from 'vue-toastification'
import 'vue-toastification/dist/index.css'

// Options: https://github.com/Maronato/vue-toastification/tree/main#plugin-registration-vueuse
const options = {
  position: POSITION.BOTTOM_CENTER,
}

Vue.use(Toast, options)
