<script>
import { mapActions, mapGetters } from 'vuex'
import baseMixin from '@/mixins/baseMixin'

export default {
  name: 'DebugLogDialog',
  mixins: [baseMixin],
  computed: {
    ...mapGetters({
      debugLog: 'debugLog',
    }),
  },
  methods: {
    ...mapActions({
      toggleDarkMode: 'toggleDarkMode',
    }),
  },
}
</script>

<template>
  <v-card class="debug-log__mono">
    <v-toolbar dark color="primary debug-log__mono">
      <v-btn icon dark @click="$emit('close')">
        <v-icon>mdi-close</v-icon>
      </v-btn>
      <v-toolbar-title>Debug Log</v-toolbar-title>
      <v-spacer></v-spacer>
    </v-toolbar>
    <v-list three-line subheader>
      <v-subheader>Controls</v-subheader>
      <v-list-item>
        <v-list-item-action>
          <a @click="toggleDarkMode">
            {{ isDarkMode ? '[x]' : '[ ]' }}
          </a>
        </v-list-item-action>
        <v-list-item-content>
          <v-list-item-title>Dark Mode</v-list-item-title>
          <v-list-item-subtitle> Enable Dark Mode </v-list-item-subtitle>
        </v-list-item-content>
      </v-list-item>
    </v-list>
    <v-divider></v-divider>
    <v-list three-line subheader>
      <v-subheader>Log</v-subheader>

      <v-list-item v-for="logItem in debugLog" :key="logItem.id">
        <v-list-item-content>
          <v-list-item-subtitle>{{ logItem.date }}</v-list-item-subtitle>

          {{ logItem.message }}
        </v-list-item-content>
      </v-list-item>
    </v-list>
  </v-card>
</template>

<style lang="scss" scoped>
.debug-log__mono {
  font-family: 'consolas', 'Azeret Mono', monospace !important;
  font-weight: 300 !important;
}
</style>
