/**
 * Describes the currency codes
 */
export default Object.freeze({
  Unknown: 'Unknown',
  AUD: 'AUD',
  USD: 'USD',
  GBP: 'GBP',
  EUR: 'EUR',
})
