import ShiftRecordErrorAlertType from '@/constants/booking/ShiftRecordErrorAlertType'
import InteractionType from '@/constants/core/InteractionType'
import VuetifyColourClass from '@/constants/core/VuetifyColourClass'
import ShiftRecordErrorDTO from '@/models/booking/shiftRecord/shiftRecordErrorDTO'
import ActionButton from '@/models/general/actionButton'
import Interaction from '@/models/general/interaction'

/**
 * Determines the error message and action type based on the failure response from the server
 * @param { { data: Object, status: Number } } response
 * @param {{ t: Function }} i18n
 * @returns
 */
const shiftRecordLoadErrorHandler = (response, i18n) => {
  const errorDto = new ShiftRecordErrorDTO({
    testId: response?.data?.code,
    title: i18n.t('bookings.shiftRecord.load.errors.unableToLoadTitle'),
    alertType: ShiftRecordErrorAlertType.statusBlock,
    actions: [
      new ActionButton({
        text: i18n.t('general.retryBtnText'),
        icon: 'mdi-refresh',
        testId: 'shift-record-dialog-submit-error-alert',
        attributes: {
          outlined: true,
          large: true,
          class: 'mt-6',
        },
        interaction: new Interaction({
          type: InteractionType.function,
          value: null,
        }),
      }),
    ],
  })

  switch (response.data.code) {
    case 'shift_record_booking_not_found':
      errorDto.alertIcon = 'mdi-help-circle'
      errorDto.alertStyle = VuetifyColourClass.warning
      break
  }

  const errorMessageKey = `bookings.shiftRecord.load.errors.${response.data.code}`

  const translationExists = i18n.t(errorMessageKey) !== errorMessageKey

  errorDto.message = translationExists
    ? i18n.t(errorMessageKey)
    : i18n.t('bookings.shiftRecord.load.errors.unhandledError', [
        response.data.code,
      ])

  return errorDto
}

export default shiftRecordLoadErrorHandler
