/**
 * Will attempt to map search params to a URL. This function returns a new URL object
 * @param {URL} url
 * @param {Object?} searchParams key value object { key: value, ... }
 * @returns {URL}
 */
const mapSearchParamsToURL = (url, searchParams) => {
  if (!searchParams) return url

  const urlWithParams = new URL(url)

  Object.keys(searchParams).forEach((key) => {
    urlWithParams.searchParams.set(key, searchParams[key])
  })

  return urlWithParams
}

/**
 * Constructs an api URL from the various url segements passed in
 * @param {String} basePath base path to the API including the trailing forward slash e.g. https://www.google.com/
 * @param {String|Number} apiVersion e.g. 1.0
 * @param {String?} resource e.g. bookings
 * @param {String} additionalPathSegments path segments without the leading forward slash '/'
 * @returns {URL}
 */
const buildAPIBasePath = (
  basePath,
  apiVersion,
  resource = '',
  additionalPathSegments = ''
) => {
  const baseWithAPIURL = `${basePath}${apiVersion ? `v${apiVersion}/` : ''}`
  const separateResourceAndPathSegementsWithSlash =
    resource && additionalPathSegments
  const resourceWithAdditionalPathSegments = `${resource || ''}${
    separateResourceAndPathSegementsWithSlash ? '/' : ''
  }${additionalPathSegments || ''}`

  return new URL(resourceWithAdditionalPathSegments, baseWithAPIURL)
}

/**
 * Builds out a URL with optional search params
 * @param {String|URL} basePath Full path of the url without the search params e.g. https://www.google.com/images/search
 * @param {Object?} searchParams key value object { key: value, ... }
 * @returns {URL}
 */
const buildURL = (basePath, searchParams = null) => {
  const url = new URL(basePath)
  return mapSearchParamsToURL(url, searchParams)
}

export { mapSearchParamsToURL, buildURL, buildAPIBasePath }
