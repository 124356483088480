import { isSuccess } from '@helpers/http-status-helpers'
import { success, fail } from '@helpers/result-helper'
import toast from '@services/toasts/index.js'
import { getType } from 'mime'
import { extractFileNameFromContentDisposition } from './file-helpers'

/**
 * This class is intended for use inside vuex actions.
 * The only required methods are the request and go methods which will execute any function and go starts the operation
 * All requests are wrapped in try catch so the caller need not worry.
 * All other methods return the same instance which allows method chaining different configuration.
 *
 * @example
 * return await new VuexResponse(commit)
 *           .request(() => this.$api.locationRestriction.loadAllUpcomingAlerts())
 *           .withCommit("UPDATE_ALERTS")
 *           .go()
 */
export class VuexResponse {
  commit = null
  dispatch = null
  constructor(commit, dispatch) {
    this.commit = commit
    this.dispatch = dispatch
    return this
  }

  requestFn = null
  updateFn = null
  stateKey = null
  showSuccessToast = false
  showFailureToast = false
  successToastMessage = ''
  failToastMessage = ''
  startLoadFnName = ''
  finishLoadFnName = ''
  loadingKey = ''
  logApiResult = false
  isPaginated = false
  paginationKey = null
  extractContentDisposition = false
  transformFns = []
  returnStatusCode = false

  /**
   * Provide an api call to be called. Use an arrow function.
   * @param fn
   * @returns {VuexResponse}
   */
  request(fn) {
    this.requestFn = fn
    return this
  }

  /**
   * Provide the name of the commit update function to pass the request data to on successful request.
   * @param updateFnName
   * @returns {VuexResponse}
   */
  withCommit(updateFnName) {
    this.updateFn = updateFnName
    return this
  }

  withSetState(key) {
    this.stateKey = key
    return this
  }

  /**
   * This will enrich the response data with pagination information found in the x-pagination header of the response
   * @param commitKey - optionally provide the commitKey which is passed to the commit function, this can be used to cache the page results
   */
  withPagination(commitKey = null) {
    this.isPaginated = true
    this.paginationKey = commitKey
    return this
  }

  /**
   * This will convert the returned data into a blob with the correct content type and filename
   */
  withContentDisposition() {
    this.extractContentDisposition = true
    return this
  }

  /**
   * This will pass the status code from the response into the result helper, returning the true status instead of just a 200
   */
  withStatusCode() {
    this.returnStatusCode = true
    return this
  }

  /**
   * Pass in a transformation function such as map filter or reduce
   * Ensure you return the data in the same format.
   * This function can be called as many times as you like and will queue a list of transformation functions.
   * This will perform the transforms before committing the data
   * @param transformFn
   * @returns {VuexResponse}
   */
  transform(transformFn) {
    this.transformFns.push(transformFn)
    return this
  }

  /**
   * Enables a success toast if the request is successfull. Optionally provide a custom message
   * @param message
   * @returns {VuexResponse}
   */
  withSuccessToast(message = 'Successfully made change') {
    this.showSuccessToast = true
    if (message) {
      this.successToastMessage = message
    }
    return this
  }

  /**
   * Enables the failure toast. Optionally provide a message
   * @param message
   * @returns {VuexResponse}
   */
  withFailureToast(message = 'There was an error making the update') {
    this.showFailureToast = true
    if (message) {
      this.failToastMessage = message
    }
    return this
  }

  /**
   * Provide the commit function names for any custom loaders.
   * These are called at the start and end of the request
   * @param startName
   * @param finishName
   * @returns {VuexResponse}
   */
  withLoading(startName = 'START_LOADING', finishName = 'FINISH_LOADING') {
    this.startLoadFnName = startName
    this.finishLoadFnName = finishName
    return this
  }

  /**
   * Provide the global loading key to track the loading state of this request.
   * These are called at the start and end of the request
   * @param {string} loadingKey
   * @returns {VuexResponse}
   */
  withLoadingByKey(loadingKey) {
    if (!loadingKey)
      throw new Error('Vuex Action Builder: Loading key required')
    this.loadingKey = loadingKey
    return this
  }

  /**
   * For help debugging. Will log the success data from the response.
   * @returns {VuexResponse}
   */
  logResult() {
    this.logApiResult = true
    return this
  }

  hasLoadingFns() {
    return !!this.startLoadFnName && !!this.startLoadFnName
  }

  hasLoadingKey() {
    return !!this.loadingKey
  }

  showLoading() {
    return this.hasLoadingFns() || this.hasLoadingKey()
  }

  startLoading() {
    if (!this.showLoading()) return

    if (this.hasLoadingFns()) {
      this.commit(this.startLoadFnName)
    } else if (this.hasLoadingKey()) {
      this.commit('START_LOADING_BY_KEY', this.loadingKey, { root: true })
    }
  }

  finishLoading() {
    if (!this.showLoading()) return

    if (this.hasLoadingFns()) {
      this.commit(this.finishLoadFnName)
    } else if (this.hasLoadingKey()) {
      this.commit('FINISH_LOADING_BY_KEY', this.loadingKey, { root: true })
    }
  }

  /**
   * Starts the built operation.
   * @returns {Promise<{data: null, message: string, errors: [], isSuccess: boolean, statusCode: null}>}
   */
  async go() {
    if (!this.commit) {
      console.error('The commit function has not been passed to the helper.')
    }

    this.startLoading()

    try {
      const response = await this.requestFn()

      if (this.logApiResult) {
        // eslint-disable-next-line no-console
        console.info(response)
      }

      if (isSuccess(response.statusCode)) {
        let data = response.data
        const status = response.statusCode

        while (this.transformFns.length) {
          const transformer = this.transformFns.shift()
          data = transformer(data)
        }

        if (this.extractContentDisposition) {
          const fileName = extractFileNameFromContentDisposition(
            response.headers['content-disposition']
          )
          data = new Blob([response.data], {
            type: getType(fileName),
          })

          data = { blob: data, fileName }
        }

        if (this.isPaginated) {
          data = this.enrichResponseDataWithPagination(response)
          if (this.paginationKey) data = { ...data, key: this.paginationKey }
        }

        // Map response from server
        if (this.updateFn) {
          this.commit(this.updateFn, data)
        }

        if (this.stateKey) {
          this.commit('SET_STATE', {
            key: this.stateKey,
            value: response.data,
          })
        }

        if (this.showSuccessToast) {
          toast.success(this.successToastMessage)
        }

        return success({
          data,
          message: '',
          statusCode: this.returnStatusCode ? status : undefined,
        })
      }
    } catch (e) {
      let error = e.response

      if (this.dispatch) {
        error = await this.dispatch('logException', e, { root: true })
      } else {
        console.error(e)
      }

      if (this.showFailureToast) {
        const message = e.response.data?.error || this.failToastMessage
        toast.error(message)
      }

      return fail({
        error,
        statusCode: this.returnStatusCode ? e.response?.status : undefined,
      })
    } finally {
      this.finishLoading()
    }
  }

  enrichResponseDataWithPagination(response) {
    return {
      ...JSON.parse(response.headers['x-pagination']),
      data: response.data,
    }
  }
}
