import { render, staticRenderFns } from "./_error.vue?vue&type=template&id=4b274bc2&scoped=true&"
import script from "./_error.vue?vue&type=script&lang=js&"
export * from "./_error.vue?vue&type=script&lang=js&"
import style0 from "./_error.vue?vue&type=style&index=0&id=4b274bc2&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4b274bc2",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VIcon } from 'vuetify/lib/components/VIcon';
installComponents(component, {VIcon})
