/* eslint-disable no-unused-vars */
import AccessGuiFileViewModel from '../file/accessGuiFileViewModel'
import WorkExperienceDotPointWithIdDTO from './workExperienceDotPointWithIdDTO'
import dayjs from '@/services/date/index'

/**
 * @class
 * @public
 */
export default class WorkExperienceDetailsVM {
  /**
   * @constructor
   */
  constructor({
    id,
    placeOfWork = '',
    positionHeld = '',
    dateFrom = null,
    dateUntil = null,
    details = '',
    dotPoints = [],
    isCurrent = false,
    isVerified = false,
    files = [],
    createdDate = null,
    updatedDate = null,
  } = {}) {
    /**
     * @type {Number}
     */
    this.id = id

    /**
     * @type {String}
     */
    this.placeOfWork = placeOfWork ?? ''

    /**
     * @type {String}
     */
    this.positionHeld = positionHeld ?? ''

    /**
     * @type {Date}
     */
    this.dateFrom = dateFrom ? dayjs(dateFrom) : null

    /**
     * Will be null if isCurrent is true
     * @type {Date}
     */
    this.dateUntil = dateUntil ? dayjs(dateUntil) : null

    /**
     * A short summary of this position written by the candidate
     * @type {String}
     */
    this.details = details ?? ''

    /**
     * @type {Array<WorkExperienceDotPointWithIdDTO>}
     */
    this.dotPoints =
      dotPoints && dotPoints.length > 0
        ? dotPoints.map(
            (dotPoint) => new WorkExperienceDotPointWithIdDTO(dotPoint)
          )
        : []

    /**
     * Indicates if candidate is currently in role
     * @type {Boolean}
     */
    this.isCurrent = isCurrent

    /**
     * Indicates if this record has been verified by a consultant or by the system.
     * @type {Boolean}
     */
    this.isVerified = isVerified

    /**
     * @type {Array<AccessGuiFileViewModel>}
     */
    this.files =
      files && files.length > 0
        ? files.map((file) => new AccessGuiFileViewModel(file))
        : []

    /**
     * @type {Date}
     */
    this.createdDate = createdDate ? dayjs(createdDate) : null

    /**
     * @type {Date}
     */
    this.updatedDate = updatedDate ? dayjs(updatedDate) : null
  }
}
