/* eslint-disable no-unused-vars */
import CertificationTypeFieldMetaVM from './certificationTypeFieldMetaVM'
import { isNonEmptyArray } from '@/helpers/array-helpers'

/**
 * @class
 * @public
 */
export default class CertificationTypeVM {
  /**
   * @constructor
   */
  constructor({ id, title = '', fieldMeta = [] } = {}) {
    /**
     * @type {Number}
     */
    this.id = id

    /**
     * @type {String}
     */
    this.title = title

    /**
     * @type {Array<CertificationTypeFieldMetaVM>}
     */
    this.fieldMeta = isNonEmptyArray(fieldMeta)
      ? fieldMeta.map((field) => new CertificationTypeFieldMetaVM(field))
      : []
  }
}
