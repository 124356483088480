import { fail } from '@helpers/result-helper'
import toast from '@/services/toasts/index.js'
import dayjs from '@/services/date'
/* eslint-disable no-unused-vars */
import CandidateBookingOverviewAvailabilityViewModel from '@/models/booking/candidateBookingOverviewAvailabilityViewModel'
import AvailabilityStatusDTO from '@/models/availability/availabilityStatusDTO'
import ScheduledAvailabilityViewModel from '@/models/availability/scheduledAvailabilityViewModel'
import OngoingAvailabilityViewModel from '@/models/availability/ongoingAvailabilityViewModel'
import { AvailabilityStatus } from '@/constants/candidate/AvailabilityStatus'
import { DayOfWeek } from '@/constants/date/DayOfWeek'
import { Ready2WorkStatus } from '@/constants/candidate/Ready2WorkStatus'
import { getSavedState, saveState } from '@/helpers/cache-helpers'
import AvailabilityCalendarSelectionMode from '@/constants/availability/AvailabilityCalendarSelectionMode'
import R2WAvailabilityStatusViewModel from '@/models/availability/r2WAvailabilityStatusViewModel'

const getDefaultState = () => {
  return {
    // Place any new state properties here
    loadingCount: 0,
    crudLoadingCount: 0,
    loadingOngoingAvailabilityCount: 0,
    scheduledAvailability: [],
    ongoingAvailability: null,
    availabilityCalendarSelectionMode: getSavedState(
      'availabilityCalendarSelectionMode'
    ),
  }
}

const state = getDefaultState()

export default {
  namespaced: true,

  /**
   * @typedef {{
   * loadingCount: Number
   * crudLoadingCount: Number
   * scheduledAvailability: ScheduledAvailabilityViewModel[]
   * ongoingAvailability: OngoingAvailabilityViewModel
   * }} AvailabilityState
   * @type {AvailabilityState}
   */
  state,
  getters: {
    moduleName: () => 'availability',
    isLoading: (state) => state.loadingCount > 0,
    isLoadingCRUD: (state) => state.crudLoadingCount > 0,
    isLoadingOngoingAvailability: (state) =>
      state.loadingOngoingAvailabilityCount > 0,
    scheduledAvailability: (state) => state.scheduledAvailability,
    ongoingAvailability: (state) => state.ongoingAvailability,
    getAvailabilityStatusByDate: (state) => (targetDate) => {
      const foundScheduledAvailability = state.scheduledAvailability.find((x) =>
        dayjs(x.dateLocal).isSame(dayjs(targetDate))
      )
      if (foundScheduledAvailability) {
        return foundScheduledAvailability
      }
      // read from ongoing availability if scheduled is not found
      const resultFromOngoing = new ScheduledAvailabilityViewModel({
        dateLocal: targetDate,
        status: null,
        partialRanges: [],
      })
      if (!state.ongoingAvailability) {
        resultFromOngoing.status = AvailabilityStatus.UNSET
        return resultFromOngoing
      }
      const dayOfWeek = dayjs(targetDate).day() // returns number that matches DayOfWeek enum
      switch (dayOfWeek) {
        case DayOfWeek.MONDAY:
          resultFromOngoing.status = state.ongoingAvailability.monday
          return resultFromOngoing
        case DayOfWeek.TUESDAY:
          resultFromOngoing.status = state.ongoingAvailability.tuesday
          return resultFromOngoing
        case DayOfWeek.WEDNESDAY:
          resultFromOngoing.status = state.ongoingAvailability.wednesday
          return resultFromOngoing
        case DayOfWeek.THURSDAY:
          resultFromOngoing.status = state.ongoingAvailability.thursday
          return resultFromOngoing
        case DayOfWeek.FRIDAY:
          resultFromOngoing.status = state.ongoingAvailability.friday
          return resultFromOngoing
        case DayOfWeek.SATURDAY:
          resultFromOngoing.status = state.ongoingAvailability.saturday
          return resultFromOngoing
        case DayOfWeek.SUNDAY:
          resultFromOngoing.status = state.ongoingAvailability.sunday
          return resultFromOngoing
        default:
          resultFromOngoing.status = AvailabilityStatus.UNSET
          return resultFromOngoing
      }
    },
    availabilityCalendarSelectionMode: (state) =>
      state.availabilityCalendarSelectionMode,
  },
  mutations: {
    START_LOADING(state) {
      state.loadingCount++
    },
    FINISH_LOADING(state) {
      state.loadingCount--
    },
    START_LOADING_CRUD(state) {
      state.crudLoadingCount++
    },
    FINISH_LOADING_CRUD(state) {
      state.crudLoadingCount--
    },
    START_LOADING_ONGOING_AVAILABILITY(state) {
      state.loadingOngoingAvailabilityCount++
    },
    FINISH_LOADING_ONGOING_AVAILABILITY(state) {
      state.loadingOngoingAvailabilityCount--
    },
    /**
     * Updates the scheduled availability in the store
     * @param {AvailabilityState} state
     * @param {ScheduledAvailabilityViewModel[]} scheduledAvailabiltyList
     */
    UPDATE_SCHEDULED_AVAILABILITY_MULTI(state, scheduledAvailabiltyList) {
      let foundScheduledAvailability

      for (const availability of scheduledAvailabiltyList) {
        foundScheduledAvailability = state.scheduledAvailability.find((x) =>
          dayjs(x.dateLocal).isSame(dayjs(availability.dateLocal))
        )

        if (!foundScheduledAvailability) {
          state.scheduledAvailability.push(availability)
        } else {
          Object.assign(foundScheduledAvailability, availability)
        }
      }
    },
    /**
     * Updates the scheduled availability in the store with new updates
     * @param {AvailabilityState} state
     * @param {AvailabilityStatusDTO} newAvailabilities
     */
    UPDATE_SCHEDULED_AVAILABITLITY_WITH_NEW_UPDATES(state, newAvailabilities) {
      let foundScheduledAvailability

      for (const date of newAvailabilities.selectedDates) {
        foundScheduledAvailability = state.scheduledAvailability.find((x) =>
          dayjs(x.dateLocal).isSame(dayjs(date))
        )

        const newScheduledAvailabilityVM = new ScheduledAvailabilityViewModel({
          dateLocal: date,
          status: newAvailabilities.status,
          partialRanges: newAvailabilities.partialRanges,
        })

        if (!foundScheduledAvailability) {
          state.scheduledAvailability.push(newScheduledAvailabilityVM)
        } else {
          Object.assign(foundScheduledAvailability, newScheduledAvailabilityVM)
        }
      }
    },
    UPDATE_ONGOING_AVAILABILITY(state, ongoingAvailability) {
      state.ongoingAvailability = ongoingAvailability
    },
    CLEAR_STORE(state) {
      // Resets store to default state
      Object.assign(state, getDefaultState())
    },
    SET_AVAILABILITY_SELECT_MODE_RANGE(state) {
      state.availabilityCalendarSelectionMode =
        AvailabilityCalendarSelectionMode.range
      saveState(
        'availabilityCalendarSelectionMode',
        AvailabilityCalendarSelectionMode.range
      )
    },
    SET_AVAILABILITY_SELECT_MODE_MULTISELECT(state) {
      state.availabilityCalendarSelectionMode =
        AvailabilityCalendarSelectionMode.multiselect
      saveState(
        'availabilityCalendarSelectionMode',
        AvailabilityCalendarSelectionMode.multiselect
      )
    },
  },
  actions: {
    /**
     * Updates the scheduled and ongoing availability that are brought in from overview booking
     * @param {object} VuexAction
     * @param {CandidateBookingOverviewAvailabilityViewModel} availabilty
     */
    async insertAvailability({ commit }, availabilty) {
      commit('UPDATE_SCHEDULED_AVAILABILITY_MULTI', availabilty.scheduled)
      commit('UPDATE_ONGOING_AVAILABILITY', availabilty.ongoing)
    },
    /**
     * Updates a candidate's availability for multiple days.
     * @param {object} VuexAction
     * @param {AvailabilityStatusDTO} newAvailabilities
     * @returns
     */
    async setAvailabilityStatus(
      { commit, dispatch, rootGetters },
      newAvailabilities
    ) {
      commit('START_LOADING')
      try {
        const response = await this.$api.availability.setAvailabilityStatus(
          newAvailabilities
        )

        let statusText = null
        switch (newAvailabilities.status) {
          case AvailabilityStatus.AVAILABLE:
            statusText = this.$i18n
              .t('availability.availableStatus')
              .toLowerCase()
            break
          case AvailabilityStatus.UNAVAILABLE:
            statusText = this.$i18n
              .t('availability.notAvailableStatus')
              .toLowerCase()
            break
          case AvailabilityStatus.PARTIAL:
            statusText = this.$i18n
              .t('availability.partialStatus')
              .toLowerCase()
            break
          default:
            statusText = this.$i18n
              .t('availability.undefinedStatus')
              .toLowerCase()
        }

        const successToastMessage = this.$i18n.t(
          'availability.successfulStatusChangeMessage',
          {
            newStatus: statusText,
            relevantDate:
              newAvailabilities.selectedDates.length > 1
                ? this.$i18n.t('availability.selectedDatesText').toLowerCase()
                : this.$i18n.d(
                    dayjs(newAvailabilities.selectedDates[0]),
                    'dateShort'
                  ),
          }
        )
        toast.success(successToastMessage)

        commit(
          'UPDATE_SCHEDULED_AVAILABITLITY_WITH_NEW_UPDATES',
          newAvailabilities
        )
        // update R2W if the date for availability contains active date
        const r2WStatusOnActiveDay =
          rootGetters['ready2Work/r2WStatusOnActiveDay']
        if (
          r2WStatusOnActiveDay &&
          r2WStatusOnActiveDay.ready2Work !== Ready2WorkStatus.UNSET &&
          newAvailabilities.selectedDates.some((date) =>
            dayjs(r2WStatusOnActiveDay.activeDateLocal).isSame(dayjs(date))
          )
        ) {
          const updatedR2WStatusOnActiveDay =
            new R2WAvailabilityStatusViewModel(r2WStatusOnActiveDay)
          updatedR2WStatusOnActiveDay.ready2Work = Ready2WorkStatus.UNSET
          await dispatch(
            'ready2Work/setReady2Work',
            updatedR2WStatusOnActiveDay,
            {
              root: true,
            }
          )
        }
        return response
      } catch (ex) {
        toast.error(this.$i18n.t('error.genericFailedRequestMessage'))
        return fail({
          error: await dispatch('logException', ex, { root: true }),
        })
      } finally {
        commit('FINISH_LOADING')
      }
    },
    async updateAvailability({ commit }, newScheduledAvailability) {
      commit('UPDATE_SCHEDULED_AVAILABILITY_MULTI', [newScheduledAvailability])
    },
    toggleAvailabilitySelectionMode({ commit, getters }) {
      getters.availabilityCalendarSelectionMode ===
      AvailabilityCalendarSelectionMode.multiselect
        ? commit('SET_AVAILABILITY_SELECT_MODE_RANGE')
        : commit('SET_AVAILABILITY_SELECT_MODE_MULTISELECT')
    },
    /**
     * Sets a candidate's ongoing/permanent availability
     * @param {*} param0
     * @param {OngoingAvailabilityViewModel} payload
     */
    async setOngoingAvailability({ commit, dispatch }, payload) {
      commit('START_LOADING_ONGOING_AVAILABILITY')
      try {
        const response = await this.$api.availability.setOngoingAvailability(
          payload
        )
        commit('UPDATE_ONGOING_AVAILABILITY', payload)
        return response
      } catch (ex) {
        toast.error(this.$i18n.t('error.genericFailedRequestMessage'))
        return fail({
          error: await dispatch('logException', ex, { root: true }),
        })
      } finally {
        commit('FINISH_LOADING_ONGOING_AVAILABILITY')
      }
    },
    /**
     * Resets store to default state.
     */
    clear({ commit }) {
      commit('CLEAR_STORE')
    },
  },
}
