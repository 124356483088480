/**
 * Describes a media query breakpoint
 */
export default class MediaQueryBreakPoint {
  constructor({ code = 'md', size = 0 } = {}) {
    /**
     * Break point code 'xs'|'sm'|'md'|'lg'|'xl'
     * @type {String}
     */
    this.code = code

    /**
     * Size of breakpoint
     * @type {Number}
     */
    this.size = size
  }
}
