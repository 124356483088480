// eslint-disable-next-line no-unused-vars
import Grade from '../client/grade'
import CandidateBookingSummaryLocationViewModel from './candidateBookingSummaryLocationViewModel'
import CandidateBookingSummaryPaymentViewModel from './candidateBookingSummaryPaymentViewModel'
import { isNonEmptyArray } from '@/helpers/array-helpers'
// eslint-disable-next-line no-unused-vars
import BookingActionableItemType from '@/constants/booking/BookingActionableItemType'

/**
 * @class
 * @public
 */
export default class CandidateBookingSummaryViewModel {
  /**
   * @param {{ id: number, location: CandidateBookingSummaryLocationViewModel, grades: Grade[], payment: CandidateBookingSummaryPaymentViewModel, startTimeLocal: Date, endTimeLocal: Date, actionableItems: BookingActionableItemType[], isRunningLate: Boolean }} param0
   */
  constructor({
    id = null,
    location = {},
    grades = [],
    payment = {},
    startTimeLocal = null,
    endTimeLocal = null,
    actionableItems = [],
    tentativeGroupBookingCount = 0,
    isRunningLate = false,
    isRunningLateEnabled = false,
    isShiftRecordSubmitted = false,
  } = {}) {
    /**
     * Booking Id
     * @type {number}
     */
    this.id = id
    /**
     * @type {CandidateBookingSummaryLocationViewModel}
     */
    this.location = new CandidateBookingSummaryLocationViewModel(location)
    /**
     * @type {Array<Grade>}
     */
    this.grades = isNonEmptyArray(grades)
      ? grades.map((grade) => new Grade(grade))
      : []
    /**
     * @type {CandidateBookingSummaryPaymentViewModel}
     */
    this.payment = new CandidateBookingSummaryPaymentViewModel(payment)
    /**
     * @type {Date}
     */
    this.startTimeLocal = startTimeLocal
    /**
     * @type {Date}
     */
    this.endTimeLocal = endTimeLocal
    /**
     * @type {Array<String>}
     * @see {BookingActionableItemType} for valid range of values
     */
    this.actionableItems = actionableItems
    /**
     * @type {Boolean}
     */
    this.isRunningLate = isRunningLate
    /**
     * @type {Boolean}
     */
    this.isRunningLateEnabled = isRunningLateEnabled
    /**
     * Indicates if a shiftRecord has already been submit for this booking.
     * @type {Boolean}
     */
    this.isShiftRecordSubmitted = isShiftRecordSubmitted
    /**
     * @type {Number}
     */
    this.tentativeGroupBookingCount = tentativeGroupBookingCount
  }
}
