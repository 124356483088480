/**
 * Used to identify errors thrown because a specific store resource
 * couldn't be found
 */
export default class StoreResourceNotFoundError extends Error {
  /**
   * @param {String} resourceName name of resource that wasn't found
   * @param {String} message replaces default message
   */
  constructor({ resourceName = null, message = null } = {}) {
    super(
      !message
        ? `Failed to find the following resource: '${resourceName}'`
        : message
    )
    this.name = 'StoreResourceNotFoundError'
  }
}
