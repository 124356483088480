export default Object.freeze({
  CandidatePhoto: 'CandidatePhoto',
  /**
   * Considered a document reference type
   */
  Certification: 'Certification',
  CheckBox: 'CheckBox',
  /**
   * Considered an agreement reference type
   */
  Contract: 'Contract',
  Form: 'Form',
  Identification100: 'Identification100',
  Qualification: 'Qualification',
  ReferenceCheckFormGroup: 'ReferenceCheckFormGroup',
  /**
   * Considered an agreement reference type
   */
  RegistrationDoc: 'RegistrationDoc',
  /**
   * Aka Work Experience
   */
  StatementsOfService: 'StatementsOfService',
  /**
   * Considered a document reference type
   */
  UploadedFile: 'UploadedFile',
  /**
   * Considered an agreement reference type
   */
  Video: 'Video',
  IDCheck: 'IDCheck',
})
