/**
 * Used to find and update the pending agreement status in overview/summary when actioned from details page
 */
export default class CandidateDocumentAgreementUpdateViewModel {
  constructor({ fileId = null, clientId = null, dateAgreedUTC = null } = {}) {
    /**
     * @type {Number}
     */
    this.fileId = fileId

    /**
     * @type {Number}
     */
    this.clientId = clientId

    /**
     * @type {Date}
     */
    this.dateAgreedUTC = dateAgreedUTC
  }
}
