/* eslint-disable no-unused-vars */
import { ModelApiService } from './BaseApiService'
import HttpMethods from '@/constants/api/HttpMethods'
import HttpRequestConfig from '@/models/api/httpRequestConfig'
import HttpRequestUrl from '@/models/api/httpRequestUrl'
import ResultDTO from '@/models/app/resultDTO'
import AccountCreationDTO from '@/models/user/accountCreationDTO'

export default class UserApiService extends ModelApiService {
  constructor() {
    super('me')
  }

  /**
   * Completes the candidate account setup step by adding a password to their account
   * @param {AccountCreationDTO} payload
   * @returns {Promise<ResultDTO>} A custom auth token that can be used to authenticate with Firebase
   */
  async accountSetup({ token, password }) {
    return this.execute(
      new HttpRequestConfig({
        method: HttpMethods.POST,
        url: new HttpRequestUrl({ subPath: 'account/setup' }),
        headers: {
          'x-candidate-account-setup-token': token,
        },
        data: { password },
        isAuthenticated: false,
      })
    )
  }
}
