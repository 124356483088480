<script>
import { mapActions } from 'vuex'
import baseMixin from '@/mixins/baseMixin'
import impersonateMixin from '@/mixins/impersonateMixin'
import TheDebugBarActions from '@/components/dev/the-debug-bar-actions.vue'

export default {
  name: 'TheImpersonateBar',
  components: { TheDebugBarActions },
  mixins: [baseMixin, impersonateMixin],
  computed: {
    compBarText() {
      return this.isMobileViewPort
        ? this.$t('impersonate.shortBarText')
        : this.$t('impersonate.barText')
    },
    compStopText() {
      return this.isMobileViewPort
        ? this.$t('impersonate.shortStopImpersonatingLinkText')
        : this.$t('impersonate.stopImpersonatingLinkText')
    },
  },
  methods: {
    ...mapActions({
      stopImpersonating: 'auth/stopImpersonating',
    }),
    signOut() {
      this.stopImpersonating()
    },
  },
}
</script>

<template>
  <v-system-bar
    v-if="isImpersonating"
    app
    dark
    window
    color="blue"
    class="d-flex justify-center text-center align-center white--text caption"
  >
    <header class="mr-2">
      <v-icon small color="white">mdi-incognito</v-icon>

      {{ compBarText }}
      <span class="font-weight-medium">
        {{ firstName }}
      </span>
    </header>
    |
    <section class="ml-2">
      <a class="white--text text-decoration-underline" @click="signOut">
        {{ compStopText }}
      </a>
    </section>

    <section v-if="isDev && !isMobileViewPort" class="ml-2">
      <TheDebugBarActions />
    </section>
  </v-system-bar>
</template>
