export const ValidationRules = Object.freeze({
  /**
   * Field is required
   */
  required: 'required',
  /**
   * Value cannot have more than specified amount of characters
   */
  maxCharacterLength: 'maxCharacterLength',
  /**
   * Expiry date must be after the obtained date
   */
  afterObtainedDate: 'afterObtainedDate',
})
