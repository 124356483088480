export const ErrorPageCodes = Object.freeze({
  /**
   * Server responded with 500 status code or some other unrecoverable error
   */
  INTERNAL_SERVER_ERROR: {
    code: '500',
  },
  /**
   *  Resource or page was not found
   */
  PAGE_NOT_FOUND: {
    code: '404',
  },
  /**
   * User is not authorized to access resource
   */
  UNAUTHORIZED: {
    code: '401',
  },
  /**
   * Generic page for bad request responses (400)
   */
  BAD_REQUEST: {
    code: '400',
  },
  /**
   * For scenarios when the user's account couldn't be retrieved from the server
   */
  ACCOUNT_LOAD_FAILURE: {
    code: 'accountLoadFailure',
  },
  /**
   * Candidate's account has been suspended/disabled
   */
  ACCOUNT_SUSPENDED: {
    code: 'accountSuspended',
  },
})
