<script>
import { shuffle } from 'lodash'
import baseMixin from '@/mixins/baseMixin'

export default {
  name: 'PageLoaderQuotes',
  mixins: [baseMixin],
  data() {
    return {
      quoteIndex: 0,
      quotes: [],
      currentQuote: '',
      show: false,
    }
  },
  computed: {
    quotesList() {
      const list = [
        this.$t('layout.pageLoaderQuotesOne'),
        this.$t('layout.pageLoaderQuotesTwo'),
        this.$t('layout.pageLoaderQuotesThree'),
        this.$t('layout.pageLoaderQuotesFour'),
        this.$t('layout.pageLoaderQuotesFive'),
        this.$t('layout.pageLoaderQuotesSix'),
        this.$t('layout.pageLoaderQuotesSeven'),
        this.$t('layout.pageLoaderQuotesEight'),
        this.$t('layout.pageLoaderQuotesNine'),
        this.$t('layout.pageLoaderQuotesTen'),
      ]

      return list
    },
  },
  mounted() {
    // Load quotes
    this.loadQuotes()
  },
  methods: {
    loadQuotes() {
      // Shuffle array
      this.quotes = shuffle(this.quotesList)
    },
  },
}
</script>

<template>
  <section class="wordCarousel">
    <div>
      <ul :class="['flip-text', isDarkMode ? 'dark' : '']">
        <li
          v-for="(quote, index) in quotes"
          :key="index"
          :class="[
            'content__container__list__item font-weight-bold',
            isDarkMode ? 'white--text' : '',
            isMobileViewPort ? 'text-subtitle-2' : 'headline',
          ]"
        >
          {{ quote }}
        </li>
      </ul>
    </div>
  </section>
</template>

<style lang="scss" scoped>
.wordCarousel {
  div {
    height: 45px;
    overflow: hidden;

    ul {
      box-shadow: inset 0 0 30px #fafafa, 0 0 30px #fafafa;

      &.dark {
        box-shadow: inset 0 0 30px #121212, 0 0 30px #121212;
      }

      li {
        display: block;
        height: 45px;
      }
    }
  }
}

.flip-text {
  padding-left: 0 !important;
  animation: flip-text 14s cubic-bezier(0.23, 1, 0.32, 1.2) infinite;
}

@keyframes flip-text {
  0% {
    margin-top: 0;
  }

  10% {
    margin-top: -45px;
  }

  20% {
    margin-top: -90px;
  }

  30% {
    margin-top: -135px;
  }

  40% {
    margin-top: -180px;
  }

  50% {
    margin-top: -225px;
  }

  60% {
    margin-top: -180px;
  }

  70% {
    margin-top: -135px;
  }

  80% {
    margin-top: -90px;
  }

  90% {
    margin-top: -45px;
  }

  100% {
    margin-top: 0;
  }
}
</style>
