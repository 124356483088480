/**
 * Code sourced from: https://github.com/latelierco/vue-application-insights/blob/master/src/index.js on 21/10/2022 3:24 PM
 * Adjusting the code to suit our needs and to ensure it conforms to the latest appinsights requirements, as the package
 * has not been maintained for some time.
 */

import { ApplicationInsights, Util } from '@microsoft/applicationinsights-web'
import MissingConfigurationError from '@/models/error/missingConfigurationError'

/**
 * Vue plugin for application insights package
 *
 * @param Vue
 * @param options
 */
function install(Vue, options) {
  // More info: https://github.com/microsoft/ApplicationInsights-JS#configuration
  const config = options.appInsightsConfig || null

  if (!config)
    throw new MissingConfigurationError({ resourceName: 'appInsightsConfig' })

  Vue.appInsights = new ApplicationInsights({ config })
  Vue.appInsights.loadAppInsights()
  Vue.appInsights.trackPageView()

  if (typeof options.onAfterScriptLoaded === 'function') {
    options.onAfterScriptLoaded(Vue.appInsights)
  }

  const router = options.router

  // Watch route event if router option is defined.
  if (router) {
    if (options.trackInitialPageView !== false) {
      setupPageTracking(options, Vue)
    } else {
      router.onReady(() => setupPageTracking(options, Vue))
    }
  }

  Object.defineProperty(Vue.prototype, '$appInsights', {
    get: () => Vue.appInsights,
  })
}

/**
 * Track route changes as page views with AppInsights
 * @param options
 */
function setupPageTracking(options, Vue) {
  const router = options.router

  const baseName = options.baseName || '(Vue App)'

  router.beforeEach((route, from, next) => {
    const name = baseName + ' / ' + route.name
    Vue.appInsights.context.telemetryTrace.traceID = Util.generateW3CId()
    Vue.appInsights.context.telemetryTrace.name = route.name
    Vue.appInsights.startTrackPage(name)
    next()
  })

  router.afterEach((route) => {
    const name = baseName + ' / ' + route.name
    const url = location.protocol + '//' + location.host + route.fullPath
    Vue.appInsights.stopTrackPage(name, url)
    Vue.appInsights.flush()
  })
}

// auto install for navigator
if (typeof window !== 'undefined' && window.Vue) {
  window.Vue.use(install)
}

export default install
