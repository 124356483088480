import dispatchActionForAllModules from '@utils/dispatch-action-for-all-modules'
import Vue from 'vue'
import Vuex from 'vuex'
import toast from '@services/toasts/index.js'
import { nanoid } from 'nanoid'
import modules from './modules'
import api from '@/plugins/api.storePlugin'
import i18n from '@/plugins/i18n.storePlugin'
import { getSavedState, saveState, deleteState } from '@/helpers/cache-helpers'
import config from '@/common/config'
import dayjs from '@/services/date'
import appInsights from '@/plugins/appinsights.storePlugin'
import { logger } from '@/services/logging/AppLogger'
import ErrorResponseFactory from '@/services/error/ErrorResponseFactory'
import { getStartOfWeekFromLocale } from '@/helpers/date-helpers'
import { DayOfWeek } from '@/constants/date/DayOfWeek.js'

Vue.use(Vuex)

const getDefaultState = () => {
  return {
    activeLoaders: [],
    debugMessages: [],
    loadingCount: 0,
    appLoadingCount: 0,
    debugMode: getSavedState(config.get('app.debugStorageKey')),
    darkMode: getSavedState('darkMode'),
    initialAppLoad: false, // Prevents full page loader on subsequent loads
    embedded: false, // App is embedded within RR
    drawer: getSavedState('drawer'),
    redirectUrl: null,
    userSettings: {
      date: {
        startOfWeek: getSavedState('userSettings.date.startOfWeek'),
      },
      availability: {
        enableWeekends:
          getSavedState('userSettings.availability.enableWeekends') || false,
      },
    },
    // TODO: Should really be returned via an api but for the sake of time this will do
    contactLocations: [
      {
        id: nanoid(),
        name: 'Melbourne - Collingwood',
        email: 'au.anzuk.education',
        phone: '03 9249 2444',
        state: ['Victoria', 'VIC'],
        country: ['Australia', 'AU'],
        address: '2-4 Down St\nCollingwood VIC 3066 Australia',
      },
      {
        id: nanoid(),
        name: 'Melbourne - St Kilda Road',
        email: 'au.anzuk.education',
        phone: '03 9249 2444',
        state: ['Victoria', 'VIC'],
        country: ['Australia', 'AU'],
        address: 'Level 10, 390 St Kilda Road\nMelbourne VIC 3004 Australia',
      },
      {
        id: nanoid(),
        name: 'Geelong',
        email: 'au.anzuk.education',
        phone: '03 5201 7956',
        state: ['Victoria', 'VIC'],
        country: ['Australia', 'AU'],
        address:
          'Level 2 Suite 20, 10-24 Moorabool Street\nGeelong VIC 3220 Australia',
      },
      {
        id: nanoid(),
        name: 'Notting Hill',
        email: 'au.anzuk.education',
        phone: '03 5201 7956',
        state: ['Victoria', 'VIC'],
        country: ['Australia', 'AU'],
        address:
          'Level 1 Building 3, 270 Ferntree Gully Road\nNotting Hill VIC 3168 Australia',
      },
      {
        id: nanoid(),
        name: 'Sydney',
        email: 'bookings.nsw@anzuk.education',
        phone: '02 8027 7909',
        state: ['New South Wales', 'NSW'],
        country: ['Australia', 'AU'],
        address: '302/83-97 Kippax St\nSurry Hills NSW 2010 Australia',
      },
      {
        id: nanoid(),
        name: 'Brisbane',
        email: 'au.anzuk.education',
        phone: '07 3852 6122',
        state: ['Queensland', 'QLD'],
        country: ['Australia', 'AU'],
        address: 'Level 1/120 Wickham St\nFortitude QLD 4006 Australia',
      },
      {
        id: nanoid(),
        name: 'Canberra',
        email: 'au.anzuk.education',
        phone: '02 6113 7933',
        state: ['Australian Capital Territory', 'ACT'],
        country: ['Australia', 'AU'],
        address:
          'Suite 8, Level 5, 17-21 University Avenue\nCity ACT 2601 Australia',
      },
      {
        id: nanoid(),
        name: 'Perth',
        email: 'au.anzuk.education',
        phone: '08 9460 1000',
        state: ['Western Australia', 'WA'],
        country: ['Australia', 'AU'],
        address:
          'Level 2, Building C\n355 Scarborough Beach Road, Osborne Park WA 6017 Australia',
      },

      {
        id: nanoid(),
        name: 'London',
        email: 'uk@anzuk.education',
        phone: '02037874553',
        state: ['London'],
        country: ['United Kingdom', 'UK'],
        address:
          'Axe and Bottle Court, Ground Floor 70 Newcomen Street\nLondon SE1 1YT United Kingdom',
      },
      {
        id: nanoid(),
        name: 'Surrey',
        email: 'surrey@anzuk.education',
        phone: '01483 926324',
        state: ['Surrey'],
        country: ['United Kingdom', 'UK'],
        address:
          'Chessington School, Garrison Lane, Chessington\nSurrey KT9 2JS United Kingdom',
      },
      {
        id: nanoid(),
        name: 'Bristol',
        email: 'bristol@anzuk.education',
        phone: '0117 4573443',
        state: ['Bristol'],
        country: ['United Kingdom', 'UK'],
        address:
          '160 Aztec West, Park Avenue, Almondsbury\nBristol BS32 4TU United Kingdom',
      },
      {
        id: nanoid(),
        name: 'Wales, UK',
        email: 'wales@anzuk.education',
        phone: '01633 988 051',
        state: ['Newport'],
        country: ['United Kingdom', 'UK'],
        address:
          'Kings Chambers, 8 High Street\nNewport, NP20 1FQ United Kingdom',
      },

      {
        id: nanoid(),
        name: 'Toronto',
        email: 'ca@anzuk.education',
        phone: '+ 1 226 773 1955',
        state: ['Toronto'],
        country: ['Canada', 'CA'],
        address: 'Toronto CA',
      },

      {
        id: nanoid(),
        name: 'Los Angeles',
        email: 'la@scoot.education',
        phone: '+13104777707',
        state: ['California', 'CA'],
        country: [
          'United States',
          'United States of America',
          'America',
          'US',
          'USA',
        ],
        address: '5670 Wilshire Blvd. Suite 1970\nLos Angeles, CA 90036',
      },
      {
        id: nanoid(),
        name: 'Bay Area',
        email: 'sf@scoot.education',
        phone: '+14155043933',
        state: ['California', 'CA'],
        country: [
          'United States',
          'United States of America',
          'America',
          'US',
          'USA',
        ],
        address: '490 43rd St\nOakland, CA 94609',
      },
      {
        id: nanoid(),
        name: 'San Diego',
        email: 'sd@scoot.education',
        phone: '+18589993075',
        state: ['California', 'CA'],
        country: [
          'United States',
          'United States of America',
          'America',
          'US',
          'USA',
        ],
        address: '600 B St. Suite 300\nSan Diego, CA 92101',
      },
      {
        id: nanoid(),
        name: 'Central Valley',
        email: 'sf@scoot.education',
        phone: '+14155043933',
        state: ['California', 'CA'],
        country: [
          'United States',
          'United States of America',
          'America',
          'US',
          'USA',
        ],
        address: '400 Capitol Mall Suite 900\nSacramento, CA 95814',
      },
      {
        id: nanoid(),
        name: 'Inland Empire',
        email: 'sd@scoot.education',
        phone: '+18589993075',
        state: ['California', 'CA'],
        country: [
          'United States',
          'United States of America',
          'America',
          'US',
          'USA',
        ],
        address: '3600 Lime St. Bldg 2\nRiverside, CA 92501',
      },
      {
        id: nanoid(),
        name: 'Phoenix, Arizona',
        email: 'px@scoot.education',
        phone: '+6028887976',
        state: ['Arizona', 'AZ'],
        country: [
          'United States',
          'United States of America',
          'America',
          'US',
          'USA',
        ],
        address: '2425 E Camelback Rd Suite 150\nPhoenix, AZ 85016',
      },
      {
        id: nanoid(),
        name: 'Denver, Colorado',
        email: 'dr@scoot.education',
        phone: '+7194167979',
        state: ['Colorado', 'CO'],
        country: [
          'United States',
          'United States of America',
          'America',
          'US',
          'USA',
        ],
        address: '2420 17th St\nDenver, CO 80202',
      },
      {
        id: nanoid(),
        name: 'Las Vegas, Nevada',
        email: 'lv@scoot.education',
        phone: '+7755073344',
        state: ['Nevada', 'NV'],
        country: [
          'United States',
          'United States of America',
          'America',
          'US',
          'USA',
        ],
        address: '10845 Griffith Peak Dr Ste 200A\nLas Vegas, NV 89135',
      },
      {
        id: nanoid(),
        name: 'Salt Lake City, Utah',
        email: 'slc@scoot.education',
        phone: '+3853609771',
        state: ['Utah', 'UT'],
        country: [
          'United States',
          'United States of America',
          'America',
          'US',
          'USA',
        ],
        address: '',
      },
      {
        id: nanoid(),
        name: 'Houston',
        email: 'hn@scoot.education',
        phone: '+2818555200',
        state: ['Texas', 'TX'],
        country: [
          'United States',
          'United States of America',
          'America',
          'US',
          'USA',
        ],
        address: '1201 Fannin St. #202, Office 107\nHouston, TX 77002',
      },
      {
        id: nanoid(),
        name: 'Dallas',
        email: 'ds@scoot.education',
        phone: '+9726378959',
        state: ['Texas', 'TX'],
        country: [
          'United States',
          'United States of America',
          'America',
          'US',
          'USA',
        ],
        address: '1920 McKinney Ave Floor 8\nDallas, Texas 75201',
      },
      {
        id: nanoid(),
        name: 'San Antonio',
        email: 'sa@scoot.education',
        phone: '+2108903010',
        state: ['Texas', 'TX'],
        country: [
          'United States',
          'United States of America',
          'America',
          'US',
          'USA',
        ],
        address: '11103 West Ave, Suite 2101\nSan Antonio, TX 78213',
      },
      {
        id: nanoid(),
        name: 'Jersey City, New Jersey',
        email: 'jc@scoot.education',
        phone: '+5513057772',
        state: ['Texas', 'TX'],
        country: [
          'United States',
          'United States of America',
          'America',
          'US',
          'USA',
        ],
        address: '78 John Miller Way Suite 2001\nKearny, NJ 07032',
      },
      {
        id: nanoid(),
        name: 'Miami, Florida',
        email: 'mi@scoot.education',
        phone: '+7865503598',
        state: ['Texas', 'TX'],
        country: [
          'United States',
          'United States of America',
          'America',
          'US',
          'USA',
        ],
        address: '333 Las Olas Way, CU1\nFort Lauderdale, FL 33301',
      },

      {
        id: nanoid(),
        name: 'Wellington, NZ',
        email: 'relief@epeducation.co.nz',
        phone: '+64 4 387 9988',
        state: ['Wellington'],
        country: ['New Zealand', 'NZ'],
        address: 'Level 7, 94 Dixon St\nWellington 6011',
      },
      {
        id: nanoid(),
        name: 'Auckland, NZ',
        email: 'relief@epeducation.co.nz',
        phone: '+64 4 387 9988',
        state: ['Auckland'],
        country: ['New Zealand', 'NZ'],
        address: '300 Richmond Road, Grey Lynn\nAuckland 1021',
      },
      {
        id: nanoid(),
        name: 'Christchurch, NZ',
        email: 'relief@epeducation.co.nz',
        phone: '+64 4 387 9988',
        state: ['Auckland'],
        country: ['New Zealand', 'NZ'],
        address:
          'Level 2, Unit 9, 15 Sir Gil Simpson Drive\nBurnside,Christchurch 8053',
      },
    ],
  }
}

const state = getDefaultState()

const store = new Vuex.Store({
  modules,
  state,
  plugins: [api, i18n, appInsights],
  // Enable strict mode in development to get a warning
  // when mutating state outside of a mutation.
  // https://vuex.vuejs.org/guide/strict.html
  strict: process.env.NODE_ENV !== 'production',
  getters: {
    moduleName: () => 'root-store',
    isLoading: (state) => state.loadingCount > 0,
    isLoadingApp: (state) => state.appLoadingCount > 0,
    isLoadingByKey: (state) => (key) => state.activeLoaders.indexOf(key) > -1,
    hasLoadedAppOnce: (state) => state.initialAppLoad,
    isError: (state) => state.error,
    isDebugModeActive: (state) => state.debugMode,
    isDarkModeActive: (state) => state.darkMode,
    isDrawerOpen: (state) => state.drawer,
    areAvailabilityWeekendsEnabled: (state) =>
      state.userSettings.availability.enableWeekends,
    debugLog: (state) => state.debugMessages,
    redirectUrl: (state) => state.redirectUrl,
    startOfWeek:
      (state) =>
      (locale = 'en') => {
        if (
          state.userSettings.date.startOfWeek !== 0 &&
          (!state.userSettings.date.startOfWeek ||
            state.userSettings.date.startOfWeek < 0)
        ) {
          const startOfWeekLocaleAware = getStartOfWeekFromLocale(locale)
          return startOfWeekLocaleAware.day()
        }

        return state.userSettings.date.startOfWeek
      },
    contactLocations: (state) => state.contactLocations,
    getContactLocationsByCountry: (state, getters) => (country) => {
      if (!country) return []

      const countryLowerCase = country.toLowerCase()
      const filteredByCountry = getters.contactLocations.filter((location) =>
        location.country
          .map((countryItem) => countryItem.toLowerCase())
          .includes(countryLowerCase)
      )

      return filteredByCountry
    },
    /**
     * Attempt to filter the anzuk location list to remove irrelevant locations
     * base on the user's geographical location
     * @param {*} state
     * @param {*} getters
     * @returns
     */
    getContactLocationsByStateAndCountry:
      (state, getters) => (stateStr, country) => {
        const filteredByCountry = getters.getContactLocationsByCountry(country)

        if (!filteredByCountry || filteredByCountry.length === 0) {
          return getters.contactLocations
        }

        if (!stateStr) {
          return filteredByCountry
        }

        const stateLowerCase = stateStr.toLowerCase()

        const filteredByState = filteredByCountry.filter((location) =>
          location.state
            .map((stateItem) => stateItem.toLowerCase())
            .includes(stateLowerCase)
        )

        if (!filteredByState || filteredByState.length === 0) {
          return filteredByCountry
        }

        return filteredByState
      },
  },
  mutations: {
    START_LOADING(state) {
      state.loadingCount++
    },
    FINISH_LOADING(state) {
      state.loadingCount--
    },
    START_LOADING_BY_KEY(state, key) {
      state.activeLoaders.push(key)
    },
    FINISH_LOADING_BY_KEY(state, key) {
      const indexOfLoader = state.activeLoaders.indexOf(key)
      if (indexOfLoader === -1) return
      state.activeLoaders.splice(indexOfLoader, 1)
    },
    INCREMENT_APP_LOADING(state) {
      state.appLoadingCount++
    },
    DECREMENT_APP_LOADING(state) {
      state.appLoadingCount--
    },
    SET_APP_AS_LOADED(state) {
      state.initialAppLoad = true
    },
    RESET_APP_LOADING_STATE(state) {
      state.initialAppLoad = false
    },
    SET_ERROR(state) {
      state.error = true
    },
    ENABLE_DEBUG(state, persistent) {
      state.debugMode = true
      if (persistent) saveState(config.get('app.debugStorageKey'), true)
    },
    DISABLE_DEBUG(state) {
      state.debugMode = false
      deleteState(config.get('app.debugStorageKey'))
    },
    ENABLE_DARKMODE(state) {
      state.darkMode = true
      saveState('darkMode', true)
    },
    DISABLE_DARKMODE(state) {
      state.darkMode = false
      saveState('darkMode', false)
    },
    OPEN_DRAWER(state) {
      state.drawer = true
      saveState('drawer', true)
    },
    CLOSE_DRAWER(state) {
      state.drawer = false
      saveState('drawer', false)
    },
    SET_REDIRECT_URL(state, url) {
      state.redirectUrl = url
    },
    CLEAR_REDIRECT_URL(state) {
      state.redirectUrl = null
    },
    CLEAR(state) {
      // Clear out local and session storage
      localStorage.clear()
      sessionStorage.clear()

      Object.assign(state, getDefaultState())
    },
    ADD_DEBUG_MESSAGE(state, obj) {
      state.debugMessages.push(obj)
    },
    TOGGLE_AVAILABILITY_WEEKENDS(state) {
      state.userSettings.availability.enableWeekends =
        !state.userSettings.availability.enableWeekends
      saveState(
        'userSettings.availability.enableWeekends',
        state.userSettings.availability.enableWeekends
      )
    },
    TOGGLE_START_OF_WEEK(state, currentStartOfWeek) {
      state.userSettings.date.startOfWeek =
        currentStartOfWeek === DayOfWeek.SUNDAY
          ? DayOfWeek.MONDAY
          : DayOfWeek.SUNDAY
      saveState(
        'userSettings.date.startOfWeek',
        state.userSettings.date.startOfWeek
      )
    },
  },
  actions: {
    /**
     * Logs a debug message
     * @param {*} param0
     * @param {String|Object|Function} message
     * @returns
     */
    addDebugMessage({ commit, getters }, message) {
      if (!getters.isDebugModeActive) return
      commit('ADD_DEBUG_MESSAGE', {
        date: dayjs().format('llll'),
        message,
        id: nanoid(),
      })
      toast.debug(message)
      console.debug(message)
    },

    /**
     * Logs store exceptions via the app logger
     * @param {*} param0
     * @param {Error} error
     */
    logException(context, error) {
      const errorResponseDTO = ErrorResponseFactory(error, this.$i18n)
      logger.logError(errorResponseDTO)
      return errorResponseDTO
    },
    startLoadingApp({ commit }) {
      commit('INCREMENT_APP_LOADING')
    },
    finishLoadingApp({ commit }) {
      commit('DECREMENT_APP_LOADING')
    },
    startLoading({ commit }) {
      commit('START_LOADING')
    },
    finishLoading({ commit }) {
      commit('FINISH_LOADING')
    },
    setAppAsLoaded({ commit }) {
      commit('SET_APP_AS_LOADED')
    },
    resetAppLoadingState({ commit }) {
      commit('RESET_APP_LOADING_STATE')
    },
    toggleDebugMode({ commit, dispatch, getters }, { persistent = false }) {
      if (!getters.isDebugModeActive) {
        commit('ENABLE_DEBUG', persistent)
        dispatch('addDebugMessage', 'Debug mode on')
      } else {
        dispatch('addDebugMessage', 'Debug mode off')
        commit('DISABLE_DEBUG')
      }
    },
    toggleDarkMode({ commit, getters }) {
      getters.isDarkModeActive
        ? commit('DISABLE_DARKMODE')
        : commit('ENABLE_DARKMODE')
    },
    toggleDrawer({ commit, getters }) {
      getters.isDrawerOpen ? commit('CLOSE_DRAWER') : commit('OPEN_DRAWER')
    },
    setRedirectUrl({ commit }, url) {
      commit('SET_REDIRECT_URL', url)
    },
    clearRedirectUrl({ commit }) {
      commit('CLEAR_REDIRECT_URL')
    },
    clearStore({ commit }) {
      dispatchActionForAllModules('clear')

      commit('CLEAR')
    },
    async setLocale({ dispatch }, locale) {
      this.$i18n.locale = locale
      await dispatch('setFavicon')
    },
    setFavicon(context) {
      const favicon = document.querySelector("link[rel~='icon']")
      favicon.href = this.$i18n.t('app.favicon')
    },
    toggleAvailabilityWeekendSetting({ commit }) {
      commit('TOGGLE_AVAILABILITY_WEEKENDS')
    },
    toggleStartOfWeekSetting({ commit, getters }) {
      commit('TOGGLE_START_OF_WEEK', getters.startOfWeek(this.$i18n.locale))
    },
  },
})

export default store

// Automatically run the `init` action for every module,
// if one exists.
dispatchActionForAllModules('init')
