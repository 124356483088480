/* eslint-disable no-unused-vars */
import CustomField from '@/constants/certifications/CustomField'

/**
 * @class
 * @public
 */
export default class CertificationCustomFieldVM {
  /**
   * @constructor
   */
  constructor({ id, value = '' } = {}) {
    /**
     * @type {CustomField}
     */
    this.id = id

    /**
     * @type {String}
     */
    this.value = value
  }
}
