import ShiftRecordErrorAlertType from '@/constants/booking/ShiftRecordErrorAlertType'
import InteractionType from '@/constants/core/InteractionType'
import VuetifyColourClass from '@/constants/core/VuetifyColourClass'
import ShiftRecordErrorDTO from '@/models/booking/shiftRecord/shiftRecordErrorDTO'
import ActionButton from '@/models/general/actionButton'
import EmitEvent from '@/models/general/emitEvent'
import Interaction from '@/models/general/interaction'

/**
 * Determines the error message and action type based on the failure response from the server
 * @param { { data: Object, status: Number } } response
 * @param {{ t: Function }} i18n
 * @returns
 */
const shiftRecordDidNotHappenErrorHandler = (response, i18n) => {
  const errorDto = new ShiftRecordErrorDTO({
    testId: response.data.code,
    title: 'Unable to process your request',
    showContactCard: true,
    actions: [
      new ActionButton({
        text: i18n.t('general.closeBtn'),
        icon: 'mdi-close',
        attributes: {
          outlined: true,
          large: true,
          class: 'mt-6',
        },
        interaction: new Interaction({
          type: InteractionType.emitEvent,
          value: new EmitEvent({ event: 'closeDialog' }),
        }),
      }),
    ],
  })

  switch (response.data.code) {
    case 'booking_already_cancelled':
      errorDto.alertType = ShiftRecordErrorAlertType.statusBlock
      errorDto.alertIcon = 'mdi-check-circle-outline'
      errorDto.alertStyle = VuetifyColourClass.success
      break
    case 'booking_already_invoiced':
      errorDto.alertType = ShiftRecordErrorAlertType.statusBlock
      break
    case 'booking_not_found':
      errorDto.alertType = ShiftRecordErrorAlertType.statusBlock
      errorDto.alertIcon = 'mdi-help-circle-outline'
      errorDto.alertStyle = VuetifyColourClass.warning
      break
    case 'cancel_candidate_not_authorised':
      errorDto.alertType = ShiftRecordErrorAlertType.statusBlock
      break
    case 'invalid_booking_id':
      errorDto.alertType = ShiftRecordErrorAlertType.statusBlock
      break
    default: {
      errorDto.alertType = ShiftRecordErrorAlertType.alert
      break
    }
  }

  const errorMessageKey = `bookings.shiftRecord.didNotHappen.errors.${response.data.code}`

  const translationExists = i18n.t(errorMessageKey) !== errorMessageKey

  errorDto.message = translationExists
    ? i18n.t(`bookings.shiftRecord.didNotHappen.errors.${response.data.code}`)
    : i18n.t('bookings.shiftRecord.didNotHappen.errors.unhandledError', [
        response.data.code,
      ])

  return errorDto
}

export default shiftRecordDidNotHappenErrorHandler
