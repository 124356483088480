import { AvailabilityStatus } from '@/constants/candidate/AvailabilityStatus'

export default class AvailabilityStatusDTO {
  constructor({
    status = AvailabilityStatus.UNSET,
    selectedDates = [],
    partialRanges = [],
  } = {}) {
    /**
     * @type {AvailabilityStatus}
     */
    this.status = status
    /**
     * @type {Date[]}
     */
    this.selectedDates = selectedDates
    /**
     * @type {ScheduledAvailabilityPartialRangeViewModel[]}
     */
    this.partialRanges = partialRanges
  }
}
