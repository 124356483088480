/* eslint-disable no-unused-vars */
import VideoType from '@/constants/registrations/VideoType'

/**
 * @class
 * @public
 */
export default class ComplianceItemVideoViewModel {
  constructor({ url, videoType, minWatchPercentageCompletionCriteria } = {}) {
    /**
     * Contains a URL to a hosted video
     * @type {URL}
     */
    this.url = url ? new URL(url) : null

    /**
     * Describes which type of hosted video exists in the url
     * @type {String}
     * @example Vimeo
     * @see {VideoType} for valid range of values
     */
    this.videoType = videoType

    /**
     * The minimum percentage of the video that needs to be watched before being
     * considered sufficient
     * @type {Number}
     */
    this.minWatchPercentageCompletionCriteria =
      minWatchPercentageCompletionCriteria
  }
}
