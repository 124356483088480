/* eslint-disable no-unused-vars */

import { isEmpty } from 'lodash'
import ActionButton from '@/models/general/actionButton'

export default class ShiftRecordErrorDTO {
  constructor({
    title = '',
    message = '',
    alertType = 'alert',
    alertIcon = 'mdi-alert-circle-outline',
    alertStyle = 'error',
    testId = 'shift-record-error-block',
    showContactCard = false,
    actions = [],
    onLoadInteraction = null,
  } = {}) {
    /**
     * Title to display on the status block
     */
    this.title = title

    /**
     * Error message to display to the user
     * @type {String}
     */
    this.message = message

    /**
     * Indicates to the UI what should be displayed.
     * alert: display the error alert
     * statusBlock: display status block which is reserved for unrecoverable errors
     * @type {'alert|statusBlock|hasAlreadySubmit|nonCompliant'}
     */
    this.alertType = alertType

    /**
     * Icon to display on the alert
     * @type {String}
     */
    this.alertIcon = alertIcon

    /**
     * Styles the alert
     * @type {'error'|'info'|'success'|'warning'}
     */
    this.alertStyle = alertStyle

    /**
     * Used for testing and determining the type of error block being displayed
     * @type {String}
     */
    this.testId = testId

    /**
     * Displays the consultant contact card
     * @type {Boolean}
     */
    this.showContactCard = showContactCard

    /**
     * Action buttons (only used in statusBlock)
     * @type {Array<ActionButton>}
     */
    this.actions = isEmpty(actions)
      ? []
      : actions.map((action) => new ActionButton(action))

    /**
     * Triggers an interaction on load of error block
     * @type {Interaction}
     */
    this.onLoadInteraction = onLoadInteraction
  }
}
