export default Object.freeze({
  /**
   * Compliance item has been completed and verified.
   */
  Complete: 'Complete',
  /**
   * The compliance item has been fullfilled by an uploaded file/cert that
   * isn't currently linked to this item and has been verified.
   */
  CompleteByUnlinked: 'CompleteByUnlinked',
  /**
   * Requires candidate action.
   */
  RequiresCandidateAction: 'RequiresCandidateAction',
  /**
   * Awaiting to be verified by a consultant.
   * No further action is required from the user.
   */
  AwaitingVerification: 'AwaitingVerification',
  /**
   * The compliance item has been fullfilled by an uploaded file/cert that
   * isn't currently linked to this item and is in the process of being verified.
   */
  AwaitingVerificationForUnlinked: 'AwaitingVerificationForUnlinked',
})
