import CandidateBookingSummaryLocationAddressViewModel from './candidateBookingSummaryLocationAddressViewModel'

/**
 * A complete view model to describe address information
 * @class
 * @public
 */
export default class CandidateBookingDetailsLocationAddressDTO extends CandidateBookingSummaryLocationAddressViewModel {
  /**
   * @param {{ fullAddress: String, latitude: number, longitude: number, suburb: String }} param0
   */
  constructor({
    line1,
    line2,
    suburb = '',
    state,
    postcode,
    country,
    fullAddress = '',
    latitude = 0.0,
    longitude = 0.0,
  } = {}) {
    super(fullAddress, latitude, longitude, suburb)
    /**
     * @type {String}
     */
    this.line1 = line1
    /**
     * @type {String}
     */
    this.line2 = line2
    /**
     * @type {String}
     */
    this.state = state
    /**
     * @type {String}
     */
    this.postcode = postcode
    /**
     * @type {String}
     */
    this.country = country
  }
}
