export default Object.freeze({
  /**
   * Displays an alert at the top of the form
   */
  alert: 'alert',
  /**
   * Block that obscures the form and removes any confirmation buttons.
   * Typically reserved for errors that are unrecoverable or scenarios where
   * there are no actions required from the user.
   */
  statusBlock: 'statusBlock',
  /**
   * Displays a dialog to enter the reason a candidate's shift record details are non compliant
   */
  nonCompliant: 'nonCompliant',
})
