/**
 * Checks if an array contains any items.
 * @param {Array} arr The array to check.
 * @returns {Boolean}, true if the array contains items. Otherwise, false.
 * @depreciated Use isEmptyArray instead
 */
const isNonEmptyArray = (arr) => {
  return arr && Array.isArray(arr) && arr.length > 0
}

/**
 * Checks if an array is empty, null or a non-array
 * @param {Array?} arr
 * @returns
 */
const isEmptyArray = (arr) => {
  return !arr || !Array.isArray(arr) || arr.length === 0
}

export { isNonEmptyArray, isEmptyArray }
