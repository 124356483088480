import {
  getAuth,
  EmailAuthProvider,
  reauthenticateWithCredential,
  updatePassword,
  sendPasswordResetEmail,
  signOut,
  getIdToken,
  signInWithEmailAndPassword,
  signInWithCustomToken,
} from 'firebase/auth'
import msal from '@plugins/msal'
import config from '@common/config.js'
import router from '@/router'
import { fail, success } from '@/helpers/result-helper.js'
import firebase from '@/plugins/firebase'
import toast from '@/services/toasts/index.js'
import $date from '@/services/date/index.js'
import {
  isCacheFresh,
  getSavedState,
  saveState,
  deleteState,
} from '@/helpers/cache-helpers'
import DurationUnits from '@/constants/date/DurationUnits.js'
import routeDefinitions from '@/constants/routes/routeDefinitions'
import CandidateUserViewModel from '@/models/candidate/candidateUserViewModel'
// eslint-disable-next-line no-unused-vars
import AccountCreationDTO from '@/models/user/accountCreationDTO'
// eslint-disable-next-line no-unused-vars
import ResultDTO from '@/models/app/resultDTO'

const USER_PROFILE_FRESHNESS_DURATION = { value: 2, units: DurationUnits.HOUR }

/**
 * Returns the user object from local storage if it's fresh otherwise returns null
 * @returns {Object|null}
 */
const checkUserCacheFreshness = (forceRefresh = false) => {
  const user = getSavedState('auth.currentUser')

  if (!user || !user?.lastUpdated) return null

  if (
    isCacheFresh({
      cacheDuration: USER_PROFILE_FRESHNESS_DURATION.value,
      durationUnits: USER_PROFILE_FRESHNESS_DURATION.units,
      lastUpdated: user?.lastUpdated,
      forceRefresh,
    })
  )
    return user

  return null
}

export default {
  namespaced: true,
  state: {
    // MSAL User
    account: getSavedState('auth.account'),
    interactionRequired: true,
    // User Profile from DB
    currentUser: checkUserCacheFreshness(),
    accessToken: '', // Bearer token
    lastTokenRefresh: null,
    loadingCount: 0,
    // auth: firebase,
    impersonateCandidateId: getSavedState('auth.impersonateCandidateId'),
    username: null, // used to track errors when user profile is not set
  },

  mutations: {
    SET_CURRENT_USER(state, userVM) {
      state.currentUser = new CandidateUserViewModel(userVM)
      saveState('auth.currentUser', state.currentUser)
    },
    SET_ACCOUNT(state, newValue) {
      state.account = newValue
      saveState('auth.account', newValue)
    },
    SET_IMPERSONATE_CANDIDATE_ID(state, candidateId) {
      state.impersonateCandidateId = candidateId
      saveState('auth.impersonateCandidateId', candidateId)
    },
    SET_USER_GENERALFILES(state, files) {
      state.generalFiles = files
    },
    SET_INTERACTION_REQUIRED(state, newValue) {
      state.interactionRequired = newValue
    },
    SET_ACCESS_TOKEN(state, token) {
      state.accessToken = token
      state.interactionRequired = false
      state.lastTokenRefresh = $date()
    },
    SET_USER_TO_UNAUTHENTICATED(state) {
      state.account = null
      state.interactionRequired = true
      state.currentUser = null
      state.impersonateCandidateId = null
      deleteState('auth.account')
      deleteState('auth.currentUser')
      deleteState('auth.impersonateCandidateId')
      deleteState('client.id')
      deleteState('client.name')
      state.accessToken = null
      state.username = null

      sessionStorage.clear()
      localStorage.clear()
    },
    FRESH_IMPERSONATE_CLEAR_STORE(state) {
      state.currentUser = null
      state.impersonateCandidateId = null
      deleteState('auth.currentUser')
      deleteState('auth.impersonateCandidateId')
      deleteState('client.id')
      deleteState('client.name')
    },
    START_LOADING(state) {
      state.loadingCount++
    },
    FINISH_LOADING(state) {
      state.loadingCount--
    },
    SET_USERNAME(state, username) {
      state.username = username
    },
  },

  getters: {
    moduleName: () => 'auth',
    currentUserFullName: (state) =>
      state.currentUser
        ? `${state.currentUser.firstName} ${state.currentUser.lastName}`
        : '',
    currentUserEmail: (state) =>
      state.currentUser
        ? state.currentUser.email
        : state.username || 'not_specified',
    currentUser: (state) => state.currentUser,
    currentUserCandidateId: (state) =>
      state.currentUser?.id || state.impersonateCandidateId || 'not_specified',
    currentUserSimple: (state, getters) => {
      return {
        id: getters.currentUserContactId || '',
        emailAddress: getters.currentUserEmail || '',
        isImpersonating: getters.hasImpersonateCandidateId,
      }
    },
    associatedConsultant: (state) => state.currentUser.associatedConsultant,
    msalAccount: (state) => state.account,
    impersonateCandidateId: (state) => state.impersonateCandidateId,
    hasImpersonateCandidateId: (state) => !!state.impersonateCandidateId,
    accessToken: (state) => state.accessToken,
    lastTokenRefresh: (state) => state.lastTokenRefresh,
    isUserLoggedIn: (state) =>
      !!state.accessToken && (!state.interactionRequired || !!state.account),
    isLoadingAuth: (state) => state.loadingCount > 0,
    isInteractionRequired: (state) => state.interactionRequired,
    auth: (state) => firebase,
    msalInstance: (state) => msal,
    timeZone: (state) => state.currentUser.timezone,
  },

  actions: {
    /**
     * This is automatically run in `src/store/index.js` when the app
     * starts, along with any other actions named `init` in other modules.
     * @param {*} param0
     */
    init({ dispatch }) {},

    /**
     * Login via firebase
     * @param {*} param0
     * @param {Object<username: String, password: String>} param1
     * @returns
     */
    async logIn({ dispatch, getters, commit }, { username, password }) {
      // Clean out localStorage to ensure AAD credentials don't remain
      dispatch('clearStore', null, { root: true })

      // Setting username in the store to assist with logging
      commit('SET_USERNAME', username)

      if (getters.isUserLoggedIn) return dispatch('refreshToken')

      commit('START_LOADING')
      try {
        const auth = getAuth(firebase)
        const response = await signInWithEmailAndPassword(
          auth,
          username,
          password
        )

        if (!response.user)
          return fail({
            error: await dispatch(
              'logException',
              new Error(this.$i18n.t('auth.loginGetProfileFailureErrorText')),
              { root: true }
            ),
            message: this.$i18n.t('auth.loginGetProfileFailureErrorText'),
          })

        commit('SET_INTERACTION_REQUIRED', false)
        return success()
      } catch (ex) {
        let message = ''
        if (ex.code === 'auth/wrong-password')
          message = this.$i18n.t('auth.loginWrongPasswordErrorText')
        else if (ex.code === 'auth/user-not-found')
          message = this.$i18n.t('auth.loginUserNotFoundErrorText')
        else message = ex.message

        return fail({
          error: await dispatch('logException', ex, { root: true }),
          message,
        })
      } finally {
        commit('FINISH_LOADING')
      }
    },

    /**
     * Handles redirect auth from MSAL
     * @param {Object} response Response will be populated on msal login
     */
    async handleRedirect({ dispatch }, response) {
      // Redirect to home after login
      if (response !== null) {
        await dispatch('getUserFromMsalProvider')
        router.push({ name: routeDefinitions.home.name })
      }

      // In case multiple accounts exist, you can select
      const currentAccounts = msal.getAllAccounts()
      if (currentAccounts.length > 0) {
        // TODO: Add choose account code here
        await dispatch('getUserFromMsalProvider')
      }
    },

    // Handles already logged in impersonate redirect from login page
    async msalAlreadyLoggedInRedirect(
      { dispatch, commit },
      impersonateCandidateId
    ) {
      // Attempt to log out of firebase & clear out store in preperation
      try {
        await dispatch('firebaseLogOut', {
          nuke: false,
          showNotifications: false,
          redirect: false,
          setUnauthenticated: false,
        })
      } catch (ex) {
        await dispatch('logException', ex, { root: true })
      }

      // Clear out certain values that need to be retrieved from API
      // await dispatch('client/clear', null, { root: true })
      commit('FRESH_IMPERSONATE_CLEAR_STORE')

      // Set the new impersonate contact Id
      commit('SET_IMPERSONATE_CANDIDATE_ID', impersonateCandidateId)

      // Redirect to dashboard
      router.push({ name: routeDefinitions.home.name })
    },

    async clearImpersonateId({ commit }) {
      commit('FRESH_IMPERSONATE_CLEAR_STORE')
    },

    /**
     * Logs in the current user.
     * @param {*} param0
     * @param {Number} impersonateCandidateId
     * @returns
     */
    async msalLogIn({ dispatch, getters, commit }, impersonateCandidateId) {
      // Attempt to log out of firebase & clear out store in preperation
      try {
        await dispatch('firebaseLogOut', {
          nuke: true,
          showNotifications: false,
          redirect: false,
          setUnauthenticated: false,
        })
      } catch (ex) {
        await dispatch('logException', ex, { root: true })
      }

      commit('SET_IMPERSONATE_CANDIDATE_ID', impersonateCandidateId)

      if (getters.isUserLoggedIn) return dispatch('msalRefreshToken')

      const loginRequest = {
        scopes: ['openid'],
      }

      try {
        await msal.loginRedirect(loginRequest)
      } catch (ex) {
        commit('SET_USER_TO_UNAUTHENTICATED')

        const error = await dispatch('logException', ex, { root: true })

        const errorCode = ex.errorCode

        const noNotificationReq = ['user_cancelled']

        // Filter through errors that don't require a notifiction
        if (noNotificationReq.some((v) => errorCode.includes(v)))
          return fail({ error })

        toast.error(this.$i18n.t('auth.errorLoginImpersonateContact'))
        return fail({ error })
      }
    },

    /**
     * Logs out the current user.
     * @param {*} param0
     * @param {*} payload
     * @returns
     */
    async msalLogOut(
      { commit, dispatch },
      payload = { redirect: true, nuke: true }
    ) {
      const { redirect, nuke } = payload

      commit('START_LOADING')

      try {
        await msal.logout({})

        commit('SET_USER_TO_UNAUTHENTICATED')

        if (redirect) await router.push({ name: routeDefinitions.login.name })

        // Nuke store
        if (nuke) dispatch('clearStore', null, { root: true })

        return success()
      } catch (ex) {
        toast.error(this.$i18n.t('auth.signOutFailureErrorText'))
        return fail({
          error: await dispatch('logException', ex, { root: true }),
        })
      } finally {
        commit('FINISH_LOADING')
      }
    },

    /**
     * Retrieves user account from auth provider
     * @param {*} param0
     * @returns
     */
    getUserFromMsalProvider({ commit }) {
      if (!msal) return Promise.resolve(null)

      try {
        const myAccounts = msal.getAllAccounts()
        commit('SET_ACCOUNT', myAccounts[0])
      } catch {
        commit('SET_ACCOUNT', null)
      }
    },

    /**
     * Validates the current user's token and refreshes it
     * with new data from the API.
     * @param {*} param0
     * @returns
     */
    async msalRefreshToken({ dispatch, commit, state }) {
      if (!msal) return Promise.resolve(fail()) // Prevents trying to access auth object before it is initialised
      await dispatch('getUserFromMsalProvider')
      if (!state.account) return Promise.resolve(fail())

      commit('SET_USERNAME', state.account?.username)

      commit('START_LOADING')

      const request = {
        scopes: [config.get('scopes.openId'), config.get('scopes.read')],
        account: state.account,
      }

      try {
        const response = await msal.acquireTokenSilent(request)
        commit('SET_ACCESS_TOKEN', response.accessToken)
        return success()
      } catch (error) {
        console.warn('Silent token acquisition failed. Using interactive mode')

        await dispatch('logException', error, { root: true })

        try {
          const popupResponse = await msal.acquireTokenPopup(request)

          commit('SET_ACCESS_TOKEN', popupResponse.accessToken)
          return success()
        } catch (ex) {
          toast.error('Failed to authenticate as impersonated contact')
          return fail({
            error: await dispatch('logException', ex, { root: true }),
          })
        } finally {
          commit('FINISH_LOADING')
        }
      } finally {
        commit('FINISH_LOADING')
      }
    },

    /**
     * Logs out the current user
     * @param {*} param0
     * @param {*} payload
     * @returns
     */
    async logOut({ getters, dispatch }, payload) {
      return dispatch(
        getters.impersonateCandidateId ? 'msalLogOut' : 'firebaseLogOut',
        payload
      )
    },

    /**
     * Logs out the current user from firebase
     * @param {*} param0
     * @param {*} payload
     * @returns
     */
    async firebaseLogOut(
      { commit, dispatch },
      payload = {
        redirect: true,
        nuke: true,
        showNotifications: true,
        setUnauthenticated: true,
      }
    ) {
      const { redirect, nuke, showNotifications, setUnauthenticated } = payload

      commit('START_LOADING')

      const auth = getAuth(firebase)

      try {
        await signOut(auth)

        if (setUnauthenticated) commit('SET_USER_TO_UNAUTHENTICATED')

        if (redirect) await router.push({ name: routeDefinitions.login.name })

        // Nuke store
        if (nuke) dispatch('clearStore', null, { root: true })

        return success()
      } catch (error) {
        if (showNotifications)
          toast.error(this.$i18n.t('auth.signOutFailureErrorText'))
        return fail({
          error: await dispatch('logException', error, { root: true }),
        })
      } finally {
        commit('FINISH_LOADING')
      }
    },

    async refreshToken({ dispatch, getters }, forceRefresh = false) {
      return await dispatch(
        getters.impersonateCandidateId
          ? 'msalRefreshToken'
          : 'firebaseRefreshToken',
        forceRefresh
      )
    },

    /**
     * Checks freshness of access token and will force refresh the token if token
     * is considered stale
     * @param {*} context Vuex context
     * @param {Boolean} forceRefresh Forces a token refresh
     * @returns Access token
     */
    async getAccessTokenOrRefresh({ dispatch, getters }, forceRefresh = false) {
      const isAccessTokenFresh = isCacheFresh({
        cacheDuration: 30,
        durationUnits: DurationUnits.MINUTE,
        lastUpdated: getters.lastTokenRefresh,
        forceRefresh,
      })

      if (!isAccessTokenFresh) {
        await dispatch('refreshToken', true)
      }

      return getters.accessToken
    },

    //
    /**
     * Validates the current user's token and refreshes it using Firebase
     * with new data from the API.
     * @param {*} param0
     * @param {Boolean} forceRefresh
     * @returns
     */
    async firebaseRefreshToken({ commit, dispatch }, forceRefresh = false) {
      try {
        const auth = getAuth(firebase)
        const user = auth.currentUser

        if (!user)
          throw new Error('User is unauthenticated and trying to refresh token')

        commit('SET_USERNAME', user?.email)

        const idToken = await getIdToken(user, forceRefresh)
        commit('SET_ACCESS_TOKEN', idToken)
        return success()
      } catch (error) {
        commit('SET_USER_TO_UNAUTHENTICATED')
        await dispatch('logException', error, { root: true })
        throw error
      }
    },

    async resetPasswordAsync({ commit, dispatch }, payload) {
      commit('START_LOADING')

      const auth = getAuth(firebase)

      try {
        await sendPasswordResetEmail(auth, payload.email)

        toast.success(this.$i18n.t('auth.resetPasswordSuccessText'))
        return success()
      } catch (error) {
        const errDto = await dispatch('logException', error, { root: true })

        let message = ''
        if (error.code === 'auth/user-not-found')
          message = this.$i18n.t(
            'auth.resetPasswordAccountDoesNotExistErrorText'
          )
        else message = error.message

        return fail({ message, error: errDto })
      } finally {
        commit('FINISH_LOADING')
      }
    },

    /**
     * Used to reauthenticate a user before a sensative action (e.g. change password, change email address)
     * This is a security requirement enforced by firebase.
     * Read more: https://firebase.google.com/docs/reference/js/firebase.User#reauthenticatewithcredential
     * @param {String} password
     */
    async reauthenticateWithCredentialsAsync({ commit, dispatch }, password) {
      commit('START_LOADING')

      const auth = getAuth(firebase)
      const user = auth.currentUser

      // Prepare auth credentials
      const credentials = await EmailAuthProvider.credential(
        user.email,
        password
      )

      // Use credentials to reauthenticate user
      try {
        await reauthenticateWithCredential(user, credentials)
        return success()
      } catch (error) {
        toast.error(this.$i18n.t('auth.failedToAuthenticateWithCredsErrorText'))
        return fail({
          error: await dispatch('logException', error, { root: true }),
        })
      } finally {
        commit('FINISH_LOADING')
      }
    },

    /**
     *
     * @param {*} param0
     * @param {*} payload
     * @returns
     */
    async changePasswordAsync({ commit, dispatch }, payload) {
      commit('START_LOADING')

      try {
        const auth = getAuth(firebase)
        const user = auth.currentUser

        await updatePassword(user, payload.newPass)

        toast.success(this.$i18n.t('auth.changePasswordSuccessText'))
        return success()
      } catch (error) {
        return fail({
          error: await dispatch('logException', error, { root: true }),
        })
      } finally {
        commit('FINISH_LOADING')
      }
    },

    /**
     * Loads user profile from R2W API.
     * @param {Boolean} forceRefresh forces refresh of user profile, bypassing the cache
     * @returns
     */
    async getCurrentUserProfile(
      { commit, dispatch, getters, rootGetters },
      forceRefresh = false
    ) {
      // 1. Check cache freshness
      if (checkUserCacheFreshness(forceRefresh))
        return success({ data: getters.currentUser })

      // 2. Load profile from API & cache user profile
      commit('START_LOADING')

      try {
        const response = await this.$api.user.get()
        commit('SET_CURRENT_USER', response.data)
        dispatch('ready2Work/setReady2Work', response.data?.ready2WorkStatus, {
          root: true,
        })

        return success({ data: getters.currentUser })
      } catch (ex) {
        return fail({
          error: await dispatch('logException', ex, { root: true }),
        })
      } finally {
        commit('FINISH_LOADING')
      }
    },
    stopImpersonating({ commit }) {
      commit('SET_USER_TO_UNAUTHENTICATED')
      router.push({ name: routeDefinitions.impersonateLogout.name })
    },
    /**
     * Authenticate to firebase using a custom auth token generated on the backend
     * @param {{dispatch: Function, commit: Function}} VuexAction
     * @param {String} token custom auth token
     * @returns
     */
    async logInWithCustomToken({ dispatch, commit }, token) {
      // Clean out localStorage to ensure AAD credentials don't remain
      dispatch('clearStore', null, { root: true })

      commit('SET_USERNAME', 'Username not set')

      commit('START_LOADING')
      try {
        const auth = getAuth(firebase)

        await signOut(auth)
        const response = await signInWithCustomToken(auth, token)

        if (!response.user)
          return fail({
            error: await dispatch(
              'logException',
              new Error(this.$i18n.t('auth.loginGetProfileFailureErrorText')),
              { root: true }
            ),
            message: this.$i18n.t('auth.loginGetProfileFailureErrorText'),
          })

        // Setting username in the store to assist with logging
        commit('SET_USERNAME', response.user.email)

        commit('SET_INTERACTION_REQUIRED', false)
        return success()
      } catch (error) {
        const message = this.$i18n.t(
          'auth.accountSetup.customTokenSignInError',
          [error.code, routeDefinitions.login.path]
        )

        return fail({
          error: await dispatch('logException', error, { root: true }),
          message,
        })
      } finally {
        commit('FINISH_LOADING')
      }
    },
    /**
     * Sets up a password for the candidate for first time account setup
     * @param {{commit: Function, dispatch: Function}} VuexAction
     * @param {AccountCreationDTO} payload
     * @returns {Promise<ResultDTO>}
     */
    async completeAccountSetup({ dispatch, commit }, payload) {
      try {
        commit('START_LOADING')

        const response = await this.$api.user.accountSetup(payload)

        const loginResult = await dispatch(
          'logInWithCustomToken',
          response.data.authToken
        )

        if (!loginResult.isSuccess) return loginResult

        toast.success(
          this.$i18n.t('auth.accountSetup.successToast', [
            this.$i18n.t('app.appName'),
          ])
        )

        return response
      } catch (error) {
        const errorDto = await dispatch('logException', error, { root: true })

        let message = ''

        switch (errorDto.code) {
          case 'account_setup_token_missing':
          case 'account_setup_token_invalid':
            message = this.$i18n.t('auth.accountSetup.argError')
            break
          case 'account_setup_token_expired':
            message = this.$i18n.t('auth.accountSetup.expiredEmailLink')
            break
          case 'account_setup_already_complete':
            message = this.$i18n.t(
              'auth.accountSetup.accountAlreadySetupError',
              [routeDefinitions.login.path]
            )
            break
          case 'account_setup_password_missing':
            message = this.$i18n.t('auth.accountSetup.passwordMissingError')
            break
          case 'account_setup_password_invalid':
            message = this.$i18n.t('auth.accountSetup.passwordInvalidError')
            break
          default:
            message = this.$i18n.t('auth.accountSetup.argError')
        }

        return fail({
          error: errorDto,
          message,
        })
      } finally {
        commit('FINISH_LOADING')
      }
    },
    clear({ commit }) {
      commit('SET_USER_TO_UNAUTHENTICATED')
    },
  },
}
