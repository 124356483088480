import NullParameterError from '@/models/error/nullParameterError'
import UserIdentificationDTO from '@/models/user/userIndentificationDTO'

/**
 * Retrieves user identity (with optional IP address) for logging purposes
 * @param {VuexStore} store
 * @param {Boolean} logIpAddress Will not log ip address if false
 * @returns {UserIdentificationDTO}
 */
const fetchUserIdentityWithIpAddress = (store, logIpAddress = false) => {
  if (!store) throw new NullParameterError('store')

  const user = store.getters['auth/currentUserSimple']
  let userIpAddress = 'Instructed not to grab IP address'

  if (logIpAddress) {
    try {
      const request = new XMLHttpRequest()
      request.open('GET', 'https://api.ipify.org?format=json', false)

      request.send(null)

      if (request.status === 200) {
        const responseJSON = JSON.parse(request.responseText)
        userIpAddress = responseJSON.ip
      } else {
        throw new Error(
          `Unable to retrieve IP address. Status code: ${request.status}`
        )
      }
    } catch (ex) {
      userIpAddress = `Failed to get IP address. Reason: ${ex}`
    }
  }

  return new UserIdentificationDTO({
    id: user.id,
    emailAddress: user.emailAddress,
    ipAddress: userIpAddress,
    isImpersonating: user.isImpersonating,
  })
}

/**
 * Compiles custom properties object for logging
 * @param {VuexStore} store
 * @param {Object} properties
 * @param {Boolean} logUserDetails Will log the details of the current user
 * @returns
 */
const compileCustomProperties = (store, properties, logUserDetails = false) => {
  if (!logUserDetails) return properties

  const user = fetchUserIdentityWithIpAddress(store, true)

  return { ...properties, ...user }
}

export { fetchUserIdentityWithIpAddress, compileCustomProperties }
