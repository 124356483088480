<script>
import Layout from '@layouts/loading.vue'
import PageLoader from '@/components/layout/page-loader.vue'

export default {
  name: 'LoadingView',
  metaInfo() {
    return {
      title: this.$t('loading.loadingPageTitle'),
      meta: [
        {
          name: 'description',
          content: this.$t('loading.loadingMetaDescription'),
        },
      ],
    }
  },
  components: {
    Layout,
    PageLoader,
  },
}
</script>

<template>
  <Layout>
    <PageLoader />
  </Layout>
</template>
