/* eslint-disable no-unused-vars */
export default class CandidateBookingNavigationItemViewModel {
  constructor({ bookingId, startDateLocal } = {}) {
    /**
     * @type {Number}
     */
    this.bookingId = bookingId

    /**
     * @type {Date}
     */
    this.startDateLocal = startDateLocal
  }
}
