/* eslint-disable no-unused-vars */
import ValidationRules from '@/constants/form/ValidationRules'

/**
 * @class
 * @public
 */
export default class CertificationTypeFieldMetaRuleVM {
  /**
   * @constructor
   */
  constructor({ ruleType, value = '' } = {}) {
    /**
     * @type {ValidationRules}
     */
    this.ruleType = ruleType

    /**
     * @type {String}
     */
    this.value = value
  }
}
