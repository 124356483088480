// eslint-disable-next-line no-unused-vars
import BackendBookingFeatureDecisions from '@/models/booking/backendBookingFeatureDecisionDTO'

/**
 * Generate feature toggles object for bookings components/logic
 * @param {Object} featureDecisions Object returned from `services/features/featureDecisions.js`
 * @param {BackendBookingFeatureDecisions} backendBookingFeatureDecisions
 */
const bookingFeatureFactory = (
  featureDecisions,
  backendBookingFeatureDecisions
) => {
  return {
    isRunningLateEnabled:
      featureDecisions.isRunningLateEnabled() &&
      backendBookingFeatureDecisions.isRunningLateEnabled,
  }
}

export default bookingFeatureFactory
