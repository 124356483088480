/**
 * To contain user data for logging purposes
 */
export default class UserIdentificationDTO {
  constructor({ id, emailAddress, ipAddress, isImpersonating = false } = {}) {
    /**
     * @type {Number} User's unique ID
     */
    this.user_id = id

    /**
     * @type {String}
     */
    this.user_email = emailAddress

    /**
     * @type {String}
     */
    this.user_host_address = ipAddress

    /**
     * @type {Boolean}
     */
    this.user_is_impersonating = isImpersonating
  }
}
