import GenericFileModel from '@/models/file/genericFileModel'

/**
 * Class representing a certification file with associated meta data to be used for verifying certs.
 * @class
 * @constructor
 */
export class CandidateCertificationCheckDTO {
  /**
   * Create a cert check object.
   */
  constructor({ content, certificationId }) {
    /**
     * @type {GenericFileModel} content
     */
    this.content = content ? new GenericFileModel(content) : null
    /**
     * @type {number} certificationId - Certification type ID
     */
    this.certificationId = certificationId
  }
}
