/* eslint-disable no-unused-vars */
import ShiftRecordBlockType from '@/constants/booking/ShiftRecordBlockType'

/**
 * @class
 * @public
 */
export default class ShiftRecordBlock {
  constructor({ type, startTime, endTime } = {}) {
    /**
     * Determines if the shiftRecord has been submitted or not
     * @type {String}
     * @see {ShiftRecordBlockType} for valid range of values
     */
    this.type = type
    /**
     * Start time of block in 24 hr format
     * @type {String}
     * @example 08:30
     */
    this.startTime = startTime
    /**
     * End time of block in 24 hr format
     * @type {String}
     * @example 15:30
     */
    this.endTime = endTime
  }
}
