import Vue from 'vue'
import { version } from '@root/lib/version'
const { get, has, set, merge } = require('lodash')

// Load config based on environment
const env = process.env.NODE_ENV

// Merge in additional config
const config = {
  // Props
  env,
  appVersion: version,
  ...Vue.prototype.$config,

  // Methods
  get(path) {
    return get(this, path)
  },
  has(path) {
    return has(this, path)
  },
  set(path, value) {
    return set(this, path, value)
  },
  load(obj) {
    merge(this, obj)
  },
}

export default config
