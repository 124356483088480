// eslint-disable-next-line no-unused-vars
import ErrorResponseDTO from '../error/errorResponseDTO'

/**
 * @class
 * @public
 */
export default class ResultDTO {
  /**
   * @constructor
   * @param {Object<isSuccess: Boolean, data: any, message: String, statusCode: Number, error: ErrorResponseDTO>, headers: any} param0
   */
  constructor({
    isSuccess = false,
    data = null,
    message = '',
    statusCode = null,
    error = null,
    headers = null,
  } = {}) {
    /**
     * Indicates if the operation was a success or failure
     * @type {Boolean}
     */
    this.isSuccess = isSuccess

    /**
     * Data returned from the operation
     * @type {*}
     */
    this.data = data

    /**
     * Message to convey to calling method. Typically used for error messages
     * @type {String}
     */
    this.message = message

    /**
     * HTTP response code
     * @type {Number}
     */
    this.statusCode = statusCode

    /**
     * @type {ErrorResponseDTO}
     */
    this.error = error

    /**
     * Contains an object of key pair values describing the response headers
     * @type {*}
     */
    this.headers = headers
  }
}
