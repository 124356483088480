<script>
import { upperFirst, camelCase } from 'lodash'
import LogoType from '@/constants/assets/LogoType'
import LogoShade from '@/constants/assets/LogoShade'
import LogoForm from '@/constants/assets/LogoForm'

export default {
  name: 'BaseLogo',
  inheritAttrs: false,
  props: {
    /**
     * Logo type
     *
     * options: app | company
     * default: app
     */
    type: {
      type: String,
      default: LogoType.APP,
      validator: function (value) {
        return Object.values(LogoType).includes(value)
      },
    },
    /**
     * Logo form
     *
     * options: full | short | icon | none
     * default: none
     */
    form: {
      type: String,
      default: LogoForm.NONE,
      validator: function (value) {
        return Object.values(LogoForm).includes(upperFirst(camelCase(value)))
      },
    },
    /**
     * Logo Shade
     *
     * options: dark | light | white
     * default: dark
     */
    shade: {
      type: String,
      default: LogoShade.DARK,
      validator: function (value) {
        return Object.values(LogoShade).includes(upperFirst(camelCase(value)))
      },
    },
  },
  computed: {
    commonAttributes() {
      return {}
    },
    compSrc() {
      const shade = upperFirst(camelCase(this.shade))
      const form = upperFirst(camelCase(this.form))
      return `https://anzukmedia.blob.core.windows.net/logos/SVG/${this.$t(
        `logo.${this.type}Logo${shade}${form}`
      )}`
    },
    compAltText() {
      return this.$t('logo.logoAltText', {
        companyName:
          this.type === LogoType.APP
            ? this.$t('app.appName')
            : this.$t('app.companyName'),
      })
    },
    compHref() {
      return this.type === LogoType.APP ? '/' : this.$t('app.companyWebsiteURL')
    },
    compTarget() {
      return this.type === LogoType.APP ? null : '_blank'
    },
  },
}
</script>

<template>
  <a :href="compHref" :target="compTarget">
    <v-img
      class="base-logo"
      :src="compSrc"
      v-bind="{ ...commonAttributes, ...$attrs }"
      :title="compAltText"
      :alt="compAltText"
      contain
      v-on="$listeners"
    />
  </a>
</template>
