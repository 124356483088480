/* eslint-disable no-unused-vars */
import CertificationTypeFieldMetaRuleVM from './certificationTypeFieldMetaRuleVM'
import FieldId from '@/constants/certifications/FieldId'
import FormInputType from '@/constants/form/FormInputType'
import { isNonEmptyArray } from '@/helpers/array-helpers'

/**
 * @class
 * @public
 */
export default class CertificationTypeFieldMetaVM {
  /**
   * @constructor
   */
  constructor({
    fieldId,
    title = '',
    fieldType = '',
    rules = [],
    options = [],
  } = {}) {
    /**
     * @type {FieldId}
     */
    this.fieldId = fieldId

    /**
     * @type {String}
     */
    this.title = title

    /**
     * @type {FormInputType}
     */
    this.fieldType = fieldType

    /**
     * @type {Array<CertificationTypeFieldMetaRuleVM>}
     */
    this.rules = isNonEmptyArray(rules)
      ? rules.map((rule) => new CertificationTypeFieldMetaRuleVM(rule))
      : []

    /**
     * @type {Array<String>}
     */
    this.options = isNonEmptyArray(options) ? options : []
  }
}
