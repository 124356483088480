import { ModelApiService } from './BaseApiService'

export default class FileApiService extends ModelApiService {
  constructor() {
    super('downloads')
  }

  async getAppGeneralFileList() {
    return this.get('general')
  }

  async downloadAppGeneralFile(fileId) {
    return this.getBlob(`general/${fileId}`)
  }
}
