/**
 * Some basic elements that make up an address
 */
export default class AddressViewModel {
  constructor({
    line1 = '',
    line2 = '',
    suburb = '',
    state = '',
    postcode = '',
    country = '',
    fullAddress = '',
    latitude = 0.0,
    longitude = 0.0,
  } = {}) {
    /**
     * @type {String}
     */
    this.line1 = line1

    /**
     * @type {String}
     */
    this.line2 = line2

    /**
     * @type {String}
     */
    this.suburb = suburb

    /**
     * @type {String}
     */
    this.state = state

    /**
     * @type {String}
     */
    this.postcode = postcode

    /**
     * @type {String}
     */
    this.country = country

    /**
     * A full address string. A combination of all address segements into a single string.
     * @type {String}
     */
    this.fullAddress = fullAddress

    /**
     * @type {Number}
     */
    this.latitude = latitude

    /**
     * @type {Number}
     */
    this.longitude = longitude
  }
}
