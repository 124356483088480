export default Object.freeze({
  /**
   * Describes the entire length of a booking/shift
   */
  bookingDuration: 'bookingDuration',
  /**
   * Typically describes a lunch break
   */
  meal: 'meal',
  /**
   * Describes any other break. For CA, this will describe rest breaks
   */
  other: 'other',
})
