import { ModelApiService } from './BaseApiService'

export default class AgreementsApiService extends ModelApiService {
  constructor() {
    super('agreements')
  }

  async putAgreements(fileId) {
    return await this.put(`${fileId}`)
  }

  async downloadAgreementsFile(fileId) {
    return await this.getBlob(`${fileId}`)
  }
}
