import AddressViewModel from './addressViewModel'

/**
 * Some basic elements that make up an address
 */
export default class BaseLocationAddress {
  constructor({ address = {}, id, name } = {}) {
    /**
     * @type {Number}
     */
    this.id = id
    /**
     * Location Name
     * @type {String}
     */
    this.name = name
    /**
     * @type {AddressViewModel}
     */
    this.address = new AddressViewModel(address)
  }
}
