import OngoingAvailabilityStatus from '@/constants/candidate/OngoingAvailabilityStatus'

export default class OngoingAvailabilityViewModel {
  constructor({
    monday = OngoingAvailabilityStatus.AVAILABLE,
    tuesday = OngoingAvailabilityStatus.AVAILABLE,
    wednesday = OngoingAvailabilityStatus.AVAILABLE,
    thursday = OngoingAvailabilityStatus.AVAILABLE,
    friday = OngoingAvailabilityStatus.AVAILABLE,
    saturday = OngoingAvailabilityStatus.AVAILABLE,
    sunday = OngoingAvailabilityStatus.AVAILABLE,
  } = {}) {
    /**
     * @type {OngoingAvailabilityStatus}
     */
    this.monday = monday
    /**
     * @type {OngoingAvailabilityStatus}
     */
    this.tuesday = tuesday
    /**
     * @type {OngoingAvailabilityStatus}
     */
    this.wednesday = wednesday
    /**
     * @type {OngoingAvailabilityStatus}
     */
    this.thursday = thursday
    /**
     * @type {OngoingAvailabilityStatus}
     */
    this.friday = friday
    /**
     * @type {OngoingAvailabilityStatus}
     */
    this.saturday = saturday
    /**
     * @type {OngoingAvailabilityStatus}
     */
    this.sunday = sunday
  }
}
