/* eslint-disable no-unused-vars */
import CandidateBookingOverviewBookingItemViewModel from './candidateBookingOverviewBookingItemViewModel'
import BookingActionableItemType from '@/constants/booking/BookingActionableItemType'
import dayjs from '@/services/date/index'

export default class CandidateBookingOverviewBookingItemWithDateViewModel extends CandidateBookingOverviewBookingItemViewModel {
  constructor({
    id = null,
    locationId = null,
    clientId = null,
    groupId = null,
    actionableItems = [],
    dateLocal,
  } = {}) {
    super({
      id,
      locationId,
      clientId,
      groupId,
      actionableItems,
    })

    /**
     * @type {Date}
     */
    this.dateLocal = dateLocal ? dayjs(dateLocal) : null
  }
}
