import ComplianceItemViewModel from './complianceItemViewModel'
import { isNonEmptyArray } from '@/helpers/array-helpers'

/**
 * @class
 * @public
 */
export default class RegistrationViewModel {
  constructor({
    id,
    title,
    status,
    statusReason,
    location,
    complianceItems = [],
  } = {}) {
    /**
     * @type {Number}
     * @example 8675309
     */
    this.id = id

    /**
     * Registration's title (was formally `type`)
     * @type {String}
     * @example VIC Teacher
     */
    this.title = title

    /**
     * Indicates the status of the registration. See `RegistrationStatus` for
     * valid values
     * @type {String}
     * @example Active
     */
    this.status = status

    /**
     * Provides context behind the current status. See `RegistrationStatusReason`
     * for valid values
     * @type {String}
     * @example AwaitCandidateAction
     */
    this.statusReason = statusReason

    /**
     * Location string indicating the area the candidate is registering for
     * @type {String}
     * @example Victoria, Australia
     */
    this.location = location

    /**
     * @type {ComplianceItemViewModel}
     */
    this.complianceItems = isNonEmptyArray(complianceItems)
      ? complianceItems.map(
          (complianceItem) => new ComplianceItemViewModel(complianceItem)
        )
      : []
  }
}
