/**
 * `responseType` indicates the type of data that the server will respond with
 * @tutorial https://developer.mozilla.org/en-US/docs/Web/API/XMLHttpRequest/responseType
 */
export default Object.freeze({
  /**
   * The response is a JavaScript object created by parsing the contents of
   * received data as JSON.
   */
  json: 'json',
  /**
   * The response is a Blob object containing the binary data. (Browser only).
   * Typically used for download streamed data (e.g. files).
   */
  blob: 'blob',
  /**
   * The response is a JavaScript ArrayBuffer containing binary data.
   */
  arraybuffer: 'arraybuffer',
  /**
   * The response is an HTML Document or XML XMLDocument, as appropriate based
   * on the MIME type of the received data. See HTML in XMLHttpRequest to learn
   * more about using XHR to fetch HTML content.
   */
  document: 'document',
  /**
   * The response is a text in a string.
   */
  text: 'text',
})
