<script>
export default {
  inheritAttrs: false,
  computed: {
    commonAttributes() {
      return {}
    },
  },
}
</script>

<template>
  <v-btn v-bind="{ ...commonAttributes, ...$attrs }" v-on="$listeners">
    <slot />
  </v-btn>
</template>
