export default Object.freeze({
  /**
   * Something happened in setting up the request that triggered an Error
   */
  unknown: 'unknown',
  /**
   * The request was made and the server responded with a status code
   * that falls out of the range of 2xx
   */
  server: 'server',
  /**
   * The request was made but no response was received
   */
  request: 'request',
  /**
   * The operation failed within client-side code
   */
  clientSide: 'clientSide',
})
