export default Object.freeze({
  accent: 'accent',
  blue: 'blue',
  error: 'error',
  indigo: 'indigo',
  info: 'info',
  primary: 'primary',
  red: 'red',
  secondary: 'secondary',
  success: 'success',
  warning: 'warning',
  grey: 'grey',
})
