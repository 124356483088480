import Vue from 'vue'
import { getSavedState } from '@helpers/cache-helpers.js'
import configFactory from './common/configFactory'
import Environment from '@/constants/core/Environment.js'

// Don't warn about using the dev version of Vue in development.
Vue.config.productionTip = process.env.NODE_ENV === Environment.production

// If running inside Cypress...
if (process.env.VUE_APP_TEST === Environment.e2e) {
  // Ensure tests fail when Vue emits an error.
  Vue.config.errorHandler = window.Cypress.cy.onUncaughtException
}

// Prevent users from being flashbanged by white screen if using dark mode
if (getSavedState('darkMode'))
  document.getElementById('app-body').style = 'background: #121212'

// Ensure config is loaded before Vue is init
configFactory().then((configResponse) => {
  // Store config as a global
  Vue.prototype.$config = configResponse

  const config = require('@/common/config').default

  // Plugins are loaded using require to ensure import occurs after config
  const router = require('@router').default
  const store = require('@/store').default
  const vuetify = require('@plugins/vuetify').default
  require('@plugins/vee-validate')
  require('@plugins/vue-mq')
  require('@plugins/vue-toastification')
  const i18n = require('@plugins/vue-i18n').default
  const firebase = require('@plugins/firebase').default
  // const signalRHub = require('@plugins/signalr-hub.js').default
  const App = require('./app.vue').default
  const { getAuth, onAuthStateChanged } = require('firebase/auth')
  const appinsights = require('@/plugins/appinsights').default

  const sanitizeHtml = require('@/utils/sanitize-html').default

  // Globally register all `_base`-prefixed components
  require('@components/_globals')

  // TODO: Uncomment hub when SignalR has been implemented
  // Vue.use(signalRHub)

  if (config.env === Environment.production)
    Vue.use(appinsights, {
      appInsightsConfig: config.appInsightsConfig,
      router,
    })

  require('@/plugins/exception-handler')

  // Filters
  const truncateText = require('@/filters/truncate-text').default
  Vue.filter('truncateText', truncateText)

  // Custom directives
  Vue.directive('sanitized-html', (el, binding) => {
    el.innerHTML = sanitizeHtml(binding.value)
  })

  let app

  const auth = getAuth(firebase)

  // Ensure firebase has loaded before app does
  onAuthStateChanged(auth, () => {
    // Init feature toggles
    store.dispatch(
      'features/setFeatureToggles',
      config.get('app.featureToggles')
    )

    if (!app)
      app = new Vue({
        router,
        store,
        vuetify,
        i18n,
        render: (h) => h(App),
      }).$mount('#app')
  })

  // Handle auth redirect on app load
  if (store.getters['auth/hasImpersonateCandidateId'])
    store.getters['auth/msalInstance']
      .handleRedirectPromise()
      .then((response) => {
        return store.dispatch('auth/handleRedirect', response)
      })
      .catch(() => {})

  // If running e2e tests...
  if (process.env.VUE_APP_TEST === Environment.e2e) {
    // Attach the app to the window, which can be useful
    // for manually setting state in Cypress commands
    // such as `cy.logIn()`.
    window.__app__ = app
  }
})
