/**
 * @class
 * @public
 */
export default class HttpRequestUrl {
  constructor({
    baseUrl = null,
    version = null,
    subPath = '',
    query = null,
  } = {}) {
    /**
     * Request base url e.g. http://localhost:8080
     * @type {String}
     */
    this.baseUrl = baseUrl

    /**
     * Api version appended after base path e.g. v1.0
     * @type {String}
     */
    this.version = version

    /**
     * Url subdirectory. e.g. "profile/8675309".
     * NB: the base path (the one passed into super() in the api services) is not
     * required for the subPath. Also don't include a leading slash
     * @type {String}
     */
    this.subPath = subPath

    /**
     * Url query arguments. Keypair object e.g. { test: true, q: 4 }
     * @type {Object}
     */
    this.query = query
  }
}
