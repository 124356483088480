/* eslint-disable no-unused-vars */
import CandidateBookingNavigationItemViewModel from './candidateBookingNavigationItemViewModel'

export default class CandidateBookingNavigationViewModel {
  constructor({
    next = new CandidateBookingNavigationItemViewModel(),
    previous = new CandidateBookingNavigationItemViewModel(),
  } = {}) {
    /**
     * @type {CandidateBookingNavigationItemViewModel}
     */
    this.next = next

    /**
     * @type {CandidateBookingNavigationItemViewModel}
     */
    this.previous = previous
  }
}
