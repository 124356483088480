/* eslint-disable no-unused-vars */
import AccessGuiFileViewModel from '../file/accessGuiFileViewModel'
import CertificationCustomFieldVM from './certificationCustomFieldVM'
import CertificationTypeVM from './certificationTypeVM'
import dayjs from '@/services/date/index'
import { isNonEmptyArray } from '@/helpers/array-helpers'

/**
 * @class
 * @public
 */
export default class CertificationDetailsVM {
  /**
   * @constructor
   */
  constructor({
    id,
    expireStatus = '',
    ignoreExpiry = false,
    dateExpiry = null,
    dateObtained = null,
    isVerified = false,
    note = '',
    certRefNo = '',
    customFields = [],
    certificationType = null,
    files = [],
  } = {}) {
    /**
     * @type {Number}
     */
    this.id = id

    /**
     * @type {String}
     * @see {ExpireStatus} for valid value range
     */
    this.expireStatus = expireStatus

    /**
     * @type {Boolean}
     */
    this.ignoreExpiry = ignoreExpiry

    /**
     * @type {Date}
     */
    this.dateExpiry = dateExpiry ? dayjs(dateExpiry) : null

    /**
     * @type {Date}
     */
    this.dateObtained = dateObtained ? dayjs(dateObtained) : null

    /**
     * @type {Boolean}
     */
    this.isVerified = isVerified

    /**
     * @type {String}
     */
    this.note = note

    /**
     * @type {String}
     */
    this.certRefNo = certRefNo

    /**
     * @type {Array<CertificationCustomFieldVM>}
     */
    this.customFields = isNonEmptyArray(customFields)
      ? customFields.map(
          (customField) => new CertificationCustomFieldVM(customField)
        )
      : []

    /**
     * @type {CertificationTypeVM}
     */
    this.certificationType = new CertificationTypeVM(certificationType)

    /**
     * @type {Array<AccessGuiFileViewModel>}
     */
    this.files = isNonEmptyArray(files)
      ? files.map((file) => new AccessGuiFileViewModel(file))
      : []
  }
}
