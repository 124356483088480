/**
 * @class
 * @public
 */
export default class WorkExperienceSummaryModel {
  /**
   * @constructor
   * @param {Object<id: Number, placeOfWork: String, positionHeld: String, dateFrom: Date, dateUntil: Date, employmentDays: Number, isCurrent: Boolean, isVerified: Boolean>} param0
   */
  constructor({
    id,
    placeOfWork = null,
    positionHeld = null,
    dateFrom = null,
    dateUntil = null,
    employmentDays = 0,
    isCurrent = false,
    isVerified = false,
  } = {}) {
    /**
     * @type {Number}
     */
    this.id = id

    /**
     * @type {String}
     */
    this.placeOfWork = placeOfWork

    /**
     * @type {String}
     */
    this.positionHeld = positionHeld

    /**
     * @type {Date}
     */
    this.dateFrom = dateFrom

    /**
     * @type {Date}
     */
    this.dateUntil = dateUntil

    /**
     * The number of days this work experience is worth
     * @type {Number}
     */
    this.employmentDays = employmentDays

    /**
     * Indicates if candidate is currently in role
     * @type {Boolean}
     */
    this.isCurrent = isCurrent

    /**
     * Indicates if this record has been verified by a consultant
     * @type {Boolean}
     */
    this.isVerified = isVerified
  }
}
