/**
 * @class
 * @public
 */
export default class BookingDetailsGuestAccessRequestDTO {
  constructor({ shortTermTentativeId, accessGuid } = {}) {
    /**
     * @type {Guid}
     */
    this.shortTermTentativeId = shortTermTentativeId
    /**
     * @type {Guid}
     */
    this.accessGuid = accessGuid
  }
}
