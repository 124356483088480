<script>
import { mapGetters, mapActions } from 'vuex'
import DebugLogDialog from './debug-log-dialog.vue'
import baseMixin from '@/mixins/baseMixin'

export default {
  name: 'TheDebugBarActions',
  components: { DebugLogDialog },
  mixins: [baseMixin],
  data() {
    return {
      debugToggle: this.isDebug,
      showDebugLog: false,
    }
  },
  computed: {
    ...mapGetters({
      serverError: 'isError',
    }),
  },
  mounted() {
    this.debugToggle = this.isDebug
  },
  methods: {
    ...mapActions({
      toggleDebug: 'toggleDebugMode',
    }),
    toggleDebugMode() {
      this.toggleDebug({ persistent: true })
    },
  },
}
</script>

<template>
  <section v-if="isDev">
    <article
      :class="[
        'd-flex flex-columns debug-bar__mono',
        isMobileViewPort ? 'debug-bar__mono--mobile' : '',
      ]"
    >
      <section class="mx-2 white--text">
        <a class="white--text" @click="toggleDebugMode">
          {{ isDebug ? '[x]' : '[ ]' }} Enable Debug Mode
        </a>
      </section>

      <section class="mx-2 white--text">
        <a class="white--text" @click="showDebugLog = !showDebugLog">
          {{ showDebugLog ? '[x]' : '[ ]' }} Show Debug Log
        </a>
      </section>
    </article>

    <v-dialog
      v-model="showDebugLog"
      fullscreen
      hide-overlay
      persistent
      no-click-animation
      transition="dialog-bottom-transition"
      @input="showDebugLog = false"
    >
      <DebugLogDialog @close="showDebugLog = false" />
    </v-dialog>
  </section>
</template>
