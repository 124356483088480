export default Object.freeze({
  /**
   * Denotes that the associated feature is enabled
   */
  enabled: 'enabled',
  /**
   * Denotes that the associated feature is disabled
   */
  disabled: 'disabled',
  /**
   * Used by route feature toggles. Will redirect user to under construction page if set.
   */
  underConstruction: 'underConstruction',
})
