/**
 * Thrown when a paramter expected to be set was passed in null or undefined
 */
export default class NullParameterError extends Error {
  /**
   * @param {String} name name of parameter
   */
  constructor({ message = null, name = null }) {
    super(message || `Required parameter was null or undefined: '${name}'`)
    this.name = 'NullParameterError'
  }
}
