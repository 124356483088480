import axios from 'axios'
import appConfig from '@/common/config'
import store from '@/store'
import { buildURL, buildAPIBasePath } from '@/helpers/url-helpers'
import HttpMethods from '@/constants/api/HttpMethods'
import HttpRequestConfig from '@/models/api/httpRequestConfig'
import HttpRequestUrl from '@/models/api/httpRequestUrl'
import { success } from '@/helpers/result-helper'
import HttpResponseType from '@/constants/api/HttpResponseType'
import HttpHeaders from '@/constants/api/HttpHeaders'
// eslint-disable-next-line no-unused-vars
import ResultDTO from '@/models/app/resultDTO'
import AxiosRequestConfig from '@/models/api/axiosRequestConfig'
import HttpStatusCodes from '@/constants/api/HttpStatusCodes'
import router from '@/router/index'
import routeDefinitions from '@/constants/routes/routeDefinitions'

class BaseApiService {
  /**
   * Api version (e.g. 1.0). Can be overridden in HttpResponseConfig object
   * @type {String}
   */
  apiVersion = appConfig.get('apiVersion')

  /**
   * Configured base URL. Can be overridden in HttpResponseConfig object
   * @type {String}
   */
  baseUrl = appConfig.get('apiUrl')

  /**
   * Axios httpClient
   */
  httpClient = axios.create({
    json: true,
  })

  /**
   * A particular resource, e.g. users, posts, comments etc.
   * @type {String}
   */
  resource

  /**
   * @constructor
   * @param {String} resource Controller base path e.g. 'users'
   */
  constructor(resource) {
    this.resource = resource
  }

  /**
   * Generates a URL for the requested endpoint
   * @param {HttpRequestUrl} options
   * @returns {URL}
   */
  getUrl(options) {
    const apiUrl = buildAPIBasePath(
      options.baseUrl || this.baseUrl,
      options.version || this.apiVersion,
      this.resource,
      options.subPath
    )

    return buildURL(apiUrl, options.query)
  }

  /**
   * Handles HTTP response errors
   * @param {Error} err
   * @returns
   */
  async handleErrors(err) {
    switch (err?.response?.status) {
      // If unauthorised, renew access token then retry
      case HttpStatusCodes.Unauthorized: {
        await store.dispatch('auth/getAccessTokenOrRefresh', true)
        return {
          retry: true,
          err,
        }
      }
      case HttpStatusCodes.Forbidden: {
        if (err.response?.data?.code === 'account_suspended') {
          await store.dispatch(
            'logException',
            new Error("The candidate's account has been suspended.")
          )

          await router.push({
            name: routeDefinitions.accountSuspended.name,
            params: [window.location.pathname],
            replace: true,
          })
        }
        break
      }
    }

    throw err
  }

  /**
   * Will attempt to get an auth token or refresh one then generate an auth header object
   * @returns
   */
  async generateAuthHeader() {
    const accessToken = await store.dispatch('auth/getAccessTokenOrRefresh')

    if (typeof accessToken === 'undefined' || !accessToken)
      throw Error('An access token is required for authenticated requests')

    return {
      [HttpHeaders.authorization]: `Bearer ${accessToken}`,
    }
  }

  /**
   * Generates a dictionary object of request headers
   * @param {HttpRequestConfig} options
   */
  async compileHeaders(options) {
    let impersonateHeader = {}
    let authorizationHeader = {}
    const acceptVersionheader = { [HttpHeaders.acceptVersion]: this.apiVersion }

    // Set impersonate header if impersonate id is present
    if (store.getters['auth/hasImpersonateCandidateId'])
      impersonateHeader = {
        [HttpHeaders.impersonatedCandidate]:
          store.getters['auth/impersonateCandidateId'],
      }

    if (options.isAuthenticated)
      authorizationHeader = await this.generateAuthHeader()

    const headers = {
      ...options.headers,
      ...acceptVersionheader,
      ...authorizationHeader,
      ...impersonateHeader,
    }

    return headers
  }

  /**
   * Compiles request configuration and handles tasks like generating headers
   * list and retrieving the auth token
   * @param {HttpRequestConfig} options
   * @returns {Promise<AxiosRequestConfig>} Request config object
   */
  async compileRequestConfig(options) {
    const headers = await this.compileHeaders(options)

    return new AxiosRequestConfig({
      method: options.method,
      url: this.getUrl(options.url),
      headers,
      responseType: options.responseType,
      data: options.data,
    })
  }

  /**
   * Generates httpClient config and calls the httpClient with that config
   * @param {HttpRequestConfig} options
   */
  async makeHttpRequest(options) {
    const config = await this.compileRequestConfig(options)
    return await this.httpClient(config)
  }

  /**
   * Executes a HTTP request
   * @param {HttpRequestConfig} options
   * @param {Boolean} retry Indicates to recursive call if in retry step to prevent infinite retry loop
   * @returns {Promise<ResultDTO>}
   */
  async execute(options, retry = false) {
    try {
      /**
       * @type {data: Object, status: Number, headers: Object}
       */
      const response = await this.makeHttpRequest(options)
      return success({
        data: response.data,
        statusCode: response.status,
        headers: response.headers,
      })
    } catch (ex) {
      const response = await this.handleErrors(ex)

      // Only retry request if haven't already required request before
      if (response.retry && !retry) return await this.execute(options, true)

      throw response.err
    }
  }
}

class ReadOnlyApiService extends BaseApiService {
  /**
   * HTTP Get authenticated request
   * @param {String} subPath Url subdirectory. e.g. "profile-image/8675309"
   * @param {*} query query object {key: value}
   * @returns {Promise<ResultDTO>}
   */
  async get(subPath, query) {
    return this.execute(
      new HttpRequestConfig({
        method: HttpMethods.GET,
        url: new HttpRequestUrl({ subPath, query }),
      })
    )
  }

  /**
   * HTTP Get unauthenticated request
   * @param {String} subPath Url subdirectory. e.g. "profile-image/8675309"
   * @param {*} query query object {key: value}
   * @returns {Promise<ResultDTO>}
   */
  async getAnon(subPath, query) {
    return this.execute(
      new HttpRequestConfig({
        method: HttpMethods.GET,
        url: new HttpRequestUrl({ subPath, query }),
        isAuthenticated: false,
      })
    )
  }

  /**
   * HTTP Get authenticated request - Response type: Blob
   * @param {String} subPath Url subdirectory. e.g. "profile-image/8675309"
   * @param {*} query query object {key: value}
   * @returns {Promise<ResultDTO>}
   */
  async getBlob(subPath, query) {
    return this.execute(
      new HttpRequestConfig({
        method: HttpMethods.GET,
        url: new HttpRequestUrl({ subPath, query }),
        responseType: HttpResponseType.blob,
      })
    )
  }
}

class ModelApiService extends ReadOnlyApiService {
  /**
   * HTTP Post authenticated request
   * @param {String} subPath Url subdirectory. e.g. "profile-image/8675309"
   * @param {*} data Payload
   * @param {*} query query object {key: value}
   * @returns {Promise<ResultDTO>}
   */
  async post(subPath, data = {}, query = null) {
    return this.execute(
      new HttpRequestConfig({
        method: HttpMethods.POST,
        url: new HttpRequestUrl({ subPath, query }),
        data,
      })
    )
  }

  /**
   * HTTP Post unauthenticated request
   * @param {String} subPath Url subdirectory. e.g. "profile-image/8675309"
   * @param {*} data Payload
   * @param {*} query query object {key: value}
   * @returns {Promise<ResultDTO>}
   */
  async postAnon(subPath, data = {}, query = null) {
    return this.execute(
      new HttpRequestConfig({
        method: HttpMethods.POST,
        url: new HttpRequestUrl({ subPath, query }),
        data,
        isAuthenticated: false,
      })
    )
  }

  /**
   * HTTP Post authenticated request - Response type: Blob
   * @param {String} subPath Url subdirectory. e.g. "profile-image/8675309"
   * @param {*} data Payload
   * @param {*} query query object {key: value}
   * @returns {Promise<ResultDTO>}
   */
  async postBlob(subPath, data = {}, query = null) {
    return this.execute(
      new HttpRequestConfig({
        method: HttpMethods.POST,
        url: new HttpRequestUrl({ subPath, query }),
        data,
        responseType: HttpResponseType.blob,
      })
    )
  }

  /**
   * HTTP Put authenticated request
   * @param {String} subPath Url subdirectory. e.g. "profile-image/8675309"
   * @param {*} data Payload
   * @param {*} query query object {key: value}
   * @returns {Promise<ResultDTO>}
   */
  async put(subPath, data = {}, query = null) {
    return this.execute(
      new HttpRequestConfig({
        method: HttpMethods.PUT,
        url: new HttpRequestUrl({ subPath, query }),
        data,
      })
    )
  }

  /**
   * HTTP Put unauthenticated request
   * @param {String} subPath Url subdirectory. e.g. "profile-image/8675309"
   * @param {*} data Payload
   * @param {*} query query object {key: value}
   * @returns {Promise<ResultDTO>}
   */
  async putAnon(subPath, data = {}, query = null) {
    return this.execute(
      new HttpRequestConfig({
        method: HttpMethods.PUT,
        url: new HttpRequestUrl({ subPath, query }),
        data,
        isAuthenticated: false,
      })
    )
  }

  /**
   * HTTP Put authenticated request - Response type: Blob
   * @param {String} subPath Url subdirectory. e.g. "profile-image/8675309"
   * @param {*} data Payload
   * @param {*} query query object {key: value}
   * @returns {Promise<ResultDTO>}
   */
  async putBlob(subPath, data = {}, query = null) {
    return this.execute(
      new HttpRequestConfig({
        method: HttpMethods.PUT,
        url: new HttpRequestUrl({ subPath, query }),
        data,
        responseType: HttpResponseType.blob,
      })
    )
  }

  /**
   * HTTP Patch authenticated request
   * @param {String} subPath Url subdirectory. e.g. "profile-image/8675309"
   * @param {*} data Payload
   * @param {*} query query object {key: value}
   * @returns {Promise<ResultDTO>}
   */
  async patch(subPath, data = {}, query = null) {
    return this.execute(
      new HttpRequestConfig({
        method: HttpMethods.PATCH,
        url: new HttpRequestUrl({ subPath, query }),
        data,
      })
    )
  }

  /**
   * HTTP Patch unauthenticated request
   * @param {String} subPath Url subdirectory. e.g. "profile-image/8675309"
   * @param {*} data Payload
   * @param {*} query query object {key: value}
   * @returns {Promise<ResultDTO>}
   */
  async patchAnon(subPath, data = {}, query = null) {
    return this.execute(
      new HttpRequestConfig({
        method: HttpMethods.PATCH,
        url: new HttpRequestUrl({ subPath, query }),
        data,
        isAuthenticated: false,
      })
    )
  }

  /**
   * HTTP Delete authenticated request
   * @param {String} subPath Url subdirectory. e.g. "profile-image/8675309"
   * @param {*} data Payload
   * @param {*} query query object {key: value}
   * @returns {Promise<ResultDTO>}
   */
  async delete(subPath, query = null) {
    return this.execute(
      new HttpRequestConfig({
        method: HttpMethods.DELETE,
        url: new HttpRequestUrl({ subPath, query }),
      })
    )
  }

  /**
   * HTTP Delete unauthenticated request
   * @param {String} subPath Url subdirectory. e.g. "profile-image/8675309"
   * @param {*} data Payload
   * @param {*} query query object {key: value}
   * @returns {Promise<ResultDTO>}
   */
  async deleteAnon(subPath, query = null) {
    return this.execute(
      new HttpRequestConfig({
        method: HttpMethods.DELETE,
        url: new HttpRequestUrl({ subPath, query }),
        isAuthenticated: false,
      })
    )
  }
}

export { ReadOnlyApiService, ModelApiService }
