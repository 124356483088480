/* eslint-disable no-unused-vars */
import ComplianceItemReferenceType from '@/constants/registrations/ComplianceItemReferenceType'
import ComplianceItemType from '@/constants/registrations/ComplianceItemType'
import ComplianceItemStatus from '@/constants/registrations/ComplianceItemStatus'

/**
 * @class
 * @public
 */
export default class ComplianceItemViewModel {
  constructor({
    id,
    referenceId,
    referenceFileId,
    status,
    title,
    referenceType,
    type,
    description,
  } = {}) {
    /**
     * @type {Number}
     * @example 8675309
     */
    this.id = id
    /**
     * This value depends on the type (ComplianceItemReferenceType) as to what it represents.
     * For example, if the type is UploadedFile, the referenceId relates to a fileTypeId
     * @type {Number}
     * @example 14
     * @see {ComplianceItemReferenceType}
     */
    this.referenceId = referenceId
    /**
     * This value is also depends on the type (ComplianceItemReferenceType) as to what it represents.
     * For example, if the type is Certification, it relates to a Candidate.Certification record.
     * This value will be used to load the related file's details on compliance items that are
     * awaiting verification.
     * @type {Number}
     * @example 8675309
     */
    this.referenceFileId = referenceFileId
    /**
     * Relates to the current status of the compliance item
     * @type {String}
     * @example RequiresCandidateAction
     * @see {ComplianceItemStatus} for valid status values
     */
    this.status = status
    /**
     * @type {String}
     */
    this.title = title
    /**
     * Type of compliance item
     * @type {String}
     * @example UploadedFile
     * @see {ComplianceItemReferenceType} for valid type values
     */
    this.referenceType = referenceType
    /**
     * The grouping/collection of compliance item this time belongs to. Directly relates to the tabs on the compliance list.
     * @type {String}
     * @example document
     * @see {ComplianceItemType} for valid type values
     */
    this.type = type
    /**
     * @type {String}
     */
    this.description = description
  }
}
