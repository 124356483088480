/* eslint-disable no-unused-vars */
import ComplianceItemStatusUpdateDto from './complianceItemStatusUpdateDto'

/**
 * @class
 * @public
 */
export default class DocumentComplianceItemStatusUpdateDto extends ComplianceItemStatusUpdateDto {
  constructor({ complianceItemId, referenceFileId = null, status } = {}) {
    super({ complianceItemId, status })
    /**
     * @type {Number}
     */
    this.referenceFileId = referenceFileId
  }
}
