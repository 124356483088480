/* eslint-disable no-unused-vars */
import CandidateBookingSummaryViewModel from './candidateBookingSummaryViewModel'
import { isNonEmptyArray } from '@/helpers/array-helpers'

/**
 * @class
 * @public
 */
export default class CandidateBookingSummaryWrapperViewModel {
  constructor({ bookings = [] } = {}) {
    /**
     * @type {Array<CandidateBookingSummaryViewModel>}
     */
    this.bookings = isNonEmptyArray(bookings)
      ? bookings.map((booking) => new CandidateBookingSummaryViewModel(booking))
      : []
  }
}
