<script>
import baseMixin from '@/mixins/baseMixin'

export default {
  name: 'SkeletonBaseListSelect',
  mixins: [baseMixin],
  props: {
    count: {
      type: Number,
      default: 10,
    },
  },
}
</script>

<template>
  <v-list class="transparent">
    <v-list-item
      v-for="n of count"
      :key="`skeleton-base-list-select__list-item-${n}`"
    >
      <v-list-item-content>
        <v-list-item-title :class="isMobileViewPort ? 'caption' : ''">
          <v-skeleton-loader type="text" width="70%"></v-skeleton-loader>
        </v-list-item-title>
      </v-list-item-content>

      <v-list-item-action>
        <v-icon>mdi-chevron-right</v-icon>
      </v-list-item-action>
    </v-list-item>

    <v-divider></v-divider>
  </v-list>
</template>
