export const RegistrationStatusReason = Object.freeze({
  // Active
  OK: 'OK',
  ITEMS_EXPIRED: 'ItemsExpired',
  ITEMS_EXPIRING: 'ItemsExpiring',

  // Pending
  NO_CHECKLIST: 'NoChecklist',
  AWAIT_CERT_OR_DOC: 'AwaitCertOrDoc',
  AWAITING_CANDIDATE_ACTION: 'AwaitCandidateAction',
  AWAITING_CONSULTANT_ACTION: 'AwaitConsultantAction',
})
