/**
 * @class
 * @public
 */
export default class AccountCreationDTO {
  constructor({ token, password } = {}) {
    /**
     * Encrypted token
     * @type {String}
     */
    this.token = token

    /**
     * @type {String}
     */
    this.password = password
  }
}
