// Firebase App (the core Firebase SDK) is always required and must be listed first
import { initializeApp } from 'firebase/app'
import config from '@/common/config'

// Add the Firebase products that you want to use
import 'firebase/auth'

// If you enabled Analytics in your project, add the Firebase SDK for Analytics
// import "firebase/analytics";

// firebase init goes here
const app = initializeApp(config.get('firebase'))

// firebase utils
export default app
