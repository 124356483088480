import i18n from '@plugins/vue-i18n'
import { RegistrationStatus } from '@/constants/registrations/RegistrationStatus'
import { RegistrationStatusReason } from '@/constants/registrations/RegistrationStatusReason'
import { Status } from '@/constants/status/Status'
import ComplianceItemStatus from '@/constants/registrations/ComplianceItemStatus'
import VuetifyColourClass from '@/constants/core/VuetifyColourClass'

/**
 * getStatusLabelHashMap: Returns a hash map of all the available status labels
 * @returns
 */
const getStatusLabelHashMap = function () {
  const map = new Map()

  map.set(Status.UNKNOWN, {
    title: i18n.t('status.unknown'),
    color: 'grey',
    icon: 'mdi-help-circle-outline',
  })
  map.set(RegistrationStatus.IN_PROGRESS, {
    title: i18n.t('status.inProgress'),
    color: 'primary',
    icon: 'mdi-progress-pencil',
  })
  map.set(RegistrationStatus.ACTIVE, {
    title: i18n.t('status.active'),
    color: 'success',
    icon: 'mdi-check-circle-outline',
  })
  map.set(RegistrationStatus.COMPLETE, {
    title: i18n.t('status.complete'),
    color: 'success',
    icon: 'mdi-check-circle-outline',
  })
  map.set(RegistrationStatus.PENDING, {
    title: i18n.t('status.pending'),
    color: 'primary',
    icon: 'mdi-progress-clock',
  })
  map.set(RegistrationStatus.AWAITING_VERIFICATION, {
    title: i18n.t('status.awaitingVerification'),
    color: 'warning',
    icon: 'mdi-progress-clock',
  })
  map.set(RegistrationStatus.DELETED, {
    title: i18n.t('status.deleted'),
    color: 'error',
    icon: 'mdi-close-circle-outline',
  })
  map.set(RegistrationStatus.TERMINATED, {
    title: i18n.t('status.terminated'),
    color: 'error',
    icon: 'mdi-close-circle-outline',
  })
  map.set(RegistrationStatusReason.NO_CHECKLIST, {
    title: i18n.t('status.noChecklist'),
    color: 'warning',
    icon: 'mdi-alert-circle-outline',
  })
  map.set(RegistrationStatusReason.ITEMS_EXPIRED, {
    title: i18n.t('status.itemsExpired'),
    color: 'error',
    icon: 'mdi-alert-circle-outline',
  })
  map.set(RegistrationStatusReason.ITEMS_EXPIRING, {
    title: i18n.t('status.itemsExpiring'),
    color: 'warning',
    icon: 'mdi-alert-circle-outline',
  })
  map.set(RegistrationStatusReason.AWAIT_CERT_OR_DOC, {
    title: i18n.t('status.awaitCertOrDoc'),
    color: 'warning',
    icon: 'mdi-progress-pencil',
  })
  map.set(RegistrationStatusReason.AWAITING_CANDIDATE_ACTION, {
    title: i18n.t('status.awaitCandidateAction'),
    color: 'warning',
    icon: 'mdi-progress-pencil',
  })
  map.set(RegistrationStatusReason.AWAITING_CONSULTANT_ACTION, {
    title: i18n.t('status.awaitConsultantAction'),
    color: 'blue',
    icon: 'mdi-clock-outline',
  })
  map.set(Status.UNVERIFIED, {
    title: i18n.t('status.unverified'),
    color: 'warning',
    icon: 'mdi-alert-circle-outline',
  })
  map.set(Status.VERIFIED, {
    title: i18n.t('status.verified'),
    color: 'success',
    icon: 'mdi-check-circle-outline',
  })

  return map
}

const getComplianceItemStatusLabelHasMap = () => {
  const map = new Map()

  map.set(Status.UNKNOWN, {
    title: i18n.t('status.unknown'),
    color: VuetifyColourClass.grey,
    icon: 'mdi-help-circle-outline',
  })
  map.set(ComplianceItemStatus.RequiresCandidateAction, {
    title: i18n.t(
      'registrationDetails.complianceItem.status.requiresCandidateAction'
    ),
    color: VuetifyColourClass.warning,
    icon: 'mdi-progress-pencil',
  })
  map.set(ComplianceItemStatus.Complete, {
    title: i18n.t('registrationDetails.complianceItem.status.complete'),
    color: VuetifyColourClass.success,
    icon: 'mdi-check-circle-outline',
  })
  map.set(ComplianceItemStatus.CompleteByUnlinked, {
    title: i18n.t('registrationDetails.complianceItem.status.completeByUnlink'),
    color: VuetifyColourClass.success,
    icon: 'mdi-check-circle-outline',
    tooltip: i18n.t(
      'registrationDetails.complianceItem.status.completeByUnlinkTooltip'
    ),
  })
  map.set(ComplianceItemStatus.AwaitingVerification, {
    title: i18n.t(
      'registrationDetails.complianceItem.status.awaitingVerification'
    ),
    color: VuetifyColourClass.primary,
    icon: 'mdi-progress-clock',
  })
  map.set(ComplianceItemStatus.AwaitingVerificationForUnlinked, {
    title: i18n.t(
      'registrationDetails.complianceItem.status.awaitingVerificationForUnlinked'
    ),
    color: VuetifyColourClass.primary,
    icon: 'mdi-progress-clock',
    tooltip: i18n.t(
      'registrationDetails.complianceItem.status.awaitingVerificationForUnlinkedTooltip'
    ),
  })

  return map
}

export { getStatusLabelHashMap, getComplianceItemStatusLabelHasMap }
