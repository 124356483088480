import dayjs from '@/services/date'

/**
 * Used for caching blob objects
 */
export default class BlobCacheDTO {
  constructor({ blob, id, timestamp = dayjs() } = {}) {
    /**
     * @type {Blob}
     */
    this.blob = blob

    /**
     * Reference to blob object
     * @type {String}
     */
    this.id = id

    /**
     * Date cached
     * @type {Date}
     */
    this.timestamp = timestamp
  }
}
