/**
 * Code sourced from https://github.com/arunredhu/vuejs_boilerplate/blob/master/src/app/shared/services/app-logger/app-logger.js
 * on 24/10/2022 9:52 AM
 */

/* eslint no-console: ["off"] */
import Vue from 'vue'
import toast from '@services/toasts/index.js'
import { nanoid } from 'nanoid'
import store from '@/store'
import config from '@/common/config'
import UnknownAppError from '@/models/error/unknownAppError'
import VueErrorDTO from '@/models/error/vueErrorDTO'
import WindowErrorDTO from '@/models/error/windowErrorDTO'
import Environment from '@/constants/core/Environment'
import { compileCustomProperties } from '@/helpers/log-helper'
import PromiseRejectionError from '@/models/error/promiseRejectionError'
import dayjs from '@/services/date'
import ErrorResponseDTO from '@/models/error/errorResponseDTO'

/**
 * @description Logger class
 * This is responsible for logging of all kinds of info in the application
 * Default, we are using the console api for logging and this provides the basic level of logging such as
 * you can use the available method of console in developement but in production these will be replaced with empty methods
 * This can be extended with the help of adding Log level functionality
 */
class AppLogger {
  /**
   * @constructor AppLogger
   */
  constructor() {
    /** Initializing the configuration of logger */
    this.initLogger()
  }

  /**
   * @description Initializing the configuration such as if environment is production then all log method will be replaced with empty methods
   * except logError, which will be responsible for logging the important info on server or logging service
   */
  initLogger() {
    // Checking the environment
    if (config.get('env') !== Environment.production) {
      this.log = console.log.bind(console)

      this.debug = console.debug.bind(console)

      this.info = console.info.bind(console)

      this.warn = console.warn.bind(console)

      this.error = console.error.bind(console)

      this.logError = (err) => {
        if (!err) return this.warn('AppLogger: Error received was empty')

        this.error(err.error, err)

        const message = `${err.error?.name ?? 'Error'}: ${err.error?.message}`

        store.commit('ADD_DEBUG_MESSAGE', {
          exception: err.error,
          date: dayjs().format('llll'),
          message,
          id: nanoid(),
        })
        if (store.getters.isDebugModeActive) toast.debug(message)
      }
    } else {
      // In case of production replace the functions definition
      this.log = this.debug = this.info = this.warn = this.error = () => {}

      this.logError = (err) => {
        switch (true) {
          case err instanceof VueErrorDTO: {
            const properties = compileCustomProperties(
              store,
              {
                info: err.info,
                route: err.vm?.$route?.name,
                component:
                  err.vm?.$options?.name ||
                  'Name prop not set for this component',
              },
              true
            )

            Vue.prototype.$appInsights.trackException({
              exception: err.error,
              properties,
            })
            break
          }

          case err instanceof WindowErrorDTO: {
            const properties = compileCustomProperties(store, err, true)

            Vue.prototype.$appInsights.trackException({
              exception: err.error,
              properties,
            })
            break
          }

          case err instanceof PromiseRejectionEvent: {
            const properties = compileCustomProperties(
              store,
              {
                reason: err?.reason,
                type: err?.type,
              },
              true
            )

            Vue.prototype.$appInsights.trackException({
              exception: new PromiseRejectionError(err.reason),
              properties,
            })
            break
          }
          case err instanceof ErrorResponseDTO: {
            const properties = compileCustomProperties(
              store,
              {
                errorResponse: {
                  source: err.source,
                  type: err.type,
                  message: err.message,
                },
              },
              true
            )

            Vue.prototype.$appInsights.trackException({
              exception: err.error,
              properties,
            })
            break
          }

          default: {
            const properties = compileCustomProperties(
              store,
              {
                error: err,
              },
              true
            )

            Vue.prototype.$appInsights.trackException({
              exception: new UnknownAppError(),
              properties,
            })
            break
          }
        }
      }
    }
  }
}

/** Creating the instance of logger */
const logger = new AppLogger()

export { logger }
