import { extractFileNameFromContentDisposition } from '@helpers/file-helpers'
import { getType } from 'mime'
import { fail, success } from '@/helpers/result-helper.js'
import { isCacheFresh } from '@/helpers/cache-helpers'
import DurationUnits from '@/constants/date/DurationUnits.js'
import toast from '@/services/toasts/index.js'
import dayjs from '@/services/date/index.js'
import { processFilesForUpload } from '@/helpers/file-helpers'
import QualificationDetailsViewModel from '@/models/qualification/qualificationDetailsViewModel'
import HttpHeaders from '@/constants/api/HttpHeaders'
import { isNonEmptyArray } from '@/helpers/array-helpers'
import { QualificationActionErrorMessageFactory } from '@/services/qualifications/QualificationActionErrorMessageFactory'
import QualificationUploadDTO from '@/models/qualification/qualificationUploadDTO'
import DateFormatToken from '@/constants/date/DateFormatToken'

const getDefaultState = () => {
  return {
    // Place any new state properties here
    qualifications: null,
    loadingCount: 0,
    loadingQualificationsCount: 0,
    loadingDetailsCount: 0,
    crudLoadingCount: 0,
    loadingDeleteCount: 0,
    loadingFile: [],
  }
}

const state = getDefaultState()

export default {
  namespaced: true,
  /**
   * @typedef {{
   * qualifications: QualificationDetailsViewModel
   * loadingCount: Number
   * loadingQualificationsCount: Number
   * loadingDetailsCount: Number
   * crudLoadingCount: Number
   * loadingDeleteCount: Number
   * loadingFile: Array<String>
   * }} QualificationState
   * @type {QualificationState}
   */
  state,
  getters: {
    moduleName: () => 'qualifications',
    qualifications: (state) => state.qualifications?.list,
    isLoading: (state) => state.loadingCount > 0,
    isLoadingQualifications: (state) => state.loadingQualificationsCount > 0,
    isLoadingDetails: (state) => state.loadingDetailsCount > 0,
    isLoadingCRUD: (state) => state.crudLoadingCount > 0,
    isLoadingDelete: (state) => state.loadingDeleteCount > 0,
    getDownloadFileLoadingState: (state) => (accessGui) =>
      state.loadingFile.includes(accessGui),
    isDownloadingFile: (getters) => (accessGui) =>
      !!getters.getDownloadFileLoadingState(accessGui),
  },
  mutations: {
    START_LOADING(state) {
      state.loadingCount++
    },
    FINISH_LOADING(state) {
      state.loadingCount--
    },
    START_LOADING_QUALIFICATIONS(state) {
      state.loadingQualificationsCount++
    },
    FINISH_LOADING_QUALIFICATIONS(state) {
      state.loadingQualificationsCount--
    },
    START_LOADING_DETAILS(state) {
      state.loadingDetailsCount++
    },
    FINISH_LOADING_DETAILS(state) {
      state.loadingDetailsCount--
    },
    START_LOADING_CRUD(state) {
      state.crudLoadingCount++
    },
    FINISH_LOADING_CRUD(state) {
      state.crudLoadingCount--
    },
    START_LOADING_DELETE(state) {
      state.loadingDeleteCount++
    },
    FINISH_LOADING_DELETE(state) {
      state.loadingDeleteCount--
    },
    START_DOWNLOADING_FILE(state, accessGui) {
      const alreadyExists = state.loadingFile.includes(accessGui)
      if (alreadyExists) return
      state.loadingFile.push(accessGui)
    },
    FINISH_DOWNLOADING_FILE(state, accessGui) {
      const foundIndex = state.loadingFile.findIndex(
        (cachedAccessGuid) => cachedAccessGuid === accessGui
      )
      if (foundIndex < 0) return
      state.loadingFile.splice(foundIndex, 1)
    },
    SET_QUALIFICATIONS(state, qualifications) {
      state.qualifications = {
        list: qualifications,
        lastUpdated: dayjs(),
        isDirty: false,
      }
    },
    INSERT_QUALIFICATION(state, qualification) {
      state.qualifications.list.push(
        new QualificationDetailsViewModel(qualification)
      )
    },
    SET_QUALIFICATIONS_DIRTY(state) {
      if (!state.qualifications) return // Will prevent an exception if quals aren't initialised
      state.qualifications.isDirty = true
    },
    REMOVE_QUALIFICATION(state, qualificationId) {
      if (!isNonEmptyArray(state.qualifications?.list)) return
      const foundIndex = state.qualifications.list.findIndex(
        (qualification) => qualification.id === qualificationId
      )
      // If not found, reload work exp summary list
      if (foundIndex < 0) {
        if (!state.qualifications) return
        state.qualifications.isDirty = true
      }

      state.qualifications.list.splice(foundIndex, 1)
    },
    CLEAR_STORE(state) {
      // Resets store to default state
      Object.assign(state, getDefaultState())
    },
  },
  actions: {
    /**
     * @param {{commit: Function, getters: Function, dispatch: Function}} VuexAction
     * @param {Boolean} forceRefresh
     * @returns {Promise<ResultDTO>}
     */
    async getQualificationsList({ commit, getters, dispatch }, forceRefresh) {
      // 1. Check if we already have the quals cached
      if (
        isCacheFresh({
          cacheDuration: 1,
          durationUnits: DurationUnits.HOUR,
          lastUpdated: state.qualifications?.lastUpdated,
          forceRefresh,
          isDirty: state.qualifications?.isDirty,
        })
      )
        return success({ data: getters.qualifications })

      // 2. Fetch quals from api
      commit('START_LOADING_QUALIFICATIONS')
      try {
        const response = await this.$api.qualifications.get()
        commit('SET_QUALIFICATIONS', response.data)
        return success({ data: getters.qualifications })
      } catch (ex) {
        toast.error(this.$i18n.t('qualifications.errorQualificationsLoad'))
        return fail({
          error: await dispatch('logException', ex, { root: true }),
        })
      } finally {
        commit('FINISH_LOADING_QUALIFICATIONS')
      }
    },
    /**
     * @param {{commit: Function, dispatch: Function}} VuexAction
     * @param {*} qualificationToUpload
     * @returns {Promise<ResultDTO>}
     */
    async uploadQualification({ commit, dispatch }, qualificationToUpload) {
      const payload = new QualificationUploadDTO({
        institute: qualificationToUpload.institute?.trim(),
        degree: qualificationToUpload.degree?.trim(),
        dateComplete: dayjs(qualificationToUpload.dateComplete?.trim())
          .local()
          .format(DateFormatToken.YYYYMMDDT0),
        teachingMethods: qualificationToUpload.teachingMethods,
        files: await processFilesForUpload(qualificationToUpload.files),
      })

      commit('START_LOADING_CRUD')
      try {
        const response = await this.$api.qualifications.post('', payload)
        commit('INSERT_QUALIFICATION', {
          id: response.data.id,
          institute: payload.institute,
          degree: payload.degree,
          completed: payload.dateComplete,
          isVerified: false,
          grades: payload.teachingMethods,
          files: [],
        })
        commit('SET_QUALIFICATIONS_DIRTY')
        return response
      } catch (ex) {
        const error = await dispatch('logException', ex, { root: true })
        toast.error(error.message)
        return fail({ error })
      } finally {
        commit('FINISH_LOADING_CRUD')
      }
    },
    /**
     * Retrieves qualification details for the specified Id.
     * @param {{commit: Function, dispatch: Function}} VuexAction
     * @param {Number} qualificationId
     * @returns {Promise<ResultDTO>} Object with details on qualification
     */
    async getQualificationDetails({ commit, dispatch }, qualificationId) {
      commit('START_LOADING_DETAILS')
      try {
        const response = await this.$api.qualifications.getQualificationDetails(
          qualificationId
        )
        response.data = new QualificationDetailsViewModel(response.data)
        return response
      } catch (ex) {
        const error = await dispatch('logException', ex, { root: true })
        toast.error(error.message)
        return fail({ error })
      } finally {
        commit('FINISH_LOADING_DETAILS')
      }
    },
    /**
     * Downloads blob of the qualification file
     * @param {{commit: Function, dispatch: Function}} VuexAction
     * @param {Number} qualificationId
     * @param {Guid} accessGuid Guid of the file
     * @returns {Promise<ResultDTO>} Blob of the qualification file
     */
    async downloadQualificationFile(
      { commit, dispatch },
      { qualificationId, accessGui, disableToast = false }
    ) {
      commit('START_DOWNLOADING_FILE', accessGui)
      try {
        const response =
          await this.$api.qualifications.downloadQualificationFile(
            qualificationId,
            accessGui
          )
        const fileName = extractFileNameFromContentDisposition(
          response.headers[HttpHeaders.contentDisposition]
        )
        const blob = new Blob([response.data], {
          type: getType(fileName),
        })
        return success({ data: blob })
      } catch (ex) {
        if (!disableToast) {
          toast.error(
            this.$i18n.t('qualifications.view.unableToLoadErrorTitle')
          )
        }
        return fail({
          error: await dispatch('logException', ex, { root: true }),
        })
      } finally {
        commit('FINISH_DOWNLOADING_FILE', accessGui)
      }
    },
    async deleteQualification({ commit, dispatch }, qualificationId) {
      commit('START_LOADING_DELETE')
      try {
        const response = await this.$api.qualifications.delete(qualificationId)
        commit('REMOVE_QUALIFICATION', qualificationId)

        toast.success(this.$i18n.t('qualifications.delete.successToastMessage'))
        return response
      } catch (ex) {
        const error = await dispatch('logException', ex, { root: true })
        toast.error(
          QualificationActionErrorMessageFactory({
            actionName: 'deleteQualification',
            error,
            i18n: this.$i18n,
          })
        )
        return fail({ error, statusCode: ex.response.status })
      } finally {
        commit('FINISH_LOADING_DELETE')
      }
    },
    /**
     * Resets store to default state.
     */
    clear({ commit }) {
      commit('CLEAR_STORE')
    },
  },
}
