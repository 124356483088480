export default class DateRange {
  /**
   * @param {{ start: Date, end: Date }} param0
   */
  constructor({ start = null, end = null } = {}) {
    /**
     * Start of date range
     * @type {Date}
     */
    this.start = start

    /**
     * End of date range
     * @type {Date}
     */
    this.end = end
  }
}
