/* eslint-disable no-unused-vars */
import FormProvider from '@/constants/registrations/FormProvider'

/**
 * @class
 * @public
 */
export default class ComplianceItemFormViewModel {
  constructor({ url, provider } = {}) {
    /**
     * @type {String}
     */
    this.url = url
    /**
     * Provider of the requested form
     * @type {String}
     * @example internal | jotForm
     * @see {FormProvider} for valid type values
     */
    this.provider = provider
  }
}
