import DurationUnits from '@/constants/date/DurationUnits'

export default class Duration {
  constructor({ value = 0, unit = DurationUnits.MINUTE } = {}) {
    /**
     * Quantity of units of time
     * @type {number}
     */
    this.value = value

    /**
     * Unit of time to describe the value prop
     * @type {DurationUnits}
     */
    this.unit = unit
  }
}
