export default Object.freeze({
  /**
   * Error is undetermined
   */
  unknown: 'unknownError',
  /**
   * An internal api error.
   */
  api: 'apiError',
  /**
   *  One or more of the input parameters failed validation.
   */
  validation: 'validationError',
  /**
   * An authorisation or authentication error.
   */
  security: 'securityError',
  /**
   * Requested resource does not exist.
   */
  notFound: 'notFoundError',
  /**
   * Server won't process request but request is well-formed and thus does not
   * meet the requirements for a validation error.
   */
  unprocessable: 'unprocessableError',
  /**
   * The request caused a conflict.
   */
  conflict: 'conflictError',
  /**
   * The requested service was unavailable and returned a transient error.
   */
  transient: 'transientError',
})
