import auth from './auth'
import file from './file'
import registrations from './registrations'
import documents from './documents'
import ready2Work from './ready2Work'
import availability from './availability'
import workExperience from './work-experience'
import qualifications from './qualifications'
import certifications from './certifications'
import subjects from './subjects'
import bookings from './bookings'
import clients from './clients'
import agreements from './agreements'
import events from './events'
import downloads from './downloads'
import features from './features.js'

export default {
  features,
  downloads,
  events,
  clients,
  bookings,
  subjects,
  qualifications,
  workExperience,
  documents,
  ready2Work,
  availability,
  registrations,
  file,
  auth,
  certifications,
  agreements,
}
