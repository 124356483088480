/* eslint-disable no-unused-vars */
import GenericFileModel from '../file/genericFileModel'
import WorkExperienceDotPointDTO from './workExperienceDotPointDTO'

/**
 * @class
 * @public
 */
export default class WorkExperienceUploadFormDTO {
  /**
   * @constructor
   * @param {Object<placeOfWork: String, positionHeld: String, dateFrom: Date, dateUntil: Date, details: String, dotPoints: Array<WorkExperienceDotPointDTO>, isCurrent: Boolean, files: Array<GenericFileModel>>} param0
   */
  constructor({
    placeOfWork = null,
    positionHeld = null,
    dateFrom = null,
    dateUntil = null,
    details = null,
    dotPoints = [],
    isCurrent = false,
    files = [],
  } = {}) {
    /**
     * @type {String}
     */
    this.placeOfWork = placeOfWork

    /**
     * @type {String}
     */
    this.positionHeld = positionHeld

    /**
     * @type {Date}
     */
    this.dateFrom = dateFrom

    /**
     * @type {Date}
     */
    this.dateUntil = dateUntil

    /**
     * @type {String}
     */
    this.details = details

    /**
     * @type {Array<WorkExperienceDotPointDTO>}
     */
    this.dotPoints = dotPoints

    /**
     * Indicates if candidate is currently in role
     * @type {Boolean}
     */
    this.isCurrent = isCurrent

    /**
     * @type {Array<GenericFileModel>}
     */
    this.files = files
  }
}
