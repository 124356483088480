/* eslint-disable no-unused-vars */
import { nanoid } from '@root/node_modules/nanoid/index'
import ShiftRecordBlock from './shiftRecordBlock'

/**
 * @class
 * @public
 */
export default class ShiftRecordBlockWithId extends ShiftRecordBlock {
  constructor({ type, startTime, endTime } = {}) {
    super({ type, startTime, endTime })

    /**
     * Client-side only value to assist with tracking shift record blocks
     * @type {String}
     */
    this.id = nanoid()
  }
}
