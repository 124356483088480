import Environment from '@/constants/core/Environment'
import $dayjs from '@/services/date'

const development = () =>
  fetch(`${process.env.BASE_URL}config.dev.json?t=${$dayjs().valueOf()}`).then(
    (response) => response.json()
  )

const localDev = () =>
  fetch(
    `${process.env.BASE_URL}config.local-dev.json?t=${$dayjs().valueOf()}`
  ).then((response) => response.json())

const production = () =>
  fetch(
    `${process.env.BASE_URL}config.runtime.json?t=${$dayjs().valueOf()}`
  ).then((response) => response.json())

const loadConfig = (environment) => {
  switch (environment) {
    case Environment.production:
      return production()
    case Environment.local:
      return localDev()
    case Environment.development:
    default:
      return development()
  }
}

export default async () => await loadConfig(process.env.NODE_ENV)
