import { mapGetters } from 'vuex'
import truncateText from '@/filters/truncate-text'

export default {
  computed: {
    ...mapGetters({
      impersonateCandidateId: 'auth/impersonateCandidateId',
      currentUser: 'auth/currentUser',
    }),
    firstName() {
      const fname = this.currentUser?.firstName || 'Unknown'
      const fnameCharLimit = this.isMobileViewPort ? 15 : 30
      return `${truncateText(fname.trim(), fnameCharLimit)}${
        this.isDebug ? ` (ID: ${this.impersonateCandidateId})` : ''
      }`
    },
  },
}
