/* eslint-disable no-unused-vars */
import { QualificationGradeViewModel } from '@/models/qualification/qualificationGradeViewModel'
import AccessGuiFileViewModel from '@/models/file/accessGuiFileViewModel'
import dayjs from '@/services/date/index'

/**
 * @class
 * @public
 */
export default class QualificationDetailsViewModel {
  constructor({
    id = null,
    institute = '',
    degree = '',
    completed = null,
    isVerified = false,
    grades = [],
    files = [],
  } = {}) {
    /**
     * Id of the qualification
     * @type {Number}
     */
    this.id = id

    /**
     * Name of the institute
     * @type {String}
     */
    this.institute = institute

    /**
     * Degree obtained
     * @type {String}
     */
    this.degree = degree

    /**
     * Date completed
     * @type {Date}
     */
    this.completed = completed ? dayjs(completed) : null

    /**
     * @type {Boolean}
     */
    this.isVerified = isVerified

    /**
     * Teaching methods qualified
     * @type {QualificationGradeViewModel[]}
     */
    this.grades = grades

    /**
     * @type {AccessGuiFileViewModel[]}
     */
    this.files =
      files && files.length > 0
        ? files.map((file) => new AccessGuiFileViewModel(file))
        : []
  }
}
