// eslint-disable-next-line no-unused-vars
import CandidateDocumentAgreementViewModel from '../candidate/candidateDocumentAgreementViewModel'

/**
 * Items requiring action from the candidate for a specific client
 */
export default class CandidateClientAgreementsViewModel {
  constructor({ clientId = null, agreements = [] } = {}) {
    /**
     * @type {Number}
     */
    this.clientId = clientId

    /**
     * @type {CandidateDocumentAgreementViewModel[]}
     */
    this.agreements = agreements
  }
}
