/**
 * @class
 * @public
 */
export default class ConsultantViewModel {
  constructor({
    name = '',
    country = '',
    state = '',
    phoneNumber = '',
    email = '',
  } = {}) {
    /**
     * Consultant's fullname
     * @type {String}
     * @example Mike Nolan
     */
    this.name = name
    /**
     * Full country name
     * @type {String}
     * @example Australia
     */
    this.country = country
    /**
     * @type {String}
     * @example Victoria
     */
    this.state = state
    /**
     * Number the candidate can use to contact this consultant/office
     * @type {String}
     * @example 03 8675 3090
     */
    this.phoneNumber = phoneNumber
    /**
     * Consultant's email address or their office's email address
     * (if the consultant doesn't have an email)
     * @type {String}
     * @example nolezy@anzuk.education
     */
    this.email = email
  }
}
