/**
 * @class
 * @public
 */
export default class BackendBookingFeatureDecisions {
  constructor({ isRunningLateEnabled = false } = {}) {
    /**
     * @type {Boolean}
     */
    this.isRunningLateEnabled = isRunningLateEnabled
  }
}
