import TentativeFillAction from '@/constants/booking/TentativeFillAction'

/**
 * @class
 * @public
 */
export default class TentativeFillActionDTO {
  constructor({ action = TentativeFillAction.unset } = {}) {
    /**
     * @type {TentativeFillAction}
     */
    this.action = action
  }
}
