<script>
import Layout from '@layouts/public.vue'
import { mapGetters, mapActions } from 'vuex'
import baseMixin from '@/mixins/baseMixin'
import { ErrorPageFactory } from '@/services/error/ErrorPageFactory'

export default {
  name: 'ErrorView',
  components: { Layout },
  mixins: [baseMixin],
  data() {
    return {
      factory: null,
      title: '',
      meta: '',
    }
  },
  computed: {
    ...mapGetters({
      isUserLoggedIn: 'auth/isUserLoggedIn',
      currentUser: 'auth/currentUser',
    }),
  },
  created() {
    this.factory = ErrorPageFactory(this.$route.meta.type)
    this.title = this.factory.errorText
    this.meta = this.factory.errorCode
  },

  methods: {
    ...mapActions({
      logout: 'auth/logOut',
    }),
    signOut() {
      this.logout({ redirect: true, nuke: true })
    },
  },
  metaInfo() {
    return {
      title: this.title,
      meta: [{ name: 'description', content: this.meta }],
    }
  },
}
</script>

<template>
  <Layout class="overflow-hidden">
    <div class="shapes-container">
      <div class="animation-shape shape-ring animation--rotating-diagonal">
        <div></div>
      </div>
      <div class="animation-shape shape-circle animation--clockwise">
        <div></div>
      </div>
      <div class="animation-shape shape-triangle animation--anti-clockwise">
        <div class="animation--rotating"></div>
      </div>
      <div class="animation-shape shape-diamond animation--anti-clockwise">
        <div class="animation--rotating"></div>
      </div>

      <div class="static-shape shape-ring-1"></div>
      <div class="static-shape shape-ring-2"></div>
      <div class="static-shape shape-circle-1"></div>

      <div class="static-shape pattern-dots-1"></div>
      <div class="static-shape pattern-dots-2"></div>
    </div>
    <div class="text-center white--text z-index-1">
      <div class="d-flex flex-column align-center">
        <h3
          class="error-title font-weight-black my-10 text-center"
          style="width: fit-content"
        >
          <div
            :class="['errorHeader', isSmallOrLower ? 'errorHeader--sm' : '']"
          >
            <div class="bgHighlight pr-4"></div>
            <div class="text display-4 z-index-1">
              {{ factory.title }}
            </div>
          </div>
        </h3>

        <p class="error-desc display-1 font-weight-bold mt-0 z-index-1">
          {{ factory.desc }}
        </p>
        <p class="error-sub-desc subtitle-1 font-weight-medium z-index-1">
          {{
            `${factory.showErrorCode ? '(' + factory.errorCode + ') ' : ''} ${
              factory.subDesc
            }`
          }}
        </p>

        <div class="error-code" :data-error-code="factory.errorText"></div>

        <div
          :class="[
            'd-flex justify-center mt-10',
            isSmallOrLower ? 'flex-column-reverse w-100' : '',
          ]"
        >
          <BaseButton
            v-show="isUserLoggedIn || currentUser"
            id="error-page-logout-btn"
            x-large
            text
            :block="isSmallOrLower"
            outlined
            color="white"
            :class="[
              'rounded-lg text-capitalize no-letter-spacing',
              isSmallOrLower ? '' : 'mr-2',
            ]"
            @click="signOut"
          >
            <v-icon left class="white--text">mdi-arrow-left</v-icon>
            {{ $t('error.errorGenericLogOutButtonText') }}
          </BaseButton>
          <BaseButton
            id="error-page-go-home-btn"
            x-large
            light
            :block="isSmallOrLower"
            color="blue"
            :class="[
              'rounded-lg text-capitalize no-letter-spacing white--text custom-elevation',
              isSmallOrLower ? 'mb-4' : 'ml-2',
            ]"
            :href="factory.goBackURL"
          >
            {{ factory.goBackText }}
          </BaseButton>
        </div>
      </div>
    </div>
  </Layout>
</template>

<style lang="scss" scoped>
@import '@/styles/animations';
@import '@/styles/login';
@import '@/styles/shapes';

.z-index-1 {
  z-index: 1;
}

.errorHeader {
  position: relative;
  text-align: center;

  .text {
    position: relative;
    z-index: 1;
    padding: 20px 20px 8px;
    margin-right: 10px;
    margin-left: 10px;
    font-size: 4rem;
    font-weight: bolder;
  }

  &.errorHeader--sm {
    .text {
      font-size: 3.5rem !important;
      font-weight: bolder;
    }
  }

  .bgHighlight {
    position: absolute;
    top: 0;
    bottom: 0.3em;
    z-index: 1;
    width: 100%;
    height: 100%;
    padding-right: 10px;
    padding-left: 10px;
    margin-bottom: -10px;
    background-color: #f5b51a;
  }
}
</style>
