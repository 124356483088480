const getDefaultState = () => {
  return {
    toggles: {},
    loadingCount: 0,
    crudLoadingCount: 0,
  }
}

const state = getDefaultState()

export default {
  namespaced: true,
  state,
  getters: {
    moduleName: () => 'features',
    featureToggles: (state) => state.toggles,
    isLoading: (state) => state.loadingCount > 0,
    isLoadingCRUD: (state) => state.crudLoadingCount > 0,
  },
  mutations: {
    START_LOADING(state) {
      state.loadingCount++
    },
    FINISH_LOADING(state) {
      state.loadingCount--
    },
    START_LOADING_CRUD(state) {
      state.crudLoadingCount++
    },
    FINISH_LOADING_CRUD(state) {
      state.crudLoadingCount--
    },
    CLEAR_STORE(state) {
      // Resets store to default state
      Object.assign(state, getDefaultState())
    },
    SET_FEATURE_TOGGLES(state, toggles) {
      state.toggles = toggles
    },
  },
  actions: {
    setFeatureToggles({ commit }, toggles) {
      commit('SET_FEATURE_TOGGLES', toggles)
    },
    /**
     * Resets store to default state.
     */
    clear({ commit }) {
      commit('CLEAR_STORE')
    },
  },
}
