import { ModelApiService } from './BaseApiService'

export default class ProfessionalDevelopmentApiService extends ModelApiService {
  constructor() {
    super('pd')
  }

  async getEvents(params) {
    return await this.get('events', params)
  }
}
